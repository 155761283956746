import api from "../services/api"

const state = {
  indicators: {}

}

const mutations = {
  HOME_INDICATORS ( state, indicators ) { state.indicators = indicators },
}

const actions = {
  async getIndicatorsHTTP ( { rootState, commit }) {
    const indicators = await api.get(`/home/indicators/${rootState.user.userId}`)
    commit('HOME_INDICATORS', indicators.data.data)
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}