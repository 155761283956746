<template>
  <div class="psConfigurationDiseases">
    <ps-upper-bar :site="{ site: 'Configuración - Enfermedades de base' }" :backButton="true"></ps-upper-bar>
    <div class="container">
      <div class="row">
        <el-button round plain icon="el-icon-plus" @click="addNewDisease" :disabled="newDisease">Nueva enfermedad de base</el-button>
      </div>
      <div class="row rowData">
        <div class="column w-25">
          <div class="label">Descripcion</div>
          <el-input 
            :value="disease.diseaseDescription" 
            @input="changeProperty($event, 'diseaseDescription')" 
            placeholder="Ingrese una descripción"
            :disabled="!newDisease"></el-input>
        </div>
        <div class="column">
          <div class="label">Activa</div>
          <el-switch :value="disease.active" @input="changeProperty($event, 'active')" :disabled="!newDisease"></el-switch>
        </div>
      </div>
      <div class="row rowButtons">
        <el-button round plain type="success" :disabled="!saveDisabled" @click="save">Guardar</el-button>
        <el-button round plain type="danger" :disabled="!newDisease" @click="cancelDisease">Cancelar</el-button>
      </div>

      <el-table 
        :data="diseases"
        style="width: 800px;" 
        :highlight-current-row="true" 
        size="mini" 
        height="305" 
        empty-text="No hay enfermedades de base ingresadas"
        @cell-dblclick="selectRow">
        <el-table-column prop="diseaseId" label="Código" width="80" class-name="diseaseId" id="diseaseId" v-if="!isMobile"></el-table-column>
        <el-table-column prop="diseaseDescription" label="Descripción" :width="isMobile == true ? '' : 300" ></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import PsUpperBar from '@/components/layout/PsUpperBar.vue'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsConfigurationDiseases',
  components: { PsUpperBar },
  data () {
    return {
      newDisease: false
    }
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapState('diseases', ['diseases', 'disease', 'errors']),
    saveDisabled () {
      return this.newDisease && this.disease.diseaseDescription != ''
    }
  },
  methods: {
    ...mapMutations('diseases', ['DISEASES_DISEASE', 'DISEASES_DISEASE_PROP']),
    ...mapActions('diseases', ['saveDisease', 'getDiseasesHTTP']),
    selectRow ( row ) {
      if( row ) {
        this.newDisease = true
        this.DISEASES_DISEASE(JSON.parse(JSON.stringify(row)))
      }
    },
    changeProperty ( value, property ) {
      this.DISEASES_DISEASE_PROP({property, value})
    },
    addNewDisease () {
      this.newDisease = true
      this.DISEASES_DISEASE({})
    },
    cancelDisease () {
      this.newDisease = false
      this.DISEASES_DISEASE({})
    },
    async save () {
      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Guardando...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        }); 

        const message = await this.saveDisease()
        if(this.errors.length > 0 ) {
          this.showValidationAlert()
        } else {
          this.cancelDisease()
          this.$message( { message, type: 'success' } )
          this.getDiseasesHTTP()
        }
    } catch ( err ) { this.$message( { message: err.response.data.err.details, type: 'error' })}
      finally { loading.close(); }
    },
    showValidationAlert () {      
      let str = '<p>';
      if(this.errors.length > 0) {
        this.errors.forEach(err => str += `${err}<br>`)
        str = str + '</p>'
        this.$alert(str, '', {confirmButtonText: 'Entendido', type: 'warning', dangerouslyUseHTMLString: true})
      }
    },
  },
  created () {
    this.DISEASES_DISEASE({})
    this.getDiseasesHTTP()
  }
}
</script>

<style scoped>
  .psConfigurationDiseases { min-height: 90%;}
  .container { background-color: white; border-radius: 20px; padding: 32px; min-height: 90%; overflow-y: scroll; height: 100%; }
  .container::-webkit-scrollbar { display: none}
  .rowData { margin: 40px 0 20px}
  .rowHistory { margin-bottom: 20px;}
  .el-collapse { width: 500px }
  .itemHistoryRate { text-align: left;}
  .stateRate { color: red; margin-left: 15px}
  .active { color: green}


  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .container { padding: 32px 16px}
    .rowData { flex-direction: column;}
    .rowData .column { margin-right: 0 }
    .rowData .column .el-input-number, .rowData .w-25 { width: 100%; margin-bottom: 8px; }
    .rowButtons { justify-content: space-evenly;}
  }
</style>