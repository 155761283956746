<template>
  <div class="psConfigurationConcepts">
    <ps-upper-bar :site="{ site: 'Configuración - Conceptos' }" :backButton="true"></ps-upper-bar>
    <div class="container">
      <div class="row">
        <el-button round plain icon="el-icon-plus" @click="addNewConcept" :disabled="newConcept">Nuevo concepto</el-button>
      </div>
      <div class="row rowData">
        <div class="column w-25">
          <div class="label">Descripcion</div>
          <el-input 
            :value="concept.conceptDescription" 
            @input="changeProperty($event, 'conceptDescription')" 
            placeholder="Ingrese una descripción"
            :disabled="!newConcept"></el-input>
        </div>
        <div class="column">
          <div class="label">Activa</div>
          <el-switch :value="concept.active" @input="changeProperty($event, 'active')" :disabled="!newConcept"></el-switch>
        </div>
      </div>
      <div class="row rowButtons">
        <el-button round plain type="success" :disabled="!saveDisabled" @click="save">Guardar</el-button>
        <el-button round plain type="danger" :disabled="!newConcept" @click="cancelConcept">Cancelar</el-button>
      </div>

      <el-table 
        :data="concepts"
        style="width: 800px;" 
        :highlight-current-row="true" 
        size="mini" 
        height="200" 
        empty-text="No hay conceptos ingresados"
        @cell-dblclick="selectRow">
        <el-table-column prop="conceptId" label="Código" width="80" class-name="conceptId" id="conceptId" v-if="!isMobile"></el-table-column>
        <el-table-column prop="conceptDescription" label="Descripción" :width="isMobile == true ? '' : 200" ></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import PsUpperBar from '@/components/layout/PsUpperBar.vue'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsConfigurationConcepts',
  components: { PsUpperBar },
  data () {
    return {
      newConcept: false
    }
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapState('concepts', ['concepts', 'concept', 'errors']),
    saveDisabled () {
      return this.newConcept && this.concept.conceptDescription != '' 
    }
  },
  methods: {
    ...mapMutations('concepts', ['CONCEPTS_CONCEPT', 'CONCEPTS_CONCEPT_PROP']),
    ...mapActions('concepts', ['saveConcept', 'getConceptsHTTP']),
    formatMoney ( row, column, cellValue ) {
      return `$ ${cellValue}`
    },
    selectRow ( row ) {
      if( row ) {
        this.newConcept = true
        this.CONCEPTS_CONCEPT(JSON.parse(JSON.stringify(row)))
      }
    },
    changeProperty ( value, property ) {
      this.CONCEPTS_CONCEPT_PROP({property, value})
    },
    addNewConcept () {
      this.newConcept = true
      this.CONCEPTS_CONCEPT({})
    },
    cancelConcept () {
      this.newConcept = false
      this.CONCEPTS_CONCEPT({})
    },
    async save () {
      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Guardando...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        }); 

        const message = await this.saveConcept()
        if(this.errors.length > 0 ) {
          this.showValidationAlert()
        } else {
          this.cancelConcept()
          this.$message( { message, type: 'success' } )
          this.getConceptsHTTP()
        }
    } catch ( err ) { this.$message( { message: err.response.data.err.details, type: 'error' })}
      finally { loading.close(); }
    },
    showValidationAlert () {      
      let str = '<p>';
      if(this.errors.length > 0) {
        this.errors.forEach(err => str += `${err}<br>`)
        str = str + '</p>'
        this.$alert(str, '', {confirmButtonText: 'Entendido', type: 'warning', dangerouslyUseHTMLString: true})
      }
    },
  },
  created () {
    this.CONCEPTS_CONCEPT({})
    this.getConceptsHTTP()
  }
}
</script>

<style scoped>
  .psConfigurationConcepts { min-height: 90%;}
  .container { background-color: white; border-radius: 20px; padding: 32px; min-height: 90%; overflow-y: scroll; height: 100%; }
  .container::-webkit-scrollbar { display: none}
  .rowData { margin: 40px 0 20px}
  .rowHistory { margin-bottom: 20px;}
  .el-collapse { width: 500px }
  .itemHistoryRate { text-align: left;}
  .stateRate { color: red; margin-left: 15px}
  .active { color: green}


  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .container { padding: 32px 16px}
    .rowData { flex-direction: column;}
    .rowData .column { margin-right: 0 }
    .rowData .column .el-input-number, .rowData .w-25 { width: 100%; margin-bottom: 8px; }
    .rowButtons { justify-content: space-evenly;}
  }
</style>