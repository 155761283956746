import Vue from 'vue'
import Vuex from 'vuex'
import api from './../services/api'
import routes from './routes.js'
import patients from './patients.js'
import rates from './rates.js'
import ratesGroup from './ratesGroup.js'
import waysToPay from './waysToPay.js'
import accounts from './accounts.js'
import users from './users.js'
import socialsWorkers from './socialsWorkers.js'
import groups from './groups.js'
import diseases from './diseases.js'
import eventuals from './eventuals.js'
import phases from './phases.js'
import calendar from './calendar.js'
import calendarTypes from './calendarTypes.js'
import administration from './administration.js'
import concepts from './concepts.js'
import home from './home.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    token: '',
    isActiveSession: false,
    drawerMenu: false,
    isMobile: false,
    months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
  },
  mutations: {
    LOGIN ( state, payload ) {
      state.token = payload.token
      localStorage.setItem('user', JSON.stringify(payload))
      state.user = payload
      state.isActiveSession = true
    },
    LOGOUT ( state ) { 
      state.token = ''
      state.user = {}
      state.isActiveSession = false
      localStorage.removeItem('user')
    },
    DRAWER_MENU ( state, drawerMenu ) { state.drawerMenu = drawerMenu },
    IS_MOBILE ( state, isMobile ) { state.isMobile = isMobile }
  },
  actions: {
    async loginHTTP ({commit}, payload) {
      return new Promise((resolve, reject) => {
        
        api.post('/users/login', payload)
          .then( result => {
            if(result) {
              commit('LOGIN', result.data.data)      
              resolve()
            }
          })
          .catch ( err => { reject(err) } ) 
        
      })
    },
    //eslint-disable-next-line
    async activeSession ({commit}, payload) {
      return new Promise ( (resolve, reject) => {
        
        if ( payload ) {
          api.post('/users/token', payload)
            .then( result => {
              if(result) {
                commit('LOGIN', JSON.parse(JSON.stringify(result.data.data)))
                resolve()
              } else {
                commit('LOGOUT')
                reject()
              }
            })
            .catch ( (err) => { reject(err)})        
        } else {
           reject()
        }
      })
     
    },
    verifyHeightDynamicRoute ( id ) {
      let dynamicRoute = document.querySelector('#dynamicRoute')
      let contentItems = document.querySelector(`#${id}`)
      if ( contentItems.scrollHeight > dynamicRoute.clientHeight ) {
        dynamicRoute.style.borderBottomLeftRadius = 0
        dynamicRoute.style.borderBottomRightRadius = 0
        dynamicRoute.style.paddingBottom = 0
        dynamicRoute.style.marginBottom = 0
      }
    }
  },
  getters: {
    isAdmin: state => {
      return state.user.roleId == 1
    }
  },
  modules: {
    routes,
    patients,
    rates,
    ratesGroup,
    waysToPay,
    accounts,
    users,
    socialsWorkers,
    groups,
    diseases,
    eventuals,
    phases,
    calendar,
    calendarTypes,
    administration,
    concepts,
    home
  }
})
