import api from "../services/api"
import {addProperyIfNotExists} from "../services/utils"

const state = {
  user: {},
  newUser: {
    userId: 0,
    username: '',
    name: '',
    surname: '',
    password: '',
    repeatPassword: '',
    active: true,
    roleId: 0,
    roleDescription: '',
    keepAccount: false,
    createdBy: 0,
    updatedBy: 0,
    createdAt: '',
    updatedAt: ''
  },
  users: [],
  errors: [],
  roles: []

}

const mutations = {
  USERS_USERS ( state, users ) { state.users = users },
  USERS_ROLES ( state, roles ) { state.roles = roles },
  USERS_USER ( state, user ) { state.user = addProperyIfNotExists(user, state.newUser) },
  USERS_USER_PROP ( state, payload ) { state.user[payload.property] = payload.value },
  USERS_EMPTY_ERRORS ( state ) { state.errors = [] },
  USERS_ADD_ERROR ( state, error ) { state.errors.push(error)}
}

const actions = {
  async getUsersHTTP ( { commit }) {
    const users = await api.get('/users')
    commit('USERS_USERS', users.data.data)
  },
  async getRolesHTTP ( { commit }) {
    const roles = await api.get('/users/roles')
    commit('USERS_ROLES', roles.data.data)
  },
  async saveUser ( { state, commit, dispatch }, changePassword ) {
    commit('USERS_EMPTY_ERRORS')
    if( state.user.username == '') commit('USERS_ADD_ERROR', 'El usuario es requerido')
    if( state.user.name == '') commit('USERS_ADD_ERROR', 'El nombre del usuario es requerido')
    if( state.user.surname == '') commit('USERS_ADD_ERROR', 'El apellido del usuario es requerido')
    if( state.user.roleId == '') commit('USERS_ADD_ERROR', 'El rol del usuario es requerido')
    
    if(state.user.userId == 0 || (changePassword == true && (state.user.password != '' || state.user.repeatPassword != '') )) {
      if( state.user.password == '' || state.user.repeatPassword == '' || state.user.password != state.user.repeatPassword) {
        commit('USERS_ADD_ERROR', 'Las contraseñas no pueden estar vacías y deben ser iguales')
      }
    }

    if( state.errors.length == 0 ) {
      if( state.user.userId == 0 ) {
        return await dispatch('createUserHTTP')
      } else {
        return await dispatch('updateUserHTTP')
      }
    }
  },
  async createUserHTTP ( { state, rootState } ) {
    let user = state.user
    user.createdBy = rootState.user.userId
    await api.post('/users', user )
    return 'Usuario creado'
  },
  async updateUserHTTP ( { state, rootState } ) {
    let user = state.user
    user.createdBy = rootState.user.userId
    user.updatedBy = rootState.user.userId
    await api.put('/users', user )
    return 'Usuario actualizado'
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}