<template>
  <div class="psGroupsDetailDailyItem">
    <div class="containerDataPatient">
      <div class="row">
        <div class="column">
          <div class="row">
            <div class="patient" @click="seePatient">
              <el-avatar 
                :size="40" 
                :src="patient.pathImagePatient && patient.pathImagePatient != '' ? patient.pathImagePatient : `${urlServer}/assets/images/avatarPlaceholder.png` ">
              </el-avatar>
              <div class="nameSurname">{{patient.name | textCapitalize}} {{patient.surname | textCapitalize}} {{patient.nickname ? '(' + patient.nickname + ')' : '' | textCapitalize}}</div>
            </div>
            <div class="weight">
              <input 
                :ref="refWeightPatient" 
                v-model="weightComma" 
                class="inputWeight" 
                @change="changeWeight" 
                controls="false" 
                @focus="handleFocus" 
                pattern="[0-9]*" 
                inputmode="numeric"
                />
              <div class="label">Kg</div>
            </div>
          </div>
          <div class="tableInformation">
            <el-input v-model="tableInformation" @blur="changeTableInformation" class="inputTableInformation"></el-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="pictures">
          <div class="column">
            <div class="label">D</div>
            <el-checkbox v-model="picBreakfast" @input="changeStatusPics($event, 'picBreakfast')"></el-checkbox>
          </div>
          <div class="column">
            <div class="label">A</div>
            <el-checkbox v-model="picLunch" @input="changeStatusPics($event, 'picLunch')"></el-checkbox>
          </div>
          <div class="column">
            <div class="label">M</div>
            <el-checkbox v-model="picAfternoonSnack" @input="changeStatusPics($event, 'picAfternoonSnack')"></el-checkbox>
          </div>
          <div class="column">
            <div class="label">C</div>
            <el-checkbox v-model="picDinner" @input="changeStatusPics($event, 'picDinner')"></el-checkbox>
          </div>
        </div>
        <div class="variation">
          <div class="column">
            <div class="label">DIARIA</div>
            <div>{{patient.dailyVariation}}</div>
          </div>
          <div class="column">
            <div class="label">SEMANAL</div>
            <div>{{patient.weeklyVariation}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="containerWeeklyWeight">
      <ps-shared-weekly-weight :data="patient.weeklyWeight"  :fontTitle="'0.9rem'"></ps-shared-weekly-weight>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import PsSharedWeeklyWeight from '@/components/PsSharedWeeklyWeight.vue'

export default {
  name: 'PsGroupsDetailDailyItem',
  components: { PsSharedWeeklyWeight },
  props: [ 'patient' ],
  computed: {
    ...mapState(['isMobile']),
    ...mapState('groups', ['group', 'selectedDate']),
    urlServer () {
      return process.env.VUE_APP_URLAPI
    },
    refWeightPatient () {
      return `weight-${this.patient.patientId}`
    },
    weightComma: {
      get () {
        return this.patient.weight ? this.patient.weight.toString().replace('.', ',') : 0
      },
      set ( value ) {
        this.GROUPS_GROUP_PATIENT_PROP( { property: 'weight', value: value, index: this.patient.index } )
      }
    },
    picBreakfast: {
      get () {
        return this.patient.picBreakfast == 1 ? true : false
      },
      set ( value ) {
        this.GROUPS_GROUP_PATIENT_PROP( { property: 'picBreakfast', value: value == 1 ? true : false, index: this.patient.index } )
      }
    },
    picLunch: {
      get () {
        return this.patient.picLunch == 1 ? true : false
      },
      set ( value ) {
        this.GROUPS_GROUP_PATIENT_PROP( { property: 'picLunch', value: value == 1 ? true : false, index: this.patient.index } )
      }
    },
    picAfternoonSnack: {
      get () {
        return this.patient.picAfternoonSnack == 1 ? true : false
      },
      set ( value ) {
        this.GROUPS_GROUP_PATIENT_PROP( { property: 'picAfternoonSnack', value: value == 1 ? true : false, index: this.patient.index } )
      }
    },
    picDinner: {
      get () {
        return this.patient.picDinner == 1 ? true : false
      },
      set ( value ) {
        this.GROUPS_GROUP_PATIENT_PROP( { property: 'picDinner', value: value == 1 ? true : false, index: this.patient.index } )
      }
    },
    tableInformation: {
      get () {
        return this.patient.tableInformation;
      },
      set ( value ) {
        this.GROUPS_GROUP_PATIENT_PROP( { property: 'tableInformation', value: value, index: this.patient.index } )
      }
    }
  },
  methods: {
    ...mapMutations('groups', ['GROUPS_GROUP_PATIENT_PROP']),
    ...mapActions('groups', ['updateStatusPictureHTTP', 'updateWeightHTTP', 'getGroupHTTP', 'updateTableInformationHTTP']),
    async changeStatusPics ( value, property ) {
      try {
        await this.updateStatusPictureHTTP( { id: this.patient.id, property, value: value == true ? 1 : 0 } )
      } catch ( err ) { this.$message.error('No se pudo actualizar el estado de esta foto')}
    },
    async changeWeight ( weight ) {
      try {
        await this.updateWeightHTTP( { 
          patientId: this.patient.patientId, 
          id: this.patient.id, 
          weight: Number(weight.target.value.replace(',', '.')), 
          weekNumber: this.patient.weekNumber, 
          dayNumberWeek: this.patient.dayNumberWeek,
          groupId: this.patient.groupId
        
        } )
        await this.getGroupHTTP(this.$route.params['groupId'])
      } catch ( err ) { this.$message.error('No se pudo actualizar el peso de este paciente')}
    },
    async changeTableInformation ( data ) {
      try {
        console.log(data);
        await this.updateTableInformationHTTP( { 
          patientId: this.patient.patientId, 
          id: this.patient.id, 
          tableInformation: data.target.value,        
        } )
      } catch ( err ) { this.$message.error('No se pudo actualizar el peso de este paciente')}
    },
    seePatient () {
      this.$router.push(`/patients/detail/${this.patient.patientId}`)
    },
    handleFocus (  ) {
      this.$refs[this.refWeightPatient].select()      
    }
  }
}
</script>

<style scoped>
  .psGroupsDetailDailyItem, .containerDataPatient, .containerWeeklyWeight, .pictures { display: flex;}
  .containerDataPatient, .containerWeeklyWeight { 
    justify-content: flex-start; 
    padding: 8px 16px; 
    background-color: white; 
    border-radius: 10px;
    color: black;
    font-weight: 500;
    text-align: left;
    margin-bottom: 8px;
    margin-right: 16px;
    box-shadow: 1px 1px 3px 1px #ccc;
    transition: 0.2s ease;
  
  }
  .containerWeeklyWeight { padding: 3px 16px; min-width: 320px;}
  .containerDataPatient > .row , .containerWeeklyWeight > .row { margin-bottom: 0; align-items: center;}
  /* .containerDataPatient > .row > .column > .row > .row { cursor: pointer} */

  .patient { display: flex; cursor: pointer}
  .nameSurname { width: 180px; text-align: left; padding-left: 10px;  margin-right: 10px; line-height: 40px}
  .weight { width: 85px; display: flex; margin-right: 10px;}
  .weight .label { margin-left: 5px; line-height: 36px; }
  input.inputWeight { 
    border: none; 
    border-bottom: 1px solid black; 
    width: 80%;  
    color: black; 
    font-weight: bold; 
    background-color: transparent; 
    font-size: 16px; 
    text-align: center; 
    font-family: 'Roboto', sans-serif; 
    border-radius: 0;
  }
  input.inputWeight::-webkit-outer-spin-button,
  input.inputWeight::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input.inputWeight:focus { outline: none;}

  .tableInformation { width: 100%}
  .inputTableInformation /deep/ input.el-input__inner { padding: 0; width: 100%; height: 25px; font-size: 12px; border: none; border-bottom: 1px solid #dcdfe6; border-radius: 0 }
  .pictures { width: 220px; justify-content: space-around;}
  .variation { width: 200px; display: flex; }
  .variation > .column { width: 50%; align-items: center; margin-right: 0; height: 42px;}
  .weeklyWeight { width: 400px;}
  .pictures > .column { align-items: center; margin-right: 0; height: 42px;}
  .pictures > .column > .label, .variation > .column > .label { color: #0290af; font-weight: bold; font-size: 0.9rem; line-height: 0.9rem; }
  .pictures /deep/ .el-checkbox__input.is-checked .el-checkbox__inner { background-color: #49FC66; border-color: #49FC66;}
  .pictures /deep/ .el-checkbox__inner { border-radius: 12px; width: 20px; height: 20px; border: 2px solid #aaa; }
  .pictures /deep/ .el-checkbox__inner::after { border: 3px solid white; width: 10px; height: 10px; border-radius: 10px; left: 0px; top: 0px; padding: 0}

      /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .containerWeeklyWeight { display: none}
    .containerDataPatient .variation { display: none}
    .containerDataPatient { flex-direction: column; font-size: 1rem; width: 100%; margin-right: 0; height: 140px; justify-content: space-between;}
    .containerDataPatient > .row { justify-content: space-between ; }

    .containerDataPatient > .row > .column { width: 100%;}
    .nameSurname { width: auto}
    .weight { width: 65px;}
    .weight > .label { display: none}
    .containerDataPatient .pictures { justify-content: space-evenly; width:  100%;}

    .tableInformation { margin-bottom: 10px}
  }


</style>