<template>
  <div class="psGroupsDetailHeader">
    <div class="row">
      <div class="column columnGroup">
        <div class="label">Nombre del grupo</div>
        <el-input :value="group.groupDescription" @input="changeProperty($event, 'groupDescription')" placeholder="Nombre del grupo"></el-input>
      </div>
      <div class="column columnGroup">
        <div class="label">Fase</div>
        <el-select :value="group.phaseDescription" @change="changeProperty($event, 'phase')">
          <el-option v-for="(phase, index) in phases" :key="index" :value="phase">{{phase.phaseDescription}}</el-option>
        </el-select>
      </div>
      <div class="column columnGroup">
        <div class="label">Activo</div>
        <el-switch :value="group.active" @input="changeProperty($event, 'active')"></el-switch>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <div class="label">Imagen del grupo</div>
        <el-upload
          action="#"
          class="imageGroupUpload"
          :class="{withoutDots: pathImage}"
          :show-file-list="false"
          :on-change="handleChange"
        >
            <img v-if="pathImage" :src="pathImage" class="img">
            <i v-else class="el-icon-plus imageGroupUpload-icon"></i>
        </el-upload>
      </div>
    </div>
    <div class="row rowButton">
      <div class="column w-33 columnButton">
        <el-button type="primary" round @click="save" :disabled="!disabledSaveButton">Guardar</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'

export default {
  name: 'PsGroupsDatailHeader',
  data () {
    return {      
      editarImagenActivo: false,
      imgquality: 0.3,
      imageUrl: ''
    }
  },
  computed: {
    ...mapState('groups', ['group', 'imageGroup', 'errors']),
    ...mapState('phases', ['phases']),
    pathImage () {
      return  `${this.group.groupPathImage}`
    },
    disabledSaveButton () {
      return this.group.groupDescription != '' ? true : false
    }
  },
  methods: {
    ...mapMutations('groups', ['GROUPS_GROUP_PROP', 'GROUPS_IMAGE_GROUP', 'GROUPS_DIALOG_CROP_IMAGE', 'GROUPS_EDITION_MODE']),
    ...mapActions('groups', ['saveGroup']),
    changeProperty ( value, property ) {
      if ( property == 'phase' ) {
        this.GROUPS_GROUP_PROP({property: 'phaseId', value: value.phaseId })
        this.GROUPS_GROUP_PROP({property: 'phaseDescription', value: value.phaseDescription })
      } else {
        this.GROUPS_GROUP_PROP({property, value})
      }
    },
    handleChange (file) {
      this.GROUPS_IMAGE_GROUP(URL.createObjectURL(file.raw))
      this.GROUPS_DIALOG_CROP_IMAGE(true)
    },
    async save () {
      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Guardando grupo...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });   

        const message = await this.saveGroup()
        if(this.errors.length > 0 ) {
          this.showValidationAlert()
        } else {
          this.$message( { message, type: 'success' } )
          setTimeout( () => this.$router.go(-1), 1000)
        }
      } catch ( err ) { this.$message( { message: err.response.data.err.details, type: 'error' })}
      finally { 
        loading.close()
        this.GROUPS_EDITION_MODE(false)
      }
    },
    showValidationAlert () {      
      let str = '<p>';
      if(this.errors.length > 0) {
        this.errors.forEach(err => str += `${err}<br>`)
        str = str + '</p>'
        this.$alert(str, '', {confirmButtonText: 'Entendido', type: 'warning', dangerouslyUseHTMLString: true})
      }
    },
  }
}
</script>

<style scoped>
  .psGroupsDetailHeader { background-color: white; border-radius: 20px; padding: 32px; }
  .columnGroup { width: 300px}

  .imageGroupUpload {
    border: 1px dashed rgb(104, 104, 104);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    height: 130px;
    width: 300px;
    border-radius: 20px;
  }
  .imageGroupUpload .el-upload:hover {
    border-color: black;
  }
  .imageGroupUpload-icon {
    font-size: 28px;
    color: #8c939d;
    /* width: 90px; */
    height: 130px;
    line-height: 130px;
    text-align: center;
    border: none;
  }

  .imageGroupUpload /deep/ .el-upload, .imageGroupUpload /deep/ .el-upload-dragger { width: 100%; height: 100%;}
  .imageGroupUpload /deep/ .el-upload:hover, .imageGroupUpload /deep/ .el-upload-dragger:hover,
  .imageGroupUpload /deep/ .imageGroupUpload-icon:hover { border: none}
  .img { width: 100%; height: 100%; object-fit: contain;}

  .withoutDots { border: none}

  .botonesImagen {
    position: absolute;
    bottom: 20px;
    display: flex;
    width: 100%;
    justify-content: center;
    z-index: 1000;
  }

  .btnEditImage, .btnDeleteImage, .btnCropImage { 
    background: #ffffff66;
    border: none;
    color: black
  }


    /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .psGroupsDetailHeader > .row { display: flex; flex-direction: column;}
    .columnGroup { width: 100%; margin-right: 0; margin-bottom: 10px;}
    .imageGroupUpload { width: 260px; height: 115px;}
  }
</style>