import { render, staticRenderFns } from "./PsGroupsListItem.vue?vue&type=template&id=a3d14474&scoped=true&"
import script from "./PsGroupsListItem.vue?vue&type=script&lang=js&"
export * from "./PsGroupsListItem.vue?vue&type=script&lang=js&"
import style0 from "./PsGroupsListItem.vue?vue&type=style&index=0&id=a3d14474&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3d14474",
  null
  
)

export default component.exports