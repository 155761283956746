<template>
  <div class="psPatientListDiseases">
    <div class="container">
      <el-checkbox-group v-model="listSelected">
        <el-checkbox-button v-for="(disease, index) in diseases" :key="index" :label="disease.diseaseDescription">{{disease.diseaseDescription}}</el-checkbox-button>
      </el-checkbox-group>
    </div>
    <el-button @click="saveDiseases" icon="el-icon-check" type="success" round class="btnSave">Guardar</el-button>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsPatientListDiseases',
  computed: {
    ...mapState('diseases', ['diseases']),
    ...mapState('patients', ['patient', 'diseasesListSelected']),
    listSelected: {
      set (value) {
        this.setDiseasesListSelected(value)
      },
      get () {
        return this.diseasesListSelected
      }
    }
  },
  methods: {
    ...mapMutations('patients', ['PATIENTS_DISEASES_LIST_SELECTED', 'PATIENTS_DIALOG_DISEASES']),
    ...mapActions('patients', ['saveDiseasesPatient', 'getPatientHTTP']),
    setDiseasesListSelected ( value ) {
      this.PATIENTS_DISEASES_LIST_SELECTED(value)
    },
    async saveDiseases () {
      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Guardando...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });   

        const message = await this.saveDiseasesPatient()
        this.$message( { message, type: 'success' } )
        setTimeout( async () => {
          await this.getPatientHTTP( this.$route.params['patientId'])
          this.PATIENTS_DIALOG_DISEASES(false)
        }, 1000)
      } catch ( err ) { this.$message( { message: err.response.data.err.details, type: 'error' })}
      finally { loading.close() } 
    }
  }
}
</script>

<style scoped>
  .el-checkbox-group { display: flex; flex-direction: column;}
  .el-checkbox-button { width: 100%;}
  .el-checkbox-button /deep/ .el-checkbox-button__inner { display: block; width: 100%; margin-bottom: 4px;}
  .btnSave { margin-top: 15px}
  .container { max-height: 300px; overflow-y: scroll;}
  .container::-webkit-scrollbar {
    width: 8px;     
    height: 100%;    
    /* display: none;   */
  }

  /* Ponemos un color de fondo y redondeamos las esquinas del thumb */
.container::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.container::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
.container::-webkit-scrollbar-thumb:active {
    background-color: #999999;
}
</style>