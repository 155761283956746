<template>
  <div class="psPatientDiseases">
    <div class="row rowTitle">
      Enfermedades de base / Momento biológico
      <ps-patient-btn-new-disease></ps-patient-btn-new-disease>
    </div>
    <div class="row rowTags">
      <el-tag v-for="(disease, index) in patient.diseases" :key="index">{{
        disease.diseaseDescription
      }}</el-tag>
    </div>
  </div>
</template>

<script>
import PsPatientBtnNewDisease from "./PsPatientBtnNewDisease.vue";
import { mapState } from "vuex";

export default {
  name: "PsPatientDiseases",
  components: { PsPatientBtnNewDisease },
  computed: {
    ...mapState("patients", ["patient"])
  }
};
</script>

<style scoped>
.psPatientDiseases {
  background-color: white;
  border-radius: 20px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  width: 48%;
  margin-top: 35px;
}
.rowTitle {
  font-size: 24px;
  font-weight: bold;
  line-height: 35px;
  align-items: center;
}
.rowTags {
  display: flex;
  flex-flow: wrap;
  max-height: 90px;
  overflow-y: scroll;
}
.el-tag {
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  margin-right: 15px;
  margin-bottom: 10px;
}

.rowTags::-webkit-scrollbar {
  width: 8px;
  height: 100%;
  /* display: none;   */
}

/* Ponemos un color de fondo y redondeamos las esquinas del thumb */
.rowTags::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.rowTags::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
.rowTags::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .psPatientDiseases {
    width: 100%;
  }
  .rowTitle {
    font-size: 22px;
    text-align: left;
  }
  .rowTags {
    max-height: 120px;
  }
}
</style>
