<template>
  <div class="psPatientsListAllWeights">
    <el-table :data="allWeights" size="mini">
      <el-table-column prop="date" label="Fecha"></el-table-column>
      <el-table-column prop="groupDescription" label="Grupo" width="160"></el-table-column>
      <el-table-column prop="weight" label="Peso"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'PsPatientsListAllWeights',
  computed: {
    ...mapState('patients', ['allWeights']),
  },
}
</script>

<style scoped>
  .psPatientsListAllWeights { padding: 32px;}
</style>