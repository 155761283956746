<template>
  <div class="psGroupsDetailUpperBar column">
    <div class="row rowMenu">
      <ps-back-button v-show="isMobile == true"></ps-back-button>
      <ps-open-menu-button></ps-open-menu-button>
    </div>
    <div class="row">
      <ps-back-button v-if="isMobile == false"></ps-back-button>
      <div class="containerSite column">
        <div class="row" style="margin-bottom: 0">          
          <div class="site">{{ $route.params['groupId'] == 0 ? site.site : group.groupDescription }}</div>
          <i :class="icon" class="icon" @click="clickIcon" v-if="isAdmin && $route.params['groupId'] > 0"></i>
        </div>
      </div>
      <ps-avatar class="avatar"></ps-avatar>
    </div>
    <div class="row">
      <div class="rowDate" @click="selectDate" v-if="group.groupId > 0">
        <span><i class="el-icon-date"></i></span>
        <span>{{['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'][new Date(selectedDate).getDay()]}}</span>
        <span>{{selectedDate | formatDateDDMMYYYY}}</span>
      </div>
      <div class="showPdf" @click="showWeekSummary" v-if="group.groupId > 0">
        <span><i class="el-icon-document"></i></span>
        <span>Ver resumen semanal</span>
      </div>
    </div>
  </div>
</template>

<script>
import PsAvatar from '@/components/layout/PsAvatar.vue'
import PsBackButton from '@/components/PsBackButton.vue'
import PsOpenMenuButton from '@/components/PsOpenMenuButton.vue'
// import BtnFakePatient from './BtnFakePatient.vue'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  name: 'PsGroupsDetailUpperBar',
  props: {
    site: Object,
    icon: String
  },
  components: { PsAvatar, PsBackButton, PsOpenMenuButton },
  computed: {
    ...mapState('routes', ['routeSite']),
    ...mapState('groups', ['editionMode', 'group', 'selectedDate']),
    ...mapState(['user', 'isMobile']),
    ...mapGetters(['isAdmin'])
  },
  methods: {
    ...mapMutations('groups', ['GROUPS_DIALOG_SELECT_DATE']),
    ...mapActions('groups', ['showWeekSummaryHTTP']),
    clickIcon () {
      this.$emit('clickIcon')
    },
    selectDate () {
      this.GROUPS_DIALOG_SELECT_DATE(true)
    },
    async showWeekSummary () {
      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Descargando resumen semanal...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });   

        await this.showWeekSummaryHTTP()
      } catch ( err ) { this.$message( { message: err.response.data.err.details, type: 'error' })}
      finally { loading.close() }
    },
  },
}
</script>

<style scoped>
  .psGroupsDetailUpperBar { height: 60px;  display: flex; margin-top: 50px; }
  .psGroupsDetailUpperBar > .row { margin-bottom: 0; padding-left: 10px;}
  .rowDate, .showPdf { margin-left: 10px; color: #555; cursor: pointer; padding: 5px 14px; border: 1px solid #999; border-radius: 4px; box-shadow: 1px 1px 3px 1px #ccc;}
  .rowDate span { margin-right: 8px;}
  .containerSite { display: flex; flex-direction: column; align-items: flex-start;}
  .site { font-weight: bold; font-size: 28px; line-height: 38px; }

  .icon { line-height: 38px; margin-left: 20px; cursor: pointer; transition: 0.2s ease} 
  .icon:hover { font-size: 1.1rem; font-weight: bold;}
  .rowMenu { justify-content: space-between; width: 100%; line-height: 40px; }


    /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .psGroupsDetailUpperBar {margin-top: 16px; height: 120px; margin-right: 0; margin-bottom: 0px;}
    .psGroupsDetailUpperBar > .row { padding-left: 0px;}
    .avatar { display: none; }
    .menu { display: block; align-self: flex-end;}
    .rowDate { margin-left: 0px; }
  }
</style>