<template>
  <div class="psAdministrationPanel">
    <ps-upper-bar :site="{ site: 'Administración' }"></ps-upper-bar>
    

    <div class="containerIndicator">
      <div class="row rowDate">
        <div class="rowDateFilter">
          <div class="column w-40">
            <div class="label" style="margin-bottom: 8px;">Mes</div>
            <el-date-picker 
              type="month" 
              placeholder="Seleccionar un mes" 
              :value="monthSelected" 
              @input="selectMonth($event)"
              :picker-options="pickerOptions"
              :format="'MM/yyyy'"></el-date-picker>
          </div>
          <div class="column w-10">
            <div class="label"  style="margin-bottom: 8px;">&nbsp;</div>
            <el-button round type="primary" @click="filterData">Filtrar</el-button>
          </div>
        </div>

        <ps-indicator-amount :value="summary.projected" :config="configProjected"></ps-indicator-amount>
        <ps-indicator-amount :value="summary.incomings" :config="configIncomings"></ps-indicator-amount>

      </div>

      <div class="column projected" style="margin-top: 40px" v-if="summary.administrators">
        <div class="titlesIndicators">
          <div class="w-15">&nbsp;</div>
          <div class="w-25">TOTAL</div>
          <div class="w-30">MILAGROS</div>
          <div class="w-30">SOFIA</div>
        </div>

        <div class="rowIndicators" style="background-color: #3E8091; color: white">
          <div class="w-15" style="font-size: 14px; line-height: 25px">PROYECTADO</div>
          <p class="titleMobile">TOTAL</p>
          <div class="w-25">{{summary.projectedOficial | money}}<span class="percentageIndicador" style="padding-left: 0">&nbsp;</span></div>
          <p class="titleMobile">MILAGROS</p>
          <div class="w-30">{{summary.administrators[0].projectedOficial | money}}<span class="percentageIndicador">{{summary.administrators[0].projectedPercentage | percentage}}</span></div>
          <p class="titleMobile">SOFIA</p>
          <div class="w-30">{{summary.administrators[1].projectedOficial | money}}<span class="percentageIndicador">{{summary.administrators[1].projectedPercentage | percentage}}</span></div>
        </div>
        <div class="rowIndicators" style="background-color: white; color: #3E8091">
          <div class="w-15" style="font-size: 14px; line-height: 25px">INGRESADO</div>
          <p class="titleMobile" style="color: black;">TOTAL</p>
          <div class="w-25">{{summary.incomingsOficial | money}}<span class="percentageIndicador" style="padding-left: 0">&nbsp;</span></div>
          <p class="titleMobile" style="color: black;">MILAGROS</p>
          <div class="w-30">{{summary.administrators[0].incomingsOficial | money}}<span class="percentageIndicador">{{summary.administrators[0].incomingsPercentage | percentage}}</span></div>
          <p class="titleMobile" style="color: black;">SOFIA</p>
          <div class="w-30">{{summary.administrators[1].incomingsOficial | money}}<span class="percentageIndicador">{{summary.administrators[1].incomingsPercentage | percentage}}</span></div>
        </div>
      </div>
    </div>

    <div class="containerAccounts">
      <div class="administrator" v-for="(administrator,index) in summary.administrators" :key="index">
        <div class="name">{{administrator.name}}</div>
        <div class="accountsTitles">
          <div class="w-20" style="text-align: left">CUENTA</div>
          <div class="w-25">Proyectado ($)</div>
          <div class="w-25">Ingresado ($)</div>
          <div class="w-25 maxAccount">Máximo ($)</div>
        </div>
        <div class="accountRow" v-for="(account, index2) in administrator.accounts" :key="index2"  :class="{ accumulatesOficial: account.accumulatesOficial}">
          <div class="w-20" style="text-align: left">{{account.accountDescription}}</div>
          <div class="w-25" :style="account.accountMaximum > 0 && account.accountProjected >= account.accountMaximum ? 'color: red;' : ''">{{account.accountProjected | money}}</div>
          <div class="w-25" :style="account.accountMaximum > 0 && account.accountIncomings >= account.accountMaximum ? 'color: red;' : ''">{{account.accountIncomings | money}}</div>
          <div class="w-25 maxAccount">{{account.accountMaximum | money}}</div>
        </div>
        <div class="accountsTotals">
          <div class="w-20" style="text-align: left; color: #3E8091; font-size: 14px; font-weight: bold; ">TOTAL:</div>
          <div class="w-25">{{summary.administrators[index].projected | money}}</div>
          <div class="w-25">{{summary.administrators[index].incomings | money}}</div>
          <div class="w-25 maxAccount">&nbsp;</div>
        </div>
      </div>
    </div>

    <div class="row" style="justify-content: center;">
      <el-button icon="el-icon-view" type="info" @click="$router.push('/administration/subscriptions')">Ver suscripciones</el-button>
      <el-button icon="el-icon-view" type="info" @click="$router.push('/administration/transactions')">Ver pagos</el-button>
    </div>
  </div>
</template>

<script>
import PsUpperBar from '@/components/layout/PsUpperBar.vue'
import PsIndicatorAmount from '@/components/PsIndicatorAmount.vue'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsAdministrationPanel',
  components: { PsUpperBar, PsIndicatorAmount },
  data () {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      configProjected: {
        amount: {
          color: 'white',
          'background-color': '#3E8091',  
        },
        labelIndicator: {
          text: 'Proyectado',
          color: 'black'
        },
      },
      configIncomings: {
        amount: {
          color: '#3E8091',
          'background-color': 'white',   
        },
        labelIndicator: {
          text: 'Ingresado',
          color: 'black'
        },
      },
    }
  },
  computed: {
    ...mapState('administration', ['summary', 'monthSelected']),
    ...mapState(['isMobile']),
    totalMilagros () {
      let milagros = this.$options.filters.numberWithoutDecimal(this.summary.milagros)
      return milagros == '' ? '0.00' : milagros
    },
    totalSofia () {
      let sofia =  this.$options.filters.numberWithoutDecimal(this.summary.sofia)
      return sofia == '' ? '0.00' : sofia
    },
    userIdMilagros () {
      return 3
    },
    userIdSofia () {
      return 4
    },
  },
  methods: {
    ...mapMutations('administration', ['ADMINISTRATION_MONTH_SELECTED']),
    ...mapActions('administration', ['getAdministrationSummaryHTTP']),
    ...mapActions('concepts', ['getConceptsHTTP']),
    selectMonth ( month ) {
      this.ADMINISTRATION_MONTH_SELECTED(month)
    },
    async filterData () {
      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Filtrando mes seleccionado...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        let year = this.monthSelected.getFullYear()
        let month = this.monthSelected.getMonth() + 1
        await this.getAdministrationSummaryHTTP({year, month})
      } catch ( err ) { this.$message( { message: 'Error al mostrar los datos de administracion. Inténtelo nuevamente', type: 'danger'})}
      finally { loading.close()}
    },
  },
  async created () {
    let today = new Date()
    let year = today.getFullYear()
    let month = today.getMonth() + 1
    await this.getAdministrationSummaryHTTP( { year, month})
    await this.getConceptsHTTP()
  }
}
</script>

<style scoped>
  .psAdministrationPanel { overflow-y: scroll; height: 100%; padding-bottom: 100px;}
  .psAdministrationPanel::-webkit-scrollbar { display: none; }

  .rowDateFilter { display: flex; width: 500px}
  .containerIndicator { 
    display: flex; 
    flex-direction: column; 
    background-color: white; 
    padding: 25px; 
    border-radius: 20px; 
    width: 100%; 
    margin-bottom: 30px;
    margin-top: 20px;
    box-shadow: 0px 1px 2px 1px #bbb;
  }
  .titlesIndicators { display: flex; width: 100%; font-size: 14px; font-weight: bold; margin-bottom: 8px;}
  .title { font-size: 1rem; font-weight: bold; text-align: left; margin-bottom: 15px }
  
  .rowIndicators { 
    display: flex; 
    justify-content: flex-start;
    width: 100%; 
    border-radius: 8px; 
    box-shadow: 0px 1px 2px 1px #bbb; 
    padding: 16px 0; 
    font-weight: bold;
    font-size: 21px;
    margin-bottom: 8px;
  }

  .titleMobile { display: none}
  .percentageIndicador { color: #444; padding-left: 20px}

  .containerAccounts { 
    display: flex; 
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: white; 
    padding: 25px; 
    border-radius: 20px;  
    margin-top: 10px;
    box-shadow: 0px 1px 2px 1px #bbb;
    margin-bottom: 30px;
    width: 100%;
  }

  .containerAccounts > .administrator { display: flex; flex-direction: column; width: 46%; margin-right: 20px; margin-bottom: 60px }
  .containerAccounts > .administrator > .name { font-size: 1.25rem; line-height: 2.25rem; font-weight: bold; text-align: left; align-self: flex-start; margin-bottom: 20px}
  .accountsTitles { color: #3E8091; font-size: 14px; font-weight: bold; display: flex; border-bottom: 1px solid #eee; padding-bottom: 8px; }
  .accountsTitles > .w-25, .accountRow > .w-25, .accountsTotals > .w-25 { text-align: right;}

  .accountRow { display: flex; border-bottom: 1px solid #eee; padding: 8px 0 }

  .accountsTotals { display: flex; margin-top: 24px;}
  .accountsTotals > .w-25 { font-weight: bold; }

  .contentTableRecords { overflow-y: scroll; height: 80%; padding: 0 5px; }
  .contentTableRecords::-webkit-scrollbar { display: none}
  .tableTitles { display: flex; padding-left: 15px ;}
  .titlePrimary, .titleBlack { color: #0290af; font-weight: bold; font-size: 0.9rem; margin-bottom: 10px; text-align: left;}
  .titleBlack { color: black; width: 120px;}

  .date {width: 100px}
  .concept {width: 120px}
  .type {width: 100px}
  .entity {width: 220px}
  .total { width: 140px}

  .totalRow { display: flex; font-weight: bold; margin-top: 5px;}
  .totalRow > .totalLabel { width: 540px; padding: 5px 150px 5px; text-align: right;}
  .totalRow > .total { padding: 5px; text-align: left;}
  .totalRow > .totalMilagros { width: 120px; background-color: #60E2FF; padding: 5px; text-align: left;}
  .totalRow > .total { padding: 5px}
  .totalRow > .totalSofia { width: 120px; background-color: #3E8091; padding: 5px; text-align: left}
  .linkDetailAccount { cursor: pointer}

  .accumulatesOficial { background-color: #ffdced }


    /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .tooltip { display: none;}    
    .rowDate { flex-direction: column; }

    .rowDateFilter { width: 100%; margin-bottom: 25px}
    .containerIndicator { width: auto;}
    .containerIndicator > .row { align-items: center;}
    .psIndicatorAmount { min-width: 250px;}

    .projected > .titlesIndicators { display: none}
    .projected > .rowIndicators { flex-direction: column; align-items: center;}
    .projected > .rowIndicators > div { width: 75%; margin-bottom: 25px}

    .titleMobile { display: inline; font-size: 0.75rem; margin-bottom: 5px;}
    .concept, .type, .titleBlack { display: none}
    .date { width: 120px}
    .containerAccounts > .administrator { width: 100%; font-size: 0.85rem;}
    .containerAccounts > .administrator .w-25 { width: 50%}
    
    .maxAccount { display: none}
    .totalRow { margin-top: 15px }
    .totalRow > .totalLabel { width: 300px; text-align: left; padding: 5px 0 5px 30px;}
    .totalRow > .totalMilagros, .totalRow > .totalSofia { display: none;}
    .linkDetailAccount {
      border: 1px solid #ddd; margin-bottom: 10px; padding: 5px; border-radius: 8px; position: relative
    }
    .btnPointMobile { font-size: 20px; position: absolute; bottom: 15px; right: 10px}
  }

</style>