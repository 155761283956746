import api from "../services/api"
import {addProperyIfNotExists} from "../services/utils"

const state = {
  eventual: {},
  newEventual: {
    eventualId: 0,
    eventualDescription: '',
    active: true,
    createdBy: 0,
    updatedBy: 0,
    createdAt: '',
    updatedAt: ''
  },
  eventuals: [],
  errors: []
}

const mutations = {
  EVENTUALS_EVENTUALS ( state, eventuals ) { state.eventuals = eventuals },
  EVENTUALS_EVENTUAL ( state, eventual ) { state.eventual = addProperyIfNotExists(eventual, state.newEventual) },
  EVENTUALS_EVENTUAL_PROP ( state, payload ) { state.eventual[payload.property] = payload.value },
  EVENTUALS_EMPTY_ERRORS ( state ) { state.errors = [] },
  EVENTUALS_ADD_ERROR ( state, error ) { state.errors.push(error)}
}

const actions = {
  async getEventualsHTTP ( { commit }) {
    const eventuals = await api.get('/configuration/eventuals')
    commit('EVENTUALS_EVENTUALS', eventuals.data.data)
  },
  async saveEventual ( { state, commit, dispatch } ) {
    commit('EVENTUALS_EMPTY_ERRORS')
    if( state.eventual.eventualDescription == '') commit('EVENTUALS_ADD_ERROR', 'La descripción de la información eventual es requerida')

    
    if( state.errors.length == 0 ) {
      if( state.eventual.eventualId == 0 ) {
        
        return await dispatch('createEventualHTTP')
      } else {
        return await dispatch('updateEventualHTTP')
      }
    }
  },
  async createEventualHTTP ( { state, rootState } ) {
    let eventual = state.eventual
    eventual.createdBy = rootState.user.userId
    await api.post('/configuration/eventuals', eventual )
    return 'Información eventual creada'
  },
  async updateEventualHTTP ( { state, rootState } ) {
    let eventual = state.eventual
    eventual.createdBy = rootState.user.userId
    eventual.updatedBy = rootState.user.userId
    await api.put('/configuration/eventuals', eventual )
    return 'Información eventual actualizada'
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}