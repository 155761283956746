<template>
  <div class="groupListItem" :style="backgroundImage" @click="select" >
    <div class="groupName" >{{group.groupDescription}}</div>
    <!-- <img :src="group.groupPathImage" :alt="`Imagen de ${group.groupDescription}`" width="100%" > -->
  </div>
</template>

<script>
export default {
  name: 'PsGroupListItem',
  props: ['group'],
  computed: {
    backgroundImage () {
      return {
        background: `linear-gradient(180deg, rgba(200,200,200,1) -10%, rgba(0,0,0,0.45) 80%), url(${this.group.groupPathImage}) no-repeat center`,
      }
    }
  },
  methods: {
    select () {
      this.$router.push(`/groups/detail/${this.group.groupId}`)
    }
  }
}
</script>

<style scoped>
  .groupListItem { width: 295px; height: 130px; min-height: 130px; border-radius: 20px; margin: 10px 20px 10px 0; position: relative; transition: 0.3s ease;}
    
  .groupListItem::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0.35) 100%); */
    z-index: -1;
    border-radius: 20px;
  }
  .groupListItem:hover {
    /* box-shadow: 1px 1px 6px 2px #aaa; */
    transform: scale(1.01);
    cursor: pointer;
  }
  .groupName { position: absolute; bottom: 5px; text-align: left; width: 100%; color: white; font-size: 32px; font-weight: bold; padding-left: 16px;}

  /* Medium devices (tablets, less than 992px) */
  /* @media (max-width: 991.98px) {
    .groupListItem { width: 100%; margin-right: 0;}
  } */

</style>