<template>
  <div class="psHomeDecreaseInPatients">
    <div class="title">Mayor descenso de peso</div>
    <div class="subtitle">Última semana</div>
    <template v-if="indicators && indicators.decreaseInPatients && indicators.decreaseInPatients.length > 0"> 
      <ps-home-decrease-in-patients-item v-for="(patient, index) in indicators.decreaseInPatients" :key="index" :patient="patient"></ps-home-decrease-in-patients-item>
    </template>
    <div class="withoutPatients" v-else>
      Sin pacientes para mostrar
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import PsHomeDecreaseInPatientsItem from './PsHomeDecreaseInPatientsItems.vue'

export default {
  name: 'psHomeDecreaseInPatients',
  components: { PsHomeDecreaseInPatientsItem },
  computed: {
    ...mapState('home', ['indicators']),
  }
}
</script>

<style scoped>
  .psHomeDecreaseInPatients { display: flex; flex-direction: column; width: 380px; align-items: flex-start;}
  .title { font-weight: bold; font-size: 1.25rem;}
  .subtitle { font-weight: bold; font-size: 0.9rem; margin: 5px 0}
  .withoutPatients { min-height: 250px; width: 100%; display: flex; justify-content: center; align-items: center; background-color: white; border-radius: 16px;}
</style>