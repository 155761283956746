<template>
  <div class="psIndicatorAmount">
    <div class="labelIndicator" :style="config.labelIndicator.color">{{config.labelIndicator.text}}</div>
    <div class="value" :style="config.amount">{{value | money }} </div>
  </div>
</template>

<script>
export default {
  name: 'PsIndicatorAmount',
  props: [ 'config', 'value', 'labelIndicator' ],
}
</script>

<style scoped>
  .psIndicatorAmount { width: 210px; margin-right: 30px; }
  .measure { height: 100px; display: flex; justify-content: center; align-items: center; border-radius: 8px; position: relative}
  .value { font-size: 21px; font-weight: 900; padding: 10px; border-radius: 4px; box-shadow: 1px 2px 3px 1px #b9b9b9; }
  .variation { font-size: 37px; font-weight: 500 ;}
  .labelIndicator { font-size: 15px; font-weight: bold; margin-bottom: 4px;}


        /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .psIndicatorAmount { min-width: 140px; width: 45%; margin-bottom: 16px;}
    .measure { height: 80px; }
    .value { font-size: 30px; }
    .labelIndicator { font-size: 13px;}    
  }
</style>