<template>
  <div class="menu" @click="openMenu"><i class="el-icon-menu"></i></div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'PsOpenMenuButton',
  methods: {
    ...mapMutations(['DRAWER_MENU']),
    openMenu () {
      this.DRAWER_MENU(true)
    },
  }
}
</script>

<style scoped>
  .menu { display: none; font-size: 1.25rem;}
</style>