var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"psPatientEventualInformation"},[_c('div',{staticClass:"row rowTitle"},[_vm._v(" Información eventual "),_c('ps-patient-btn-new-eventual')],1),_c('div',{staticClass:"row columnTags"},_vm._l((_vm.patient.eventuals),function(eventual,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"row rowDate"},[_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm._f("formatDatetimeDDMMYYYYhhmm")(eventual.createdAt))+" ")]),(
            !(
              eventual.eventualDescription &&
              eventual.eventualDescription != ''
            )
          )?_c('i',{staticClass:"icon el-icon-edit",on:{"click":function($event){return _vm.editItem(eventual)}}}):_vm._e()]),(
          eventual.eventualDescription && eventual.eventualDescription != ''
        )?_c('div',{staticClass:"row rowTag"},[_c('el-tag',[_vm._v(_vm._s(eventual.eventualDescription))]),_c('i',{staticClass:"icon el-icon-edit",on:{"click":function($event){return _vm.editItem(eventual)}}})],1):_vm._e(),_c('div',{staticClass:"detail"},[_vm._v(_vm._s(eventual.detail))])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }