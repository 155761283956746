<template>
  <div class="psConfiguration view">
    <ps-page-container :site="{ site: 'Configuración' }"></ps-page-container>
  </div>
</template>

<script>
import PsPageContainer from '@/components/layout/PsPageContainer.vue'

export default {
  name: 'PsConfiguration',
  components: { PsPageContainer }
  
}
</script>

<style scoped>

</style>