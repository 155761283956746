<template>
  <div class="psPatientMedicationDetail">
    <div class="row">
      <div class="column w-100">
        <div class="label">Medicación</div>
        <el-input :value="itemMedication.description" @input="PATIENTS_ITEM_MEDICATION_PROP({property: 'description', value: $event})"></el-input>
      </div>
      <div class="column" v-if="itemMedication.id > 0">
        <div class="label">&nbsp;</div>
        <el-button type="danger" icon="el-icon-delete" plain @click="deleteMedication"></el-button>
      </div>
    </div>
    <el-button @click="saveMedication" icon="el-icon-check" type="success" round class="btnSave" :disabled="itemMedication.description == ''">{{ itemMedication.id > 0 ? 'Actualizar' : 'Guardar' }}</el-button>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsPatientMedicationDetail',
  computed: {
    ...mapState('patients', ['itemMedication'])
  },
  methods: {
    ...mapMutations('patients', ['PATIENTS_ITEM_MEDICATION_PROP', 'PATIENTS_DIALOG_MEDICATION']),
    ...mapActions('patients', ['saveMedicationPatient', 'getPatientHTTP', 'deleteMedicationPatientHTTP']),
    async saveMedication () {
      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Guardando...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });   

        const message = await this.saveMedicationPatient()
        this.$message( { message, type: 'success' } )
        setTimeout( async () => {
          await this.getPatientHTTP( this.$route.params['patientId'])
          loading.close()
          this.PATIENTS_DIALOG_MEDICATION(false)
        }, 1000)
      } catch ( err ) { this.$message( { message: err.response.data.err.details, type: 'error' })}
      finally { loading.close()}
     
    },
    async deleteMedication () {
      let loading
      try {
        this.$confirm('¿Quiere eliminar esta medicación?', 'Confirmación', {
          type: 'warning',
          confirmButtonText: 'Si',
          cancelButtonText: 'No'
        })
        .then( async () => {
          loading = this.$loading({
            lock: true,
            text: 'Eliminando...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          }); 
          const message = await this.deleteMedicationPatientHTTP()
          setTimeout( async () => {
            await this.getPatientHTTP( this.$route.params['patientId'])
            this.PATIENTS_DIALOG_MEDICATION(false)
            loading.close()
            this.$message.success(message)
          }, 1000)
        })
      } catch ( err ) { this.$message.error('No se pudo eliminar esta medicación.')}
      finally { loading.close()}
    }
  }
}
</script>

<style>

</style>