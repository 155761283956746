<template>
  <div class="psAdministrationTransactionsList">
    <div class="rowTotal">
      <div class="date">&nbsp;</div>
      <div class="concept">&nbsp;</div>
      <div class="type">&nbsp;</div>
      <div><div class="entity">MONTO SUMADO ($)</div>
      <div class="total">{{transactionsFiltered.reduce( (acc, curr) => { 
        acc = curr.type.toLowerCase() == 'ingreso' ? acc + Number(curr.total) : acc - Number(curr.total); 
        return acc; 
      } , 0) | money}}</div></div>
      <div class="wayToPay">{{transactionsFiltered.length}} movimientos</div>
      <div class="account">&nbsp;</div>
    </div>


    <div class="rowTitles">
      <div class="date">FECHA</div>
      <div class="concept">CONCEPTO</div>
      <div class="type">TIPO MOVIMIENTO</div>
      <div class="entity">ENTIDAD</div>
      <div class="total">MONTO ($)</div>
      <div class="wayToPay">MEDIO DE PAGO</div>
      <div class="account">CUENTA</div>
    </div>

    <div class="transaction" v-for="(transaction, index) in transactionsFiltered" :key="index" >
      <div class="date">{{transaction.paidOutDatetime | formatDateDDMMYYYY}}</div>
      <div class="concept">{{transaction.conceptDescription | textCapitalize}}</div>
      <div class="type">{{transaction.type | textCapitalize}}</div>
      <div class="entity patientLink" @click="selectPatient(transaction.patientId)">{{transaction.entity | textCapitalize}}</div>
      <div class="total">{{transaction.total | money}}</div>
      <div class="wayToPay">{{transaction.wayToPayDescription | textCapitalize}}</div>
      <div class="account">{{transaction.accountDescription | textCapitalize}}</div>
    </div>


  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PsAdministrationTransactionsList',
  computed: {
    ...mapState('administration', ['transactionsFiltered']),
  },
  methods: {
    selectPatient(patientId) {
      if(patientId > 0) {
        this.$router.push(`/patients/detail/${patientId}`)
      }
    },
  }
}
</script>

<style scoped>
  .psAdministrationTransactionsList { display: flex; flex-direction: column; }
  .rowTitles, .rowTotal { display: flex; margin: 25px 0 10px ; color: #3E8091; font-size: 13px; font-weight: bold; text-align: left; padding-left: 12px}
  .rowTotal { font-size: 14px; }

  .rowTotal > div { display: flex}
  .rowTotal .entity, .rowTotal .total { background-color: #60E2FF; color: #333; padding: 8px 0}
  .rowTotal .entity { border-top-left-radius: 8px; border-bottom-left-radius: 8px; padding-left: 16px }
  .rowTotal .wayToPay { background-color: #3E8091; color: white; line-height: 32px; border-top-right-radius: 8px; border-bottom-right-radius: 8px; padding-right: 6px; padding-left: 12px}
  
  .date, .total { width: 100px}
  .concept { width: 120px}
  .type, .wayToPay, .account { width: 140px }
  .entity { width: 250px }

  .transaction { display: flex; padding: 12px; box-shadow: 0px 1px 2px 1px #bbb; border-radius: 8px; margin-bottom: 8px; font-size: 14px; text-align: left}

  .patientLink { cursor: pointer; }

  .patientLink:hover { font-weight: bold;}

  @media (max-width: 991.98px) {
    .rowTotal > .date, .rowTotal > .concept, .rowTotal > .type, .rowTotal > .account { display: none }
    .rowTotal { font-size: 0.8rem; flex-direction: column; }

    .rowTotal .total { border-top-right-radius: 8px; border-bottom-right-radius: 8px}
    .rowTotal .wayToPay { border-radius: 8px; margin-top: 8px}

    .rowTitles, .transaction { font-size: 0.75rem;}
    .rowTitles > .concept, .rowTitles > .type, .rowTitles > .entity { display: none; }
    .transaction > .concept, .transaction > .type, .transaction > .entity { display: none; }

    .rowTitles > .date, .transaction > .date, .rowTitles > .total, .transaction > .total { width: 110px}
    .rowTitles > .wayToPay, .transaction > .wayToPay { width: 160px}

    .rowTitles > .account, .transaction > .account { width: 120px}
  }
</style>