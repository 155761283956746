<template>
  <div class="psAdministrationSubscriptionsFilters" :style="closed == true ? 'padding-bottom: 0' : 'padding-bottom: 15px;'" >
    <div class="row filterTitle"  @click="clickFilters" :style="closed == true ? 'margin-bottom: 20px;' : 'margin-bottom: 0'" >Filtros<span><i :class="closed == true ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"></i></span></div>
    
    <div class="row rowFilters" :class="closed == true ? 'closed' : 'opened'">
      
      <div class="first w-33">
        <div class="column w-50">
          <div class="label">Tarifa</div>
          <el-select v-model="rate" value-key="rateDescription" multiple collapse-tags size="mini">
            <el-option v-for="(rate, index) in rates" :key="index" :label="rate.rateDescription" :value="rate"></el-option>
          </el-select>
        </div>

        <div class="column w-50">
          <div class="label">Estado</div>
          <el-select v-model="state" value-key="description" multiple collapse-tags size="mini">
            <el-option v-for="(state, index) in statesSubscriptions" :key="index" :label="state.description" :value="state"></el-option>
          </el-select>
        </div>
      </div>

      <div class="second w-33">
        <div class="column w-50">
          <div class="label">Medio de pago</div>
          <el-select v-model="wayToPay" value-key="wayToPayDescription" multiple collapse-tags size="mini">
            <el-option v-for="(wayToPay, index) in waysToPayWithEmpty" :key="index" :label="wayToPay.wayToPayDescription" :value="wayToPay"></el-option>
          </el-select>
        </div>

        <div class="column w-50">
          <div class="label">Cuenta</div>
          <el-select v-model="account" value-key="accountDescription" multiple collapse-tags size="mini">
            <el-option v-for="(account, index) in accountsWithEmpty" :key="index" :label="account.accountDescription" :value="account"></el-option>
          </el-select>
        </div>
      </div>

      <div class="thirth w-15">
        <div class="column w-50">
          <div class="label">Lleva factura</div>
          <el-switch v-model="takesInvoice"></el-switch>
        </div>
        
        <div class="column w-50">
          <div class="label">Facturado</div>
          <el-switch v-model="invoiced"></el-switch>
        </div>
      </div>

      <div class="fourth w-10">
        <div class="column w-50">
          <div class="label">&nbsp;</div>
          <el-button round type="primary" @click="filterData" size="mini">Filtrar</el-button>
        </div>
        <div class="column w-50">
          <div class="label">&nbsp;</div>        
          <el-button icon="el-icon-close" circle size="mini" type="danger" plain @click="cleanFilters"></el-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'psAdministrationSubscriptionsFilters',
  data () {
    return {
      closed: true,
      rate: [],
      wayToPay: [],
      account: [],
      state: [],
      takesInvoice: false,
      invoiced: false,
    }
  },
  computed: {
    ...mapState('rates', ['rates']),
    ...mapState('waysToPay', ['waysToPay']),
    ...mapState('accounts', ['accounts']),
    ...mapState('administration', ['subscriptions', 'statesSubscriptions']),
    waysToPayWithEmpty () {
      let waysToPay = this.waysToPay.slice()
      waysToPay.push( { wayToPayId: 0, wayToPayDescription: 'Sin medio de pago' })
      return waysToPay
    },
    accountsWithEmpty () {
      let accounts = this.accounts.slice()
      accounts.push( { accountId: 0, accountDescription: 'Sin cuenta' })
      return accounts
    },
  },
  methods: {
    ...mapMutations('administration', ['ADMINISTRATION_SUBSCRIPTIONS_FILTERED']),
    clickFilters () {
      this.closed = !this.closed
    },
    cleanFilters () {
      this.rate = this.state = this.wayToPay = this.account = []
      this.takesInvoice = this.invoiced = 0
      this.filterData()
    },
    filterData () {
      let subscriptionsFiltered = this.subscriptions.slice()
      if ( this.rate && this.rate.length > 0) {
        subscriptionsFiltered = subscriptionsFiltered.filter( i => [...this.rate.map( r => r.rateId )].includes(i.rateId) )
      }
      if ( this.state && this.state.length > 0) {
        subscriptionsFiltered = subscriptionsFiltered.filter( i => [...this.state.map( s => s.id )].includes(i.statusPaidOut))
      }
      if ( this.wayToPay && this.wayToPay.length > 0) {
        subscriptionsFiltered = subscriptionsFiltered.filter( i => [...this.wayToPay.map( w => w.wayToPayId )].includes(i.wayToPayId) )
      }
      if ( this.account && this.account.length > 0) {
        subscriptionsFiltered = subscriptionsFiltered.filter( i => [...this.account.map( a => a.accountId )].includes(i.accountId == null ? 0 : i.accountId) )
      }
      if( this.takesInvoice && this.takesInvoice == true) {
        subscriptionsFiltered = subscriptionsFiltered.filter( i => i.takesInvoice == this.takesInvoice )        
      }
      if( this.invoiced && this.invoiced == true) {
        subscriptionsFiltered = subscriptionsFiltered.filter( i => i.invoiced == true )        
      }

      this.ADMINISTRATION_SUBSCRIPTIONS_FILTERED( subscriptionsFiltered )
    }
  }
}
</script>

<style scoped>
  .psAdministrationSubscriptionsFilters { display: flex; flex-direction: column; border-bottom: 1px solid #3E8091; }
  .filterTitle { color: #3E8091;  }
  .filterTitle > span { margin-left: 20px}
  .rowFilters { height: 0px; overflow: hidden; margin-bottom: 0; }
  .closed { height: 80px}
  .first, .second, .thirth, .fourth { display: flex}

  @media (max-width: 991.98px) {
    .rowFilters { flex-direction: column; width: 100% }
    
    .closed { height: 190px}

    .first, .second, .thirth { width: 100%}
     .fourth { width: 45%}

  }
</style>