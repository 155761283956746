<template>
  <div class="psCalendarGridMonth">
    <div class="row">
      <div class="fullYear">{{calendarMonth.fullYear}}</div>
      <div class="monthDescription">{{calendarMonth.monthDescription | toUpperCase}}</div>
    </div>
    <div class="container">
      <ps-calendar-grid-day v-for="(calendarDay, index) in calendarMonth.days" :calendar-day="calendarDay" :key="index" :index="index"></ps-calendar-grid-day>
    </div>
  </div>
</template>

<script>
import PsCalendarGridDay from './PsCalendarGridDay.vue'
import { mapState } from 'vuex'

export default {
  name: 'PsCalendarGridMonth',
  props: ['calendarMonth'],
  components: { PsCalendarGridDay },
  computed: {
    ...mapState('calendar', ['calendar']),
  }
}
</script>

<style scoped>
  .psCalendarGridMonth { margin-bottom: 40px;}
  .fullYear, .monthDescription { font-size: 22px;}
  .monthDescription { font-weight: bold; margin-left: 10px}
  .container { display: flex; flex-wrap: wrap; align-content: flex-start; gap: 20px; margin-top: 30px;  }
  .container::-webkit-scrollbar {
    width: 8px;     
    height: 100%;    
    display: none;  
  }
</style>