<template>
  <div class="psAdministrationTransactionDetail">
    <div class="row">
      <el-radio-group :value="typeTransaction" @input="changeType($event)">
        <el-radio-button label="Egreso"></el-radio-button>
        <el-radio-button label="Pase entre cuentas"></el-radio-button>
      </el-radio-group>

      <el-date-picker
        :value="transaction.paidOutDatetime"
        @input="changeProperty($event, 'paidOutDatetime')"
        :picker-options="pickerOptions"
        style="width: 180px; margin-left: 40px"
        placeholder="Seleccionar fecha"
        :format="'dd/MM/yyyy'"
      ></el-date-picker>
    </div>

    <div class="row">
      <div class="column w-50">
        <div class="label">Concepto</div>
        <el-select :value="transaction.conceptDescription" @change="changeProperty($event, 'concept')" :disabled="typeTransaction == 'Pase entre cuentas'">
          <el-option v-for="(concept, index) in concepts.filter( c => !['Tarifa', 'Pase entre cuentas'].includes(c.conceptDescription))" :key="index" :value="concept" :label="concept.conceptDescription"></el-option>
        </el-select>
      </div>

      <div class="column w-50" v-if="typeTransaction == 'Egreso'">
        <div class="label">Entidad</div>
        <el-input :value="transaction.entity" @input="changeProperty($event, 'entity')" placeholder="Ej: Moto, Edenor, etc"></el-input>
      </div>
    </div>

    <div style="display: flex; flex-direction: column; background-color: #3E809133; padding: 8px 16px; margin-bottom: 8px">
      <span style="text-align: left; font-weight: bold; align-self: flex-start; margin-bottom: 5px">Origen</span>
      <div class="row">
        <div class="column w-50" v-if="typeTransaction == 'Egreso'">
          <div class="label">Medio de pago</div>
          <el-select :value="transaction.wayToPayDescription" @input="changeProperty($event, 'wayToPay')">
            <el-option v-for="(wayToPay, index) in waysToPay" :key="index" :label="wayToPay.wayToPayDescription" :value="wayToPay"></el-option>
          </el-select>
        </div>

        <div class="column w-50">
          <div class="label">Cuenta</div>
          <el-select :value="transaction.accountDescription" @input="changeProperty($event, 'account')">
            <el-option v-for="(account, index) in accounts" :key="index" :label="account.accountDescription" :value="account"></el-option>
          </el-select>
        </div>
      </div>
    </div>

    <div style="display: flex; flex-direction: column; background-color: #3E809111; padding: 8px 16px; margin-bottom: 8px" v-if="typeTransaction == 'Pase entre cuentas'">
      <span style="text-align: left; font-weight: bold; align-self: flex-start; margin-bottom: 5px">Destino</span>
      <div class="row">

        <div class="column w-50">
          <div class="label">Cuenta</div>
          <el-select :value="transaction.accountDestinyDescription" @input="changeProperty($event, 'accountDestiny')">
            <el-option v-for="(account, index) in accounts" :key="index" :label="account.accountDescription" :value="account"></el-option>
          </el-select>
        </div>
      </div>
    </div>

    <div class="column">
      <div class="label">Monto ($)</div>
      <el-input-number :value="transaction.total" @input="changeProperty($event, 'total')" :controls="false" :min="0"></el-input-number>
    </div>

    <el-button @click="createTransaction" round type="primary" icon="el-icon-check" style="margin-top: 20px" :disabled="disabledSaveButton">Guardar</el-button>

  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'

export default {
  name: 'PsAdministrationTransactionDetail',
  data () {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      }
    }
  },
  computed: {
    ...mapState('concepts', ['concepts']),
    ...mapState('administration', ['transaction', 'typeTransaction']),
    ...mapState('waysToPay', ['waysToPay']),
    ...mapState('accounts', ['accounts']),
    disabledSaveButton () {
      let value = true
      if(this.typeTransaction == 'Egreso') {
        value = this.transaction.total == 0 || this.transaction.conceptId == 0 || this.transaction.entity == '' || this.transaction.wayToPayId == 0 || this.transaction.accountId == 0
      }

      if(this.typeTransaction == 'Pase entre cuentas') {
        value = this.transaction.total == 0 || this.transaction.conceptId == 0 || this.transaction.accountId == 0 || this.transaction.accountDestinyId == 0
      }

      return value
    }
  },
  methods: {
    ...mapMutations('administration', ['ADMINISTRATION_TRANSACTION_PROP', 'ADMINISTRATION_DIALOG_TRANSACTION', 'ADMINISTRATION_TRANSACTION',
    'ADMINISTRATION_TYPE_TRANSACTION']),
    ...mapActions('administration', ['saveTransaction', 'getAdministrationTransactionsHTTP']),
    changeProperty ( value, property ) {
      if ( property == 'concept' ) {
        this.ADMINISTRATION_TRANSACTION_PROP( { property: 'conceptId', value: value.conceptId } )
        this.ADMINISTRATION_TRANSACTION_PROP( { property: 'conceptDescription', value: value.conceptDescription } )
      } else if ( property == 'wayToPay' ) {
        this.ADMINISTRATION_TRANSACTION_PROP( { property: 'wayToPayId', value: value.wayToPayId } )
        this.ADMINISTRATION_TRANSACTION_PROP( { property: 'wayToPayDescription', value: value.wayToPayDescription } )
      } else if ( property == 'account' ) {
        this.ADMINISTRATION_TRANSACTION_PROP( { property: 'accountId', value: value.accountId } )
        this.ADMINISTRATION_TRANSACTION_PROP( { property: 'accountDescription', value: value.accountDescription } )
      } else if ( property == 'accountDestiny' ) {
        this.ADMINISTRATION_TRANSACTION_PROP( { property: 'accountDestinyId', value: value.accountId } )
        this.ADMINISTRATION_TRANSACTION_PROP( { property: 'accountDestinyDescription', value: value.accountDescription } )
      } else if ( property == 'paidOutDatetime' ) {
        this.ADMINISTRATION_TRANSACTION_PROP( { property: 'paidOutDatetime', value: value } )
        this.ADMINISTRATION_TRANSACTION_PROP( { property: 'year', value: value.getFullYear()} )
        this.ADMINISTRATION_TRANSACTION_PROP( { property: 'month', value: value.getMonth() + 1 } )
      } else {
        this.ADMINISTRATION_TRANSACTION_PROP( { property, value } )
      }
    },
    changeType ( typeTransaction ) {
      this.ADMINISTRATION_TYPE_TRANSACTION(typeTransaction)
      if( typeTransaction == 'Pase entre cuentas') {
        const concept = this.concepts.find( i => i.conceptDescription == 'Pase entre cuentas')
        this.changeProperty(concept, 'concept')
      } else {
        this.changeProperty( { conceptId: 0, conceptDescription: ''},'concept')
      }
      this.changeProperty( '','entity')
      this.changeProperty( 0,'total')
      this.changeProperty( { wayToPayId: 0, wayToPayDescription: ''},'wayToPay')
      this.changeProperty( { accountId: 0, accountDescription: ''},'account')
      this.changeProperty( { accountDestinyId: 0, accountDestinyDescription: ''},'accountDestiny')
    },
    async createTransaction () {
      if(this.typeTransaction == 'Pase entre cuentas' && this.transaction.accountId == this.transaction.accountDestinyId) {
        this.$alert('La cuenta de origen y de destino deben ser diferentes', 'Alerta', {
          confirmButtonText: 'Ok',
          callback: () => null          
        })
        return
      }

      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Guardando movimiento...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        const message = await this.saveTransaction()
        this.ADMINISTRATION_DIALOG_TRANSACTION(false)
        this.ADMINISTRATION_TRANSACTION({})
        let today = new Date()
        let year = today.getFullYear()
        let month = today.getMonth() + 1
        await this.getAdministrationTransactionsHTTP( { year, month})
        this.$message( { message, type: 'success'})
      } catch ( err ) { this.$message( { message: 'Error al guardar el movimiento. Inténtelo nuevamente', type: 'danger'})}
      finally { loading.close()}
      
      

    }
  }
}
</script>

<style scoped>
      /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .column { margin-bottom: 20px;}
  }
</style>