<template>
  <div class="psAdministration view">
    <ps-page-container :site="{ site: 'Administración' }"></ps-page-container>
    <el-dialog
      title="Nuevo movimiento"
      :visible.sync="dialogTransaction"
      :width="isMobile ? '90%' : '40%'"
      :before-close="handleClose">
        <ps-administration-transactions-detail></ps-administration-transactions-detail>
      </el-dialog>
  </div>
</template>

<script>
import PsPageContainer from '@/components/layout/PsPageContainer.vue'
import PsAdministrationTransactionsDetail from '@/views/administration/pages/panel/widgets/PsAdministrationTransactionsDetail.vue'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'PsAdministration',
  components: { PsPageContainer, PsAdministrationTransactionsDetail },
  computed: {
    ...mapState('administration', ['dialogTransaction']),
    ...mapState(['isMobile'])
  },
  methods: {
    ...mapMutations('administration', ['ADMINISTRATION_DIALOG_TRANSACTION']),
    handleClose () {
      this.ADMINISTRATION_DIALOG_TRANSACTION(false)
    }
  },

}
</script>

<style scoped>

</style>