<template>
  <div class="psHomeSubscriptions">
    <div class="title">Usuarios abonados</div>
    <div class="container" v-if="indicators && indicators.subscription && indicators.subscription.paidOutQuantity > 0">
      <div class="indicator" >
        <radial-progress-bar 
          :diameter=diameter
          :total-steps="100"
          :completed-steps="indicators.subscription.subscriptionPercentage"
          :start-color="color"
          :stop-color="color"
          :inner-stroke-color="innerStrokeColor"
          :stroke-width="strokeWidth"
          :inner-stroke-width="innerStrokeWidth"
          :stroke-line-cap="strokeLinCap"
          :animate-speed="animateSpeed"
          :timing-func="timingFunc"
          >
          <div class="dataInner">
            <div class="paidOut">{{indicators.subscription.paidOutQuantity}}</div>
            <div class="paidOutPercentaje">{{indicators.subscription.subscriptionPercentage}} %</div>
          </div>
        </radial-progress-bar>
        <div class="dataOut">{{indicators.subscription.paidOutQuantity}}/{{indicators.subscription.usersQuantity}}</div>
      </div>
      <div class="values">
        <div class="valueGroups">
          <div class="value">{{indicators.subscription.groupsQuantity}}</div>
          <div class="label">GRUPOS</div>
        </div>
        <div class="valueGroups">
          <div class="value">{{indicators.subscription.usersQuantity}}</div>
          <div class="label">USUARIOS</div>
        </div>
      </div>
    </div>
    <div class="withoutSubscription" v-else>
      Sin subscripciones para mostrar
    </div>
  </div>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress'
import { mapState } from 'vuex'

export default {
  name: 'PsHomeSubscriptions',
  components: { RadialProgressBar },
  data () {
    return {
      diameter: 250,
      innerStrokeColor: '#bbb', // calle interna circular para pintar
      strokeWidth: 15,
      innerStrokeWidth: 15,
      strokeLinCap: 'round',
      animateSpeed: 1000,
      timingFunc: 'linear',
      color: '#3E8091'
    }
  },
  computed: {
    ...mapState('home', ['indicators'])
  }
}
</script>

<style scoped>
  .psHomeSubscriptions { 
    width: 480px; 
    height: 310px;
    background-color: white;
    display: flex; 
    flex-direction: column;
    align-items: flex-start; 
    
    box-shadow: 1px 1px 1px #ddd;
    border-radius: 16px;
    padding: 16px;
    margin-left: 60px
  }
  .title { font-weight: bold; font-size: 1.25rem; margin-bottom: 10px;} 
  .container { display: flex; justify-content: space-between; width: 100%; align-items: center; }
  .indicator { width: 260px; height: 260px; position: relative;}
  .valueGroups { display: flex; flex-direction: column; justify-content: center; margin-bottom: 15px;}
  .value { 
    background-color: #60E2FF; 
    border-radius: 12px; 
    height: 50px; 
    width: 140px; 
    color: white; 
    font-weight: bold; 
    text-align: center; 
    line-height: 50px; 
    font-size: 1.25rem; 
    margin-bottom: 5px;
  }
  .valueGroups:nth-child(2) > .value { background-color: #3E8091;}
  .paidOut { font-size: 3rem; font-weight: bold;}
  .paidOutPercentaje, .dataOut { font-size: 0.9rem; color: #3E8091; font-weight: bold;}
  .dataOut { position: absolute; right: 10px; bottom: 15px }
  .withoutSubscription { min-height: 250px; width: 100%; display: flex; justify-content: center; align-items: center; background-color: white; border-radius: 16px;}
  
  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .psHomeSubscriptions { margin-left: 0; width: 100%; height: auto; margin-top: 20px;}
    .container { flex-direction: column;}
    .values { display: flex; flex-direction: row; width: 100%; justify-content: space-around;}
  }
</style>