<template>
  <div class="psPatientsList">
    <ps-upper-bar :site="{ site: 'Pacientes' }" :newButton="true" :tooltip="'Nuevo paciente'" @newItem="newPatient"></ps-upper-bar>
    <div class="row">
      <ps-patients-list-search-bar></ps-patients-list-search-bar>
    </div>
    <ps-patients-list-table></ps-patients-list-table>
  </div>
</template>

<script>
import PsUpperBar from '@/components/layout/PsUpperBar.vue'
import PsPatientsListSearchBar from './widgets/PsPatientsListSearchBar.vue'
import PsPatientsListTable from './widgets/PsPatientsListTable.vue'

export default {
  name: 'PsPatientsList',
  components: { PsPatientsListSearchBar, PsPatientsListTable, PsUpperBar },
  methods: {
    newPatient () {
      this.$router.push('/patients/detail/0')
    }
  }
}
</script>

<style scoped>
  .psPatientsList { display: flex; flex-direction: column; width: 100%; height: 84%; margin-bottom: 0;}

  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .psPatientsList { height: 100%;}
  }
</style>