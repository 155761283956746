<template>
  <div class="psCalendarGridDayEvent">
    <el-button circle type="default" icon="el-icon-edit" size="mini" class="editButton" @click="editEvent"></el-button>
    <div class="column">
      <div class="row rowCalendar">
        <div class="calendarTypeDescription">{{calendarEvent.calendarTypeDescription}}</div>
        <div class="patient">{{calendarEvent.patient}}</div>            
      </div>
      <div class="calendarDescription">{{calendarEvent.calendarDescription}} - {{ calendarEvent.groupDescription }}</div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'PsCalendarGridDayEvent',
  props: [ 'calendarEvent', 'index'],
  methods: {
    ...mapMutations('calendar', ['CALENDAR_CALENDAR_ITEM', 'CALENDAR_NEW_EVENT_DIALOG_VISIBLE']),
    editEvent () {
      this.CALENDAR_CALENDAR_ITEM(Object.assign({}, this.calendarEvent))
      this.CALENDAR_NEW_EVENT_DIALOG_VISIBLE(true)
    }
  }
}
</script>

<style scoped>
  .psCalendarGridDayEvent { display: flex; margin-bottom: 12px; }
  .psCalendarGridDayEvent > .column { margin-right: 0;}
  .calendarTypeDescription { font-weight: bold; margin-right: 10px; font-size: 0.9rem; }
  .patient { font-weight: 500; color: #0290af; text-align: left; font-size: 0.9rem;}
  .rowCalendar { margin-bottom: 0;}
  .calendarDescription { font-size: 0.8rem;}
  .editButton { width: 30px; height: 30px; margin-right: 5px;}
</style>