<template>
  <div class="psHome view">
    <ps-page-container :site="{ site: 'Home' }"></ps-page-container>
  </div>
</template>

<script>
import PsPageContainer from '@/components/layout/PsPageContainer.vue'

export default {
  name: 'PsHome',
  components: { PsPageContainer }
}
</script>

<style scoped>

</style>