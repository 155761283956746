<template>
  <div class="psCalendar view">
    <ps-page-container :site="{ site: 'Calendario' }"></ps-page-container>
    <ps-calendar-dialog-new-event v-if="newEventDialogVisible == true"></ps-calendar-dialog-new-event>
  </div>
</template>

<script>
import PsPageContainer from '@/components/layout/PsPageContainer.vue'
import PsCalendarDialogNewEvent from './pages/CalendarGrid/widgets/PsCalendarDialogNewEvent.vue'
import { mapState } from 'vuex'

export default {
  name: 'PsCalendar',
  components: { PsPageContainer, PsCalendarDialogNewEvent},
  computed: {
    ...mapState('calendar', ['newEventDialogVisible']),
  }
}
</script>

<style scoped>

</style>