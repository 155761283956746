<template>
  <div class="psPatientListEventuals">
    <div class="container">
      <el-radio-group v-model="listSelected" v-if="eventuals.length > 0">
        <el-radio-button v-for="(eventual, index) in eventualsModified" :key="index" :label="eventual.eventualDescription"></el-radio-button>
      </el-radio-group>
    </div>
    <div class="row detail">
      <div class="column w-100">
        <div class="label">Detalles</div>
        <el-input type="textarea" :rows="5" :value="itemEventual.detail" @input="changeProperty($event, 'detail')"></el-input>
      </div>
    </div>
    <el-button @click="saveEventual" icon="el-icon-check" type="success" round class="btnSave" :disabled="itemEventual.eventualSelected == '' && itemEventual.detail == ''">{{ itemEventual.id > 0 ? 'Actualizar' : 'Guardar' }}</el-button>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsPatientListEventuals',
  computed: {
    ...mapState('eventuals', ['eventuals']),
    ...mapState('patients', ['patient', 'itemEventual']),
    listSelected: {
      set (value) {
        this.setEventualSelected(value)
      },
      get () {
        return this.itemEventual.eventualSelected || ''
      }
    },
    eventualsModified () {
      let list = this.eventuals
      list.push({eventualId: 0, eventualDescription: 'Sin etiqueta'})
      return list || []
    }
  },
  methods: {
    ...mapMutations('patients', ['PATIENTS_ITEM_EVENTUAL_PROP', 'PATIENTS_DIALOG_EVENTUALS']),
    ...mapActions('patients', ['saveEventualsPatient', 'getPatientHTTP']),
    setEventualSelected ( value ) {
      this.PATIENTS_ITEM_EVENTUAL_PROP({property: 'eventualSelected', value} )
    },
    changeProperty ( value, property ) {
      this.PATIENTS_ITEM_EVENTUAL_PROP({property, value})
    },
    async saveEventual () {
      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Guardando...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });   

        const message = await this.saveEventualsPatient()
        this.$message( { message, type: 'success' } )
        setTimeout( async () => {
          await this.getPatientHTTP( this.$route.params['patientId'])
          this.PATIENTS_DIALOG_EVENTUALS(false)
        }, 1000)
      } catch ( err ) { this.$message( { message: err.response.data.err.details, type: 'error' })}
      finally { loading.close() } 
    }
  }
}
</script>

<style scoped>
  .el-radio-group { display: flex; flex-direction: column;}
  .el-radio-button { width: 100%;}
  .el-radio-button /deep/ .el-radio-button__inner { display: block; width: 100%; margin-bottom: 4px;}
  .btnSave { margin-top: 15px}
  .container { max-height: 180px; overflow-y: scroll;}
  .container::-webkit-scrollbar {
    width: 8px;     
    height: 100%;    
    /* display: none;   */
  }
  .detail { margin-top: 15px;}
  .detail .column { margin: 7px}
  /* Ponemos un color de fondo y redondeamos las esquinas del thumb */
  .container::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 4px;
  }

  /* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
  .container::-webkit-scrollbar-thumb:hover {
      background: #b3b3b3;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }

  /* Cambiamos el fondo cuando esté en active */
  .container::-webkit-scrollbar-thumb:active {
      background-color: #999999;
  }

  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .patientIndicators { flex-flow: wrap;}    
    .patientDiseases, .patientEventuals { flex-direction: column;}
  }
</style>