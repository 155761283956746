<template>
  <div class="psAccounts">
    <ps-upper-bar :site="{ site: 'Configuración - Cuentas' }" :backButton="true"></ps-upper-bar>
    <div class="container">
      <div class="row">
        <el-button round plain icon="el-icon-plus" @click="addNewAccount" :disabled="newAccount">Nueva cuenta</el-button>
      </div>
      <div class="row rowData">
        <div class="column w-25">
          <div class="label">Descripcion</div>
          <el-input 
            :value="account.accountDescription" 
            @input="changeProperty($event, 'accountDescription')" 
            placeholder="Ingrese una descripción"
            :disabled="!newAccount"></el-input>
        </div>
        <div class="column">
          <div class="label">Usuario</div>
          <el-select :value="account.username" @change="changeProperty($event, 'user')" placeholder="Seleccione un usuario" :disabled="!newAccount">
            <el-option v-for="(user, index) in users.filter(u=>u.keepAccount == 1)" :key="index" :label="`${user.name} ${user.surname}`" :value="user"></el-option>
          </el-select>
        </div>
        <div class="column w-20">
          <div class="label">Monto máximo ($)</div>
          <el-input-number
            :value="account.maximum" 
            @input="changeProperty($event, 'maximum')" 
            :min="0"
            :controls="false"
            placeholder="Ingrese un monto máximo para la cuenta"
            :disabled="!newAccount"></el-input-number>
        </div>
        <div class="column">
          <div class="label">Acumula oficialmente</div>
          <el-switch :value="account.accumulatesOficial" @input="changeProperty($event, 'accumulatesOficial')" :disabled="!newAccount"></el-switch>
        </div>
        <div class="column">
          <div class="label">Activa</div>
          <el-switch :value="account.active" @input="changeProperty($event, 'active')" :disabled="!newAccount"></el-switch>
        </div>
      </div>
      <div class="row rowButtons">
        <el-button round plain type="success" :disabled="!saveDisabled" @click="save">Guardar</el-button>
        <el-button round plain type="danger" :disabled="!newAccount" @click="cancel">Cancelar</el-button>
      </div>

      <el-table 
        :data="accounts"
        style="width: 800px;" 
        :highlight-current-row="true" 
        size="mini" 
        height="305" 
        empty-text="No hay cuentas ingresadas"
        @cell-dblclick="selectRow">
        <el-table-column prop="accountId" label="Código" width="80"  v-if="!isMobile"></el-table-column>
        <el-table-column prop="accountDescription" label="Descripción" :width="isMobile == true ? '' : 200" ></el-table-column>
        <el-table-column prop="username" label="Usuario" :width="isMobile == true ? '' : 250" ></el-table-column>
        <el-table-column prop="maximum" label="Maximo ($)" v-if="!isMobile" width="120" ></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import PsUpperBar from '@/components/layout/PsUpperBar.vue'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsAccounts',
  components: { PsUpperBar },
  data () {
    return {
      activeNames: [],
      newAccount: false
    }
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapState('accounts', ['accounts', 'account', 'errors']),
    ...mapState('users', ['users']),
    saveDisabled () {
      return this.newAccount && this.account.accountDescription != ''
    }
  },
  methods: {
    ...mapMutations('accounts', ['ACCOUNTS_ACCOUNT', 'ACCOUNTS_ACCOUNT_PROP']),
    ...mapActions('accounts', ['saveAccount', 'getAccountsHTTP']),
    ...mapActions('users', ['getUsersHTTP']),
    selectRow ( row ) {
      if( row ) {
        this.newAccount = true
        this.ACCOUNTS_ACCOUNT(JSON.parse(JSON.stringify(row)))
      }
    },
    changeProperty ( value, property ) {
      if( property == 'user' ) {
        this.ACCOUNTS_ACCOUNT_PROP({property: 'userId', value: value.userId })
        this.ACCOUNTS_ACCOUNT_PROP({property: 'username', value: `${value.name} ${value.surname}` })
      } else {
        this.ACCOUNTS_ACCOUNT_PROP({property, value})
      }
    },
    addNewAccount () {
      this.newAccount = true
      this.ACCOUNTS_ACCOUNT({})
    },
    cancel () {
      this.newAccount = false
      this.ACCOUNTS_ACCOUNT({})
    },
    async save () {
      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Guardando...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        }); 

        const message = await this.saveAccount()
        if(this.errors.length > 0 ) {
          this.showValidationAlert()
        } else {
          this.cancel()
          this.$message( { message, type: 'success' } )
          this.getAccountsHTTP()
        }
    } catch ( err ) { this.$message( { message: err.response.data.err.details, type: 'error' })}
      finally { loading.close(); }
    },
    showValidationAlert () {      
      let str = '<p>';
      if(this.errors.length > 0) {
        this.errors.forEach(err => str += `${err}<br>`)
        str = str + '</p>'
        this.$alert(str, '', {confirmButtonText: 'Entendido', type: 'warning', dangerouslyUseHTMLString: true})
      }
    },
  },
  async created () {
    this.ACCOUNTS_ACCOUNT({})
    await this.getAccountsHTTP()
    await this.getUsersHTTP()
  }
}
</script>

<style scoped>
  .psAccounts { min-height: 90%;}
  .container { background-color: white; border-radius: 20px; padding: 32px; min-height: 90%; height: 100%; overflow-y: scroll; }
  .container::-webkit-scrollbar { display: none}
  .rowData { margin: 40px 0 20px}

    /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .container { padding: 32px 16px}
    .rowData { flex-direction: column;}
    .rowData .column { margin-right: 0; width: 100%; margin-bottom: 8px; }
    .rowButtons { justify-content: space-evenly;}
  }
</style>