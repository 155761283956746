<template>
  <div class="psNewButton">
    <el-button circle style="border: 2px solid black; margin: 4px 0; color: black; font-weight: bold;" size="mini" @click="newItem">
      <i class="el-icon-plus" style="font-weight: 900"></i>
    </el-button>
    <span class="tooltip">{{tooltip}}</span>    
  </div>
</template>

<script>
export default {
  name: 'psNewButton',
  props: [ 'tooltip'],
  methods: {
    newItem () {
      
      this.$emit('newItem')
    }
  }
}
</script>

<style scoped>
  .psNewButton { position: relative; margin-right: 10px;}
  .tooltip { 
    visibility: hidden;
    width: 120px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    text-align: center;
    padding: 4px 0;
    border-radius: 12px;
    position: absolute;
    z-index: 10;
    font-size: 0.8rem;
    left: 5px;
    top: -20px
  }

  .psNewButton:hover .tooltip {
    visibility: visible;
  }
</style>