<template>
  <div class="psConfigurationRates">
    <ps-upper-bar :site="{ site: 'Configuración - Grupo de Tarifas' }" :backButton="true"></ps-upper-bar>
    <div class="container">
      <div class="row">
        <el-button round plain icon="el-icon-plus" @click="addNewRatesGroup" :disabled="newRatesGroup">Nuevo grupo de tarifas</el-button>
      </div>
      <div class="row rowData">
        <div class="column">
          <div class="row" style="width: 720px; justify-content: space-between;">
            <div class="row">
              <div class="column">
                <div class="label">Descripcion</div>
                <el-input 
                  :value="rateGroup.description" 
                  @input="changeProperty($event, 'description')" 
                  placeholder="Ingrese una descripción"
                  :disabled="!newRatesGroup"></el-input>
              </div>
              <div class="column">
                <div class="label">&nbsp;</div>
                <el-button circle icon="el-icon-delete" :disabled="!newRatesGroup" type="danger" v-if="rateGroup.id > 0" @click="deleteRateGroup"></el-button>
              </div>
            </div>
            <div class="column">
              <div class="label">&nbsp;</div>
              <el-button rounded icon="el-icon-upload2" :disabled="!ratesSelected" type="warning" v-if="rateGroup.id > 0" @click="sendRatesToPrincipal">
                Pasar tarifas seleccionadas a la principal
              </el-button>              
            </div>
          </div>

          <el-table
            :data="rateGroup.ratesByGroup"
            style="width: 800px"
            size="mini"
            height="150"
            empty-text="No hay tarifas en este grupo"
            @selection-change="handleSelection"
            v-if="rateGroup.id > 0"
          >
            <el-table-column type="selection" width="100"></el-table-column>
            <el-table-column prop="rateId" label="ID Tarifa" width="100"></el-table-column>
            <el-table-column prop="rateDescription" label="Tarifa" width="280"></el-table-column>
            <el-table-column prop="patientsByRate" label="# Pacientes" width="100"></el-table-column>
            <el-table-column prop="rateValue" label="$" width="100"></el-table-column>
            <el-table-column prop="ratePrincipal" label="Principal" v-if="!isMobile" width="120" >
              <template slot-scope="scope" v-if="scope.row.ratePrincipal == true">
                <span>1°</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="row rowButtons">
        <el-button round plain type="success" :disabled="!saveDisabled" @click="save">Guardar</el-button>
        <el-button round plain type="danger" :disabled="!newRatesGroup" @click="cancelRateGroup">Cancelar</el-button>
      </div>

      <el-table 
        :data="ratesGroup"
        style="width: 800px;" 
        :highlight-current-row="true" 
        size="mini" 
        height="170" 
        empty-text="No hay tarifas ingresadas"
        @cell-dblclick="selectRow">
        <el-table-column prop="id" label="Código" width="80" class-name="rateId" id="rateId"></el-table-column>
        <el-table-column prop="description" label="Descripción" :width="isMobile == true ? '' : 300" ></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import PsUpperBar from '@/components/layout/PsUpperBar.vue'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsConfigurationRatesGroup',
  components: { PsUpperBar },
  data () {
    return {
      newRatesGroup: false,
      multipleSelection: []
    }
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapState('ratesGroup', ['ratesGroup', 'rateGroup', 'errors']),
    saveDisabled () {
      return this.newRatesGroup && this.rateGroup.description != '';
    },
    ratesSelected() {
      return this.multipleSelection.length > 0 ? true : false;
    }
  },
  methods: {
    ...mapMutations('ratesGroup', ['RATES_GROUP_RATE_GROUP', 'RATES_GROUP_RATE_GROUP_PROP']),
    ...mapActions('ratesGroup', ['getRatesGroupHTTP', 'saveRateGroup', 'deleteRateGroupHTTP', 'sendRatesToPrincipalHTTP']),
    selectRow ( row ) {
      if( row ) {
        this.newRatesGroup = true;
        this.RATES_GROUP_RATE_GROUP(JSON.parse(JSON.stringify(row)))
      }
    },
    changeProperty ( value, property ) {      
      this.RATES_GROUP_RATE_GROUP_PROP({property, value});
    },
    addNewRatesGroup () {
      this.newRatesGroup = true
      this.RATES_GROUP_RATE_GROUP({})
    },
    cancelRateGroup () {
      this.newRatesGroup = false;
      this.RATES_GROUP_RATE_GROUP({});
    },
    async save () {
      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Guardando...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        }); 

        const message = await this.saveRateGroup()
        if(this.errors.length > 0 ) {
          this.showValidationAlert()
        } else {
          this.$message( { message, type: 'success' } )
          this.getRatesGroupHTTP()
          this.cancelRateGroup()
        }
    } catch ( err ) { console.log({err}); this.$message( { message: err.response.data.err.details, type: 'error' })}
      finally { loading.close(); }
    },
    showValidationAlert () {      
      let str = '<p>';
      if(this.errors.length > 0) {
        this.errors.forEach(err => str += `${err}<br>`)
        str = str + '</p>'
        this.$alert(str, '', {confirmButtonText: 'Entendido', type: 'warning', dangerouslyUseHTMLString: true})
      }
    },
    async deleteRateGroup() {
      this.$confirm('¿Quiere eliminar este grupo de tarifas?', 'Confirmación', {
        type: 'warning',
        confirmButtonText: 'Sí',
        cancelButtonText: 'No'
      })
      .then( async () => {
        let loading;

        try {
        loading = this.$loading({
          lock: true,
          text: 'Eliminando...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        }); 

        const message = await this.deleteRateGroupHTTP()
        if(this.errors.length > 0 ) {
          this.showValidationAlert()
        } else {
          this.$message( { message, type: 'success' } )
          this.getRatesGroupHTTP()
          this.cancelRateGroup()
        }
      } catch ( err ) { this.$message( { message: err.response.data.err.details, type: 'error' })}
        finally { loading.close(); }
      })
      .catch( () => null)
    },
    handleSelection( value ) {
      this.multipleSelection = value;
    },
    async sendRatesToPrincipal() {
      if( !this.rateGroup.ratesByGroup.find( rate => rate.ratePrincipal == true) ) {
        return this.$message.error('Para poder cambiar las tarifas debe existir alguna principal.');
      }


      this.$confirm('¿Quiere pasar los pacientes de las tarifas seleccionadas a la tarifa principal?', 'Confirmación', {
        type: 'warning',
        confirmButtonText: 'Sí',
        cancelButtonText: 'No'
      })
      .then( async () => {
        let loading;

        try {
        loading = this.$loading({
          lock: true,
          text: 'Pasando pacientes a la tarifa principal ...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        }); 

        const message = await this.sendRatesToPrincipalHTTP( this.multipleSelection )
        if(this.errors.length > 0 ) {
          this.showValidationAlert()
        } else {
          this.$message( { message, type: 'success' } )
          this.getRatesGroupHTTP();
          this.cancelRateGroup();
        }
      } catch ( err ) { this.$message( { message: err.response.data.err.details, type: 'error' })}
        finally { loading.close(); }
      })
      .catch( () => null)
    }
  },
  created () {
    this.RATES_GROUP_RATE_GROUP({});
    this.getRatesGroupHTTP();
  }
}
</script>

<style scoped>
  .psConfigurationRates { min-height: 90%;}
  .container { background-color: white; border-radius: 20px; padding: 32px; min-height: 90%; overflow-y: scroll; height: 100%; }
  .container::-webkit-scrollbar { display: none}
  .rowData { margin: 40px 0 20px}
  .rowHistory { margin-bottom: 20px;}
  .el-collapse { width: 500px }
  .itemHistoryRate { text-align: left;}
  .stateRate { color: red; margin-left: 15px}
  .active { color: green}


  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .container { padding: 32px 16px}
    .rowData { flex-direction: column;}
    .rowData .column { margin-right: 0 }
    .rowData .column .el-input-number, .rowData .w-25 { width: 100%; margin-bottom: 8px; }
    .rowButtons { justify-content: space-evenly;}
  }
</style>