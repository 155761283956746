<template>
  <div class="psSharedWeeklyWeight">
    <div class="row week">
      <div class="column" v-for="(day, index) in data" :key="index" style="justify-content: flex-start">
        <div class="day" :style="`font-size: '${fontTitle};'`">{{day.weekDay}}</div>
        <div class="date" :style="`font-size: '${fontTitle};'`">{{day.date}}</div>
        
        <!--eslint-disable-next-line vue/no-parsing-error-->
        <div class="weight">{{ Number(day.weight) < 0 ? '0 CA' : day.weight}}</div>
      </div>
    </div>



  </div>
</template>

<script>
export default {
  name: 'PsSharedWeeklyWeight',
  props: {
    data: {
      type: Array
    },
    fontTitle: {
      type: String,
      default: '20px'
    }
  }
}
</script>

<style scoped>
  .psSharedWeeklyWeight { display: flex; flex-direction: column; overflow-x: auto; height: 100%; align-items: center; justify-content: center  }
  .day { font-weight: 500; color: #EFAF00; font-size: 15px;}
  .date { font-size: 10px;}
  .weight { font-size: 15px; font-weight: 500; line-height: 28px;}
  .column { height: 42px; min-width: 50px; justify-content: space-between;}
  .week .column { align-items: center; margin-right: 0; width: 55px; height: 100%;}
  .week { margin-bottom: 0; display: flex; align-items: center;}
  
  .psSharedWeeklyWeight::-webkit-scrollbar {
    width: 60px;     
    height: 8px;  
  }

  .psSharedWeeklyWeight::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
  }

  /* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
  .psSharedWeeklyWeight::-webkit-scrollbar-thumb:hover {
      background: #b3b3b3;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }

  /* Cambiamos el fondo cuando esté en active */
  .psSharedWeeklyWeight::-webkit-scrollbar-thumb:active {
      background-color: #999999;
  }

  

  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .psSharedWeeklyWeight { width: 100%; height: 100px; }
    .row.week { flex-wrap: wrap; row-gap: 18px;}
  }
</style>