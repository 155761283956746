import api from './../services/api'
import axios from 'axios'
import { addProperyIfNotExists } from './../services/utils'
// import { saveAs } from 'file-saver'

const state = {
  editionMode: false,
  patients: [],
  patient: {},
  newPatient: {
    accountDescription: '',
    accountId: '',
    active: true,
    actualWeight: 0,
    address: '',
    createdBy: 0,
    createdAt: '',
    cuit: '',
    dateOfBirth: '',
    dailyVariation: 0,
    debtor: false,
    desiredWeight: 0,
    discountRate: 0,
    diseases: [],
    dni: '',
    email: '',
    eventuals: [],
    generalInformation: [],
    groupDescription: '',
    groupId: 0,
    height: 0,
    lastMonth: false,
    lastWeights: {},
    locationDescription: '',
    medications: [],
    name: '',
    nickname: '',
    occupation: '',
    osId: 0,
    pathImagePatient: '',
    patientId: 0,
    paymentsHistory: [],
    phone: '',
    postalCode: '',
    questionnaire: 'NO',
    rateDescription: '',
    rateId: 0,
    rateValue: 0,
    startDate: '',
    startFile: '',
    startSubscription: new Date(),
    startWeightTable: 0,
    startWeight: 0,
    subscriptionsHistory: [],
    surname: '',
    tableInformation: '',
    takesInvoice: false,
    targetWeight: '0',
    total: 0,
    updatedAt: '',
    updatedBy: 0,
    usualWeight: 0,
    wayToPayDescription: '',
    wayToPayId: 0,
    weeklyVariation: 0,
    weeklyWeight: []
  },
  filter: '',
  errors: [],
  dialogDiseases: false,
  loading: false,
  diseasesListSelected: [],
  dialogEventuals: false,
  itemEventual: {},
  newItemEventual: {
    id: 0,
    eventualSelected: '',
    detail: ''
  },
  dialogMedication: false,
  itemMedication: {},
  newItemMedication: {
    id: 0,
    description: ''
  },
  dialogSubscription: false,
  subscription: {},
  newSubscription: {
    discountRate: 0,
    discountAmount: 0,
    id: 0,
    month: 0,
    patientId: 0,
    rateId: 0,
    rateValue: 0,
    statusPaidOut: 1,
    surcharge: 0,
    surchargeAmount: 0,
    total: 0,
    year: 0,
    assignment: 0,
    observations: ''
  },
  dialogPayment: false,
  payment: {},
  newPayment: {
    patientId: 0,
    paymentId: 0,
    paymentDate: new Date(),
    paymentAmount: 0,
    wayToPayId: 0,
    wayToPayDescription: '',
    accountId: 0,
    accountDescription: ''
  },
  paymentsAssigned: [],
  dialogGeneralInformation: false,
  itemGeneralInformation: {},
  newItemGeneralInformation: {
    id: 0,
    description: ''
  },
  drawerAllWeights: false,
  allWeights: []
}

const mutations = {
  PATIENTS_EDITION_MODE(state, editionMode) { state.editionMode = editionMode },
  PATIENTS_PATIENTS(state, patients) { state.patients.push(...patients) },
  PATIENTS_FILTER(state, filter) { state.filter = filter },
  PATIENTS_EMPTY_PATIENTS(state) { state.patients = [] },
  PATIENTS_PATIENT(state, patient) { state.patient = addProperyIfNotExists(patient, state.newPatient) },
  PATIENTS_PATIENT_PROP(state, payload) { state.patient[payload.property] = payload.value },
  PATIENTS_EMPTY_ERRORS(state) { state.errors = [] },
  PATIENTS_ADD_ERROR(state, error) { state.errors.push(error) },
  PATIENTS_DIALOG_DISEASES(state, dialogoDiseases) { state.dialogDiseases = dialogoDiseases },
  PATIENTS_LOADING(state, loading) { state.loading = loading },
  PATIENTS_DISEASES_LIST_SELECTED(state, diseasesListSelected) { state.diseasesListSelected = diseasesListSelected },
  PATIENTS_DIALOG_EVENTUALS(state, dialogEventuals) { state.dialogEventuals = dialogEventuals },
  PATIENTS_ITEM_EVENTUAL(state, itemEventual) { state.itemEventual = addProperyIfNotExists(itemEventual, JSON.parse(JSON.stringify(state.newItemEventual))) },
  PATIENTS_ITEM_EVENTUAL_PROP(state, payload) { state.itemEventual[payload.property] = payload.value },
  PATIENTS_DIALOG_MEDICATION(state, dialogMedication) { state.dialogMedication = dialogMedication },
  PATIENTS_ITEM_MEDICATION(state, itemMedication) { state.itemMedication = addProperyIfNotExists(itemMedication, JSON.parse(JSON.stringify(state.newItemMedication))) },
  PATIENTS_ITEM_MEDICATION_PROP(state, payload) { state.itemMedication[payload.property] = payload.value },
  PATIENTS_DIALOG_SUBSCRIPTION(state, dialogSubscription) { state.dialogSubscription = dialogSubscription },
  PATIENTS_SUBSCRIPTION(state, subscription) { state.subscription = addProperyIfNotExists(subscription, JSON.parse(JSON.stringify(state.newSubscription))) },
  PATIENTS_SUBSCRIPTION_PROP(state, payload) { state.subscription[payload.property] = payload.value },
  PATIENTS_DIALOG_PAYMENT(state, dialogPayment) { state.dialogPayment = dialogPayment },
  PATIENTS_PAYMENT(state, payment) { state.payment = addProperyIfNotExists(payment, JSON.parse(JSON.stringify(state.newPayment))) },
  PATIENTS_PAYMENT_PROP(state, payload) { state.payment[payload.property] = payload.value },
  PATIENTS_PAYMENTS_ASSIGNED(state, paymentsAssigned) { state.paymentsAssigned = paymentsAssigned },
  PATIENTS_DIALOG_GENERAL_INFORMATION(state, dialogGeneralInformation) { state.dialogGeneralInformation = dialogGeneralInformation },
  PATIENTS_ITEM_GENERAL_INFORMATION(state, itemGeneralInformation) { state.itemGeneralInformation = addProperyIfNotExists(itemGeneralInformation, JSON.parse(JSON.stringify(state.newItemGeneralInformation))) },
  PATIENTS_ITEM_GENERAL_INFORMATION_PROP(state, payload) { state.itemGeneralInformation[payload.property] = payload.value },
  PATIENTS_DRAWER_ALL_WEIGHTS(state, value) { state.drawerAllWeights = value},
  PATIENTS_ALL_WEIGHTS(state, weights) { state.allWeights = weights },
}

const actions = {
  async getPatientsHTTP({ state, commit }, payload) {
    let url = `/patients/${payload.page}`

    if (state.filter && state.filter != '') {
      url = `${url}/${state.filter}`
    }

    let result = await api.get(url)
    commit('PATIENTS_PATIENTS', result.data.data)
    if (result.data.data.length == 0) {
      return true
    } else {
      return false
    }
  },
  async getPatientHTTP({ commit }, patientId) {
    let result = await api.get(`/patients/one/${patientId}`)
    let patient = result.data.data

    commit('PATIENTS_PATIENT', patient)
  },
  async savePatient({ state, commit, dispatch }) {
    const patient = Object.assign({}, state.patient)
    commit('PATIENTS_EMPTY_ERRORS')

    if (!patient.name || patient.name == '') commit('PATIENTS_ADD_ERROR', 'Debe ingresar el nombre del paciente')
    if (!patient.surname || patient.surname == '') commit('PATIENTS_ADD_ERROR', 'Debe ingresar el apellido del paciente')
    if (!patient.rateId || patient.rateId == 0) commit('PATIENTS_ADD_ERROR', 'Debe seleccionar una tarifa')
    if (patient.dateOfBirth && patient.dateOfBirth != '' && patient.dateOfBirth != '00-00-0000') {
      const [day, month, year] = patient.dateOfBirth.split('/');

      if (!day || day < 1 || day > 31 || !month || month < 1 || month > 12 || !year || year < 1930 || new Date(year, month - 1, day).getTime() > new Date(
        new Date().getFullYear() - 16,
        new Date().getMonth(),
        new Date().getDate()
      ).getTime()) {
        commit('PATIENTS_ADD_ERROR', 'La fecha de nacimiento no es válida')
      }
    }

    if (patient.startDate && patient.startDate != '' && patient.startDate != '00-00-0000') {
      const [day, month, year] = patient.startDate.split('/');

      if (!day || day < 1 || day > 31 || !month || month < 1 || month > 12 || !year || year < 1930) {
        commit('PATIENTS_ADD_ERROR', 'La fecha de inicio no es válida')
      }
    }

    if (state.errors.length == 0) {
      if (state.patient.patientId == 0) {
        return await dispatch('createPatientHTTP')
      } else {
        return await dispatch('updatePatientHTTP')
      }
    }

  },
  async createPatientHTTP({ state, rootState }) {
    const patient = Object.assign({}, state.patient)
    patient.createdBy = rootState.user.userId
    patient.updatedBy = rootState.user.userId

    await api.post('/patients', patient)
    return 'Paciente creado!'
  },
  async updatePatientHTTP({ state, rootState }) {
    const patient = Object.assign({}, state.patient)
    patient.updatedBy = rootState.user.userId
    await api.put('/patients', patient)
    return 'Paciente actalizado!'
  },
  //eslint-disable-next-line
  async avatarUploadHTTP({ commit }, file) {
    var formData = new FormData()
    formData.append('file', file.file)
    formData.append('upload_preset', 'vkvg1rjl')
    axios({
      method: 'POST',
      url: `https://api.cloudinary.com/v1_1/planadev/upload`,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: formData
    })
      .then(data => {

        commit('PATIENTS_PATIENT_PROP', { property: 'pathImagePatient', value: data.data.secure_url })
      })
      .catch(err => err)
  },
  //eslint-disable-next-line
  async initialFileUploadHTTP({ state, commit }, file) {
    var formData = new FormData()
    formData.append('file', file.file)
    let result = await api.put(`/uploads/startFile/${state.patient.patientId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    commit('PATIENTS_PATIENT_PROP', { property: 'startFile', value: `${file.file.name}` })
    return result.data.data
  },
  async saveDiseasesPatient({ state, rootState }) {
    let diseases = rootState.diseases.diseases.filter(item => state.diseasesListSelected.indexOf(item.diseaseDescription) >= 0)
    let data = {
      diseases: diseases,
      patientId: state.patient.patientId,
      createdBy: rootState.user.userId,
      updatedBy: rootState.user.userId
    }
    await api.post('/patients/diseases', data)
    return 'Enfermedades de base guardadas'
  },
  async saveEventualsPatient({ state, dispatch, rootState }) {
    let eventual = rootState.eventuals.eventuals.filter(item => state.itemEventual.eventualSelected.indexOf(item.eventualDescription) >= 0)

    if (state.itemEventual.id == 0) {
      return await dispatch('createEventualsPatientHTTP', eventual[0])
    } else {
      return await dispatch('updateEventualsPatientHTTP', eventual[0])
    }
  },
  async createEventualsPatientHTTP({ state, rootState }, eventual) {
    let data = {
      detail: state.itemEventual.detail,
      eventual: eventual ? eventual : {},
      patientId: state.patient.patientId,
      createdBy: rootState.user.userId,
      updatedBy: rootState.user.userId
    }
    await api.post('/patients/eventuals', data)
    return 'Información eventual creada'
  },
  async updateEventualsPatientHTTP({ state, rootState }, eventual) {

    let data = {
      id: state.itemEventual.id,
      detail: state.itemEventual.detail,
      eventual: eventual ? eventual : {},
      patientId: state.patient.patientId,
      updatedBy: rootState.user.userId
    }
    await api.put('/patients/eventuals', data)
    return 'Información eventual actualizada'
  },
  async saveMedicationPatient({ state, rootState, dispatch }) {
    let data = Object.assign({}, state.itemMedication)
    data.patientId = state.patient.patientId
    data.createdBy = rootState.user.userId
    data.updatedBy = rootState.user.userId
    if (state.itemMedication.id == 0) {
      return await dispatch('createMedicationsPatientHTTP', data)
    } else {
      return await dispatch('updateMedicationsPatientHTTP', data)
    }
  },
  //eslint-disable-next-line
  async createMedicationsPatientHTTP({ }, data) {
    await api.post('/patients/medications', data)
    return 'Medicación creada'
  },
  //eslint-disable-next-line
  async updateMedicationsPatientHTTP({ }, data) {
    await api.put('/patients/medications', data)
    return 'Medicación actualizada'
  },
  async deleteMedicationPatientHTTP({ state }) {
    await api.delete(`/patients/medications/${state.itemMedication.id}`)
    return 'Medicación eliminada'
  },
  async getPaymentHistoryHTTP({ commit }, patientId) {
    let result = await api.get(`/patients/one/${patientId}`)
    commit('PATIENTS_PATIENT', result.data.data)
  },
  async saveSubscription({ state, dispatch, rootState }) {
    let userId = rootState.user.userId
    let subscription = { ...state.subscription }
    subscription.userId = userId

    if (state.subscription.id == 0) {
      return await dispatch('createPatientSubscriptionHTTP', subscription)
    } else {
      return await dispatch('updatePatientSubscriptionHTTP', subscription)
    }
  },
  //eslint-disable-next-line
  async createPatientSubscriptionHTTP({ }, subscription) {
    await api.post('/patients/subscription', subscription)
    return
  },
  //eslint-disable-next-line
  async updatePatientSubscriptionHTTP({ }, subscription) {
    await api.put('/patients/subscription', subscription)
    return
  },
  async savePayment({ state, dispatch, rootState }) {
    let userId = rootState.user.userId
    console.log(state.payment)
    let payment = { ...state.payment }
    console.log(payment)
    payment.userId = userId
    payment.patientId = state.patient.patientId

    if (state.payment.paymentId == 0) {
      return await dispatch('createPaymentPatientHTTP', payment)
    } else {
      return await dispatch('updatePaymentPatientHTTP', payment)
    }
  },
  //eslint-disable-next-line
  async createPaymentPatientHTTP({ }, payment) {
    await api.post('/patients/payment', payment)

  },
  //eslint-disable-next-line
  async updatePaymentPatientHTTP({ }, payment) {
    await api.put('/patients/payment', payment)
  },
  async getPaymentsAssignedHTTP({ commit }, subscriptionId) {
    const result = await api.get(`/patients/paymentsAssigned/${subscriptionId}`)
    commit('PATIENTS_PAYMENTS_ASSIGNED', [])
    commit('PATIENTS_PAYMENTS_ASSIGNED', result.data.data)
  },
  async getChartWeightsHTTP({ state }) {

    let result = await api.post(`/patients/chartWeights/${state.patient.patientId}`)
    if (result) {
      window.open(`${process.env.VUE_APP_URLAPI}/pdfs/chartWeights/${state.patient.patientId}.pdf`, '_blank')
    }
  },
  async saveGeneralInformationPatient({ state, rootState, dispatch }) {
    let data = Object.assign({}, state.itemGeneralInformation)
    data.patientId = state.patient.patientId
    data.createdBy = rootState.user.userId
    data.updatedBy = rootState.user.userId
    if (state.itemGeneralInformation.id == 0) {
      return await dispatch('createGeneralInformationPatientHTTP', data)
    } else {
      return await dispatch('updateGeneralInformationPatientHTTP', data)
    }
  },
  //eslint-disable-next-line
  async createGeneralInformationPatientHTTP({ }, data) {
    await api.post('/patients/generalInformation', data)
    return 'Información general creada'
  },
  //eslint-disable-next-line
  async updateGeneralInformationPatientHTTP({ }, data) {
    await api.put('/patients/generalInformation', data)
    return 'Información general actualizada'
  },
  async deleteGeneralInformationatientHTTP({ state }) {
    await api.delete(`/patients/generalInformation/${state.itemGeneralInformation.id}`)
    return 'Información general eliminada'
  },
  async getAllWeightsPatientHTTP({ state, commit }, payload) {
    let url = `/patients/weights/${state.patient.patientId}/${payload.page}`

    let result = await api.get(url)
    commit('PATIENTS_ALL_WEIGHTS', result.data.data)
    if (result.data.data.length == 0) {
      return true
    } else {
      return false
    }
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}