<template>
  <div class="psCalendarUpperBar column">
    <div class="row rowMenu">
      <ps-back-button v-show="isMobile == true"></ps-back-button>
      <ps-open-menu-button></ps-open-menu-button>
    </div>
    <div class="row">
      <ps-back-button v-if="isMobile == false"></ps-back-button>
      <div class="containerSite column">
        <div class="row" style="margin-bottom: 0">          
          <div class="site">{{ site.site }}</div>
          <!-- <i :class="icon" class="icon" @click="clickIcon" v-if="isAdmin"></i> -->
          <el-button circle style="border: 2px solid black; margin: 4px 0; color: black; font-weight: bold;" size="mini" @click="clickIcon" v-if="isAdmin">
            <i class="el-icon-plus" style="font-weight: 900"></i>
          </el-button>
        </div>
      </div>
      <ps-avatar class="avatar"></ps-avatar>
    </div>
  </div>
</template>

<script>
import PsAvatar from '@/components/layout/PsAvatar.vue'
import PsBackButton from '@/components/PsBackButton.vue'
import PsOpenMenuButton from '@/components/PsOpenMenuButton.vue'
// import BtnFakePatient from './BtnFakePatient.vue'
import { mapState, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'PsCalendarUpperBar',
  props: {
    site: Object,
  },
  components: { PsAvatar, PsBackButton, PsOpenMenuButton },
  computed: {
    ...mapState('routes', ['routeSite']),
    ...mapState('calendar', ['newEventDialogVisible']),
    ...mapState(['user', 'isMobile']),
    ...mapGetters(['isAdmin'])
  },
  methods: {
    ...mapMutations('calendar', ['CALENDAR_NEW_EVENT_DIALOG_VISIBLE', 'CALENDAR_CALENDAR_ITEM']),
    ...mapMutations('calendarTypes', ['CALENDAR_TYPES_CALENDAR_TYPE']),
    clickIcon () {
      this.CALENDAR_CALENDAR_ITEM({})
      this.CALENDAR_TYPES_CALENDAR_TYPE({})
      this.CALENDAR_NEW_EVENT_DIALOG_VISIBLE(true)
    },
  },
}
</script>

<style scoped>
  .psCalendarUpperBar { height: 60px;  display: flex; margin-top: 50px; }
  .psCalendarUpperBar > .row { margin-bottom: 0;}
  .containerSite { display: flex; flex-direction: column; align-items: flex-start;}
  .site { font-weight: bold; font-size: 28px; line-height: 38px; margin-right: 20px; }

  .icon { line-height: 35px; margin-left: 20px; cursor: pointer; transition: 0.2s ease; border: 1px solid black; width: 35px; height: 35px; border-radius: 18px;} 
  .icon:hover { font-size: 1.1rem; font-weight: bold;}
  .rowMenu { justify-content: space-between; width: 100%; line-height: 40px; }


    /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .psCalendarUpperBar {margin-top: 16px; height: 80px; margin-right: 0; margin-bottom: 0px;}
    .psCalendarUpperBar > .row { padding-left: 0px;}
    .avatar { display: none; }
    .menu { display: block; align-self: flex-end;}
  }

</style>