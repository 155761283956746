<template>
  <div class="psPatientSubscriptionHistoryItem">
    <div style="display: flex; line-height: 20px">
      <div class="id"> {{ String(item.id).substring(4) }} </div>
      <div class="year"> {{ item.year }} </div>
        <div class="month"> {{ months[item.month - 1] }} </div>
        <div class="statusPaidOut" v-if="isMobile == false">
          <span v-if="item.statusPaidOut == 3" class="green">Pagado</span>
          <span v-else-if="item.statusPaidOut == 2" class="yellow">Pago parcial</span>
          <span v-else-if="item.statusPaidOut == 0" class="bordo">Cancelado</span>
          <span v-else class="red">Pendiente de pago</span>
        </div>
        <div class="statusPaidOutMobile">
          <span v-if="item.statusPaidOut == 3" class="green"></span>
          <span v-else-if="item.statusPaidOut == 2" class="yellow"></span>
          <span v-else-if="item.statusPaidOut == 0" class="bordo"></span>
          <span v-else class="red"></span>

        </div>
        <div class="amount" v-if="isMobile == false"> {{ item.total }} </div>
    </div>
    <div class="edit">
      <el-button size="mini" icon="el-icon-edit" @click="edit"></el-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex' 

export default {
  name: 'PsPatientSubscriptionHistoryItem',
  props: [ 'item' ],
  computed: {
    ...mapState(['months']),
    ...mapState(['isMobile'])
  },
  methods: {
    ...mapMutations('patients', ['PATIENTS_DIALOG_SUBSCRIPTION', 'PATIENTS_SUBSCRIPTION', 'PATIENTS_PAYMENTS_ASSIGNED']),
    ...mapActions('patients', ['getPaymentsAssignedHTTP']),
    async edit () {
      this.PATIENTS_SUBSCRIPTION({...this.item})
      this.PATIENTS_DIALOG_SUBSCRIPTION(true)
      this.PATIENTS_PAYMENTS_ASSIGNED([])
      await this.getPaymentsAssignedHTTP(this.item.id)
    }
  }
}
</script>

<style scoped>
  .psPatientSubscriptionHistoryItem { display: flex; color: #828282; text-align: left; border-bottom: 1px solid #ddd; padding: 5px 0; justify-content: flex-start; align-items: center;}
  .year { width: 70px}
  .month { width: 100px}
  .id { width: 80px}
  .statusPaidOut { width: 140px; line-height: 1rem;}
  .amount { width: 100px}
  .green { color: rgb(2, 218, 2)}
  .red { color: red}
  .yellow { color: #c9aa04}
  .bordo { color: #870101;}

    /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .psPatientSubscriptionHistoryItem { justify-content: space-between;}
    .year { width: 50px} 
    .month { width: 80px} 
    .statusPaidOutMobile { width: 40px; } 
    .edit { align-self: flex-end;}
    
    .statusPaidOutMobile > span { width: 16px; height: 16px; display: block; border-radius: 8px;}
    .green { background-color: rgb(2, 218, 2)}
    .red { background-color: red}
    .yellow { background-color: #c9aa04}
    .bordo { background-color: #870101;}
  }
</style>