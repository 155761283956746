<template>
  <div class="psPatientPaymentHistoryItem">
    <div class="id" v-if="isMobile == false"> {{ item.paymentId}} </div>
    <div class="date"> {{ item.paymentDate | formatDateDDMMYYYY}} </div>
    <div class="amount">$ {{ item.paymentAmount }} </div>
    <div class="wayToPay"  v-if="isMobile == false">{{ item.wayToPayDescription }} </div>
    <div class="account">{{ item.accountDescription }} </div>
    <div class="edit" v-if="index == 0">
      <el-button size="mini" icon="el-icon-edit" @click="edit"></el-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex' 

export default {
  name: 'PsPatientPaymentHistoryItem',
  props: [ 'item', 'index' ],
  computed: {
    ...mapState(['months']),
    ...mapState('patients', ['patient']),
    ...mapState(['isMobile']),
  },
  methods: {
    ...mapMutations('patients', ['PATIENTS_PAYMENT', 'PATIENTS_DIALOG_PAYMENT']),
    
    async edit () {
      
      let payment = Object.assign({},this.item)
      if( payment.wayToPayId == 0) {
        payment.wayToPayId = this.patient.wayToPayId
        payment.wayToPayDescription = this.patient.wayToPayDescription
      }
      
      if( payment.accountId == 0) {
        payment.accountId = this.patient.accountId
        payment.accountDescription = this.patient.accountDescription
      }


      this.PATIENTS_PAYMENT(payment)
      this.PATIENTS_DIALOG_PAYMENT(true)
      
    }
  }
}
</script>

<style scoped>
  .psPatientPaymentHistoryItem { display: flex; color: #828282; text-align: left; border-bottom: 1px solid #ddd; padding: 5px 0; align-items: center;}
  .date, .amount, .wayToPay, .account { width: 120px }
  .amount { text-align: right; padding-right: 20px;}
  .id { width: 80px}

    /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {


    .date, .amount, .account { width: 95px; }
  }
</style>