<template>
  <div class="psPatientsDetail">
    <ps-patient-detail-upper-bar
      :site="{ site: 'Perfil' }"
      icon="el-icon-edit"
      @clickIcon="editProfile"
    ></ps-patient-detail-upper-bar>
    <div class="container">
      <ps-patient-detail-personal></ps-patient-detail-personal>
      
      <div class="row patientIndicators">
        <ps-indicator-number
          :config="configStartWeight"
          :value="patient.startWeightTable"
          :unit="'kg'"
          :labelIndicator="'PESO INICIAL TABLA'"
        ></ps-indicator-number>
        <ps-indicator-number
          :config="configActualWeight"
          :value="patient.actualWeight"
          :unit="'kg'"
          :labelIndicator="'PESO ACTUAL'"
        ></ps-indicator-number>
        <ps-indicator-number
          :config="configTargetWeight"
          :value="patient.targetWeight"
          :unit="'kg'"
          :labelIndicator="'PESO OBJETIVO'"
        ></ps-indicator-number>
        <ps-indicator-number
          :config="configDesiredWeight"
          :value="patient.desiredWeight"
          :unit="'kg'"
          :labelIndicator="'PESO DESEADO'"
        ></ps-indicator-number>
        <ps-indicator-number
          :config="configUsualWeight"
          :value="patient.usualWeight"
          :unit="'kg'"
          :labelIndicator="'PESO HABITUAL'"
        ></ps-indicator-number>
        <ps-indicator-number
          :config="configHeight"
          :value="patient.height"
          :unit="'mt'"
          :labelIndicator="'TALLA'"
        ></ps-indicator-number>
      </div>
      <div class="row patientDiseases">
        <ps-patient-general-information></ps-patient-general-information>
        <ps-patient-diseases></ps-patient-diseases>
      </div>
      <div class="row patientEventuals">
        <ps-patient-eventual-information></ps-patient-eventual-information>
        <ps-patient-medication></ps-patient-medication>
      </div>
      <ps-patient-chart-trend
        v-if="
          patient.lastWeights &&
            patient.lastWeights.weights &&
            patient.lastWeights.weights.length > 0
        "
      ></ps-patient-chart-trend>
    </div>
  </div>
</template>

<script>
import PsPatientDetailPersonal from "./widgets/PsPatientDetailPersonal.vue";
import PsPatientDetailUpperBar from "./widgets/PsPatientDetailUpperBar.vue";
import PsIndicatorNumber from "@/components/PsIndicatorNumber.vue";
import PsPatientDiseases from "./widgets/PsPatientDiseases.vue";
import PsPatientEventualInformation from "./widgets/PsPatientEventualInformation.vue";
import PsPatientMedication from "./widgets/PsPatientMedication.vue";
import PsPatientChartTrend from "./widgets/PsPatientChartTrend.vue";
import PsPatientGeneralInformation from "./widgets/PsPatientGeneralInformation.vue";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "PsPatientsDetail",
  components: {
    PsPatientDetailPersonal,
    PsPatientDetailUpperBar,
    PsIndicatorNumber,
    PsPatientDiseases,
    PsPatientEventualInformation,
    PsPatientMedication,
    PsPatientChartTrend,
    PsPatientGeneralInformation
  },
  computed: {
    ...mapState("patients", ["editionMode", "loading", "patient"])
  },
  data() {
    return {
      configStartWeight: {
        measure: {
          color: "white",
          "background-color": "#3E8091",
          "border-bottom": "2px solid white",
          variationType: "",
          variation: false
        },
        labelIndicator: {
          color: "black"
        },
        editValue: true,
        property: "startWeightTable",
        inputType: "number"
      },
      configActualWeight: {
        measure: {
          color: "white",
          "background-color": "#60E2FF",
          variationType: "",
          variation: false
        },
        labelIndicator: {
          color: "black"
        },
        editValue: false,
        property: "actualWeight",
        inputType: "number"
      },
      configTargetWeight: {
        measure: {
          color: "white",
          "background-color": "#3E8091",
          "border-bottom": "2px solid white",
          variationType: "",
          variation: false
        },
        labelIndicator: {
          color: "black"
        },
        editValue: true,
        property: "targetWeight",
        inputType: "text"
      },
      configDesiredWeight: {
        measure: {
          color: "white",
          "background-color": "#d78464",
          "border-bottom": "2px solid white",
          variationType: "",
          variation: false
        },
        labelIndicator: {
          color: "black"
        },
        editValue: true,
        property: "desiredWeight",
        inputType: "number"
      },
      configUsualWeight: {
        measure: {
          color: "white",
          "background-color": "#80ad10",
          "border-bottom": "2px solid white",
          variationType: "",
          variation: false
        },
        labelIndicator: {
          color: "black"
        },
        editValue: true,
        property: "usualWeight",
        inputType: "number"
      },
      configHeight: {
        measure: {
          color: "white",
          "background-color": "#6e70cc",
          "border-bottom": "2px solid white",
          variationType: "",
          variation: false
        },
        labelIndicator: {
          color: "black"
        },
        editValue: true,
        property: "height",
        inputType: "number"
      }
    };
  },
  methods: {
    ...mapMutations("patients", [
      "PATIENTS_PATIENT",
      "PATIENTS_EDITION_MODE",
      "PATIENTS_LOADING"
    ]),
    ...mapActions("patients", ["getPatientHTTP", "getPaymentHistoryHTTP"]),
    ...mapActions("groups", ["getGroupsHTTP"]),
    ...mapActions("rates", ["getRatesHTTP"]),
    ...mapActions("waysToPay", ["getWaysToPayHTTP"]),
    ...mapActions("accounts", ["getAccountsHTTP"]),
    ...mapActions("socialsWorkers", ["getOssHTTP"]),
    editProfile() {
      this.PATIENTS_EDITION_MODE(!this.editionMode);
    }
  },
  async created() {
    if (this.$route.params["patientId"] == 0) {
      this.PATIENTS_PATIENT({});
    } else {
      await this.getPatientHTTP(this.$route.params["patientId"]);
      await this.getPaymentHistoryHTTP(this.$route.params["patientId"]);
    }
    await this.getGroupsHTTP({ page: 0 });
    await this.getRatesHTTP();
    await this.getWaysToPayHTTP();
    await this.getAccountsHTTP();
    await this.getOssHTTP();
  }
};
</script>

<style scoped>
.psPatientsDetail {
  height: 95%;
}
.container {
  height: 95%;
  overflow-y: scroll;
  padding-bottom: 100px;
}
.container::-webkit-scrollbar {
  display: none;
}
.patientIndicators {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.patientDiseases,
.patientEventuals {
  justify-content: space-between;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .patientIndicators {
    flex-flow: wrap;
  }
  .patientDiseases,
  .patientEventuals {
    flex-direction: column;
  }
}
</style>
