<template>
  <div class="container">
    <figure class="imgBackground">
      <img  width="250" src="../../assets/images/frutillas.jpg" alt="Frutillas" >
    </figure>
    <div class="login">
      <figure>
        <img  width="250" src="../../assets/images/isologotipo.png" alt="Isologotipo Trimafi">
      </figure>
      <div class="formContainer" @keyup.enter="login">
        <div class="input-container">
          <input type="text" id="username" required v-model="userLocal.username" spellcheck="false" />
          <label for="username">Usuario</label>
        </div>
        <div class="input-container">
          <input type="password" id="password" required v-model="userLocal.password" spellcheck="false" />
          <label for="password" >Contraseña</label>
        </div>
        <div class="rowButton">
          <el-button round @click="login" class="btnLogin" id="btnLogin" :disabled="disableBtnLogin">Ingresar</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'PsLogin',
  data () {
    return {
      userLocal: {
        username: '',
        password: '' 
      }
    }
  },
  computed: {
    ...mapState(['isMobile', 'user']),
    disableBtnLogin () {
      return !(this.userLocal.username && this.userLocal.username != '' && this.userLocal.password && this.userLocal.password != '')
    }
  },
  methods: {
    ...mapActions(['loginHTTP']),
    async login () {
      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Iniciando sesión...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });    
        
        await this.loginHTTP({username: this.userLocal.username, password: this.userLocal.password})
              
        if(this.user.roleId == 1) {
          this.$router.replace('/home/indicators')          
        } else {
          this.$router.replace('/groups/list')          
        }
      } catch ( err ) { this.$message( { message: err.response.data.err.details, type: 'error' }) }
      finally { loading.close(); }         
    }
  }
}
</script>

<style scoped>
  .container { width: 100vw; height: 100vh; display: flex;  align-items: center; }
  .imgBackground {
    width: 50%;
    height: 100%;
    overflow: hidden;
  }

  .imgBackground > img { width: 100%;}

  .login { 
    width: 50%; 
    height: 60%; 
    max-height: 500px;
    border-radius: 8px; 
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    justify-content: space-between;
  }
  .column { margin-top: 20px;}
  .formContainer { width: 25%; max-width: 300px;}

  .input-container {
    position: relative;
    height: 56px;
  }


  input { border: none; border-bottom: 1px solid #0A4B97; padding: 0 0 5px; width: 100%;  color: #3E8091; font-weight: bold; background-color: transparent; }
  
  label {
    display: block;
    position: absolute;
    text-align: left;
    transform: translateY(-130%); 
    transition: 0.1s ease;
    color: #3E8091;
  }

  input:focus { outline: none; border-bottom: 2px solid #0A4B97 ;}
  
  input:focus + label,
  input:valid + label {
    transform: translateY(-240%) translateX(-12%) scale(0.75);
  }

  #btnLogin { width: 100%; background-color: #5DBEBC; color: white; border: none;  padding: 24px 23px; border-radius: 30px; }
  #btnLogin.is-disabled {  background-color: #b3b3b3; }


    /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .imgBackground { display: none }
    .login { width: 100%; position: absolute; top: 100px; left: 0; z-index: 10; }
    .formContainer { width: auto;}
    input, label { color: black}
    input { border-bottom-color: black;}
    input:focus { border-bottom-color: black;}
    
  }
</style>