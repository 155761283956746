<template>
  <div class="pageContainer">
    <ps-lateral-bar class="lateralBar"></ps-lateral-bar>
    <div class="dynamicRoute" id="dynamicRoute">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import PsLateralBar from '@/components/layout/PsLateralBar.vue'

export default {
  name: 'PsPageContainer',
  components: { PsLateralBar },
  props: {
    site: Object
  },

}
</script>

<style scoped>
  .pageContainer { width: 100%; height: 100%; display: flex;}
  .dynamicRoute { 
    display: flex; 
    flex-direction: column; 
    z-index: 1; 
    min-height: 90%; 
    background-color: #e1e1e1; 
    width: 94%; 
    min-width: 1200px;
    margin: 16px 16px 0 0; 
    border-radius: 70px 70px 0 0 ;
    padding: 0 50px;
    overflow: hidden;
  }
    
  .lateralBar { width: 6%}

  /* @media (max-width: 575.98px) { ... } */

  /* Small devices (landscape phones, less than 768px) */
  /* @media (max-width: 767.98px) { ... } */

  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .lateralBar { display: none }
    .dynamicRoute { margin: 0; border-radius: 0; width: 100%; min-width: 0; padding: 0 16px}
  }

  /* Large devices (desktops, less than 1200px) */
  /* @media (max-width: 1199.98px) { ... } */
</style>