<template>
  <div class="psConfigurationPanel">
    <ps-upper-bar :site="{ site: 'Configuración' }"></ps-upper-bar>
    <div class="container">
      <!--  Grupo de tarifas -->
      <ps-configuration-link :icon="'el-icon-connection'" :label="'Grupo de tarifas'" :to="'/configuration/ratesGroup'"></ps-configuration-link>
      <!--  Tarifas -->
      <ps-configuration-link :icon="'icon-pos'" :label="'Tarifas'" :to="'/configuration/rates'"></ps-configuration-link>
      <!-- Forma de pago -->
      <ps-configuration-link :icon="'icon-wallet'" :label="'Formas de pago'" :to="'/configuration/waysToPay'"></ps-configuration-link>
      <!-- Cuentas -->
      <ps-configuration-link :icon="'icon-shield-lock'" :label="'Cuentas'" :to="'/configuration/accounts'"></ps-configuration-link>
      <!-- Usuarios -->
      <ps-configuration-link :icon="'icon-user'" :label="'Usuarios'" :to="'/configuration/users'"></ps-configuration-link>
      <!-- Enfermedades de base -->
      <ps-configuration-link :icon="'el-icon-first-aid-kit'" :label="'Enfermedades de base / Momento biológico'" :to="'/configuration/diseases'"></ps-configuration-link>
      <!-- Información eventual -->
      <ps-configuration-link :icon="'el-icon-timer'" :label="'Información eventual'" :to="'/configuration/eventuals'"></ps-configuration-link>
      <!-- Fases -->
      <ps-configuration-link :icon="'el-icon-timer'" :label="'Fases'" :to="'/configuration/phases'"></ps-configuration-link>
      <!-- Conceptos -->
      <ps-configuration-link :icon="'el-icon-timer'" :label="'Conceptos'" :to="'/configuration/concepts'"></ps-configuration-link>
    </div>
  </div>
</template>

<script>
import PsUpperBar from '@/components/layout/PsUpperBar.vue'
import PsConfigurationLink from './widgets/PsConfigurationLink.vue'

export default {
  name: 'PsConfigurationPanel',
  components: { PsUpperBar, PsConfigurationLink }
}
</script>

<style scoped>
  .psConfigurationPanel { min-height: 90%;}
  .container { background-color: white; border-radius: 20px; padding: 32px; min-height: 90%; }
</style>