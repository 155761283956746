<template>
  <div class="psCalendarGrid">
    <ps-calendar-upper-bar :site="{ site: 'Calendario' }"></ps-calendar-upper-bar>
    <div class="container">
      <ps-calendar-grid-month v-for="(calendarMonth, index) in calendar" :calendar-month="calendarMonth" :key="index" :index="index"></ps-calendar-grid-month>
    </div>
  </div>
</template>

<script>
import PsCalendarUpperBar from './widgets/PsCalendarUpperBar.vue'
import PsCalendarGridMonth from './widgets/PsCalendarGridMonth.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'PsCalendarGrid',
  components: { PsCalendarUpperBar, PsCalendarGridMonth },
  computed: {
    ...mapState('calendar', ['calendar'])
  },
  methods: {
    ...mapActions('calendar', ['getCalendarHTTP']),
    ...mapActions('calendarTypes', ['getCalendarTypesHTTP']),
    ...mapActions('patients', ['getPatientsHTTP'])
  },
  async created () {
    await this.getCalendarHTTP()
    await this.getCalendarTypesHTTP()
    await this.getPatientsHTTP({page: 0})
  }
}
</script>

<style scoped>
  .container { display: flex; flex-direction: column; align-content: flex-start; gap: 20px; height: calc(100vh - 150px); overflow-y: scroll ; width: 100% }
  .container::-webkit-scrollbar {
    width: 8px;     
    height: 100%;    
    display: none;  
  }

  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    /* .container { height: calc(100vh - 300px)}  */
  }
</style>