import api from "../services/api"
import { addProperyIfNotExists } from "../services/utils"

const state = {
  account: {},
  newAccount: {
    accountId: 0,
    accountDescription: '',
    accumulatesOficial: 0,
    maximum: 0,
    username: '',
    userId: 0,
    active: true,
    createdBy: 0,
    updatedBy: 0,
    createdAt: '',
    updatedAt: ''
  },
  accounts: [],
  errors: []

}

const mutations = {
  ACCOUNTS_ACCOUNTS(state, accounts) { state.accounts = accounts },
  ACCOUNTS_ACCOUNT(state, account) { state.account = addProperyIfNotExists(account, state.newAccount) },
  ACCOUNTS_ACCOUNT_PROP(state, payload) { state.account[payload.property] = payload.value },
  ACCOUNTS_EMPTY_ERRORS(state) { state.errors = [] },
  ACCOUNTS_ADD_ERROR(state, error) { state.errors.push(error) }
}

const actions = {
  async getAccountsHTTP({ commit }) {
    const accounts = await api.get('/configuration/accounts')
    commit('ACCOUNTS_ACCOUNTS', accounts.data.data)
  },
  async saveAccount({ state, commit, dispatch }) {
    commit('ACCOUNTS_EMPTY_ERRORS')
    if (state.account.accountDescription == '') commit('ACCOUNTS_ADD_ERROR', 'La descripción de la cuenta es requerida')
    if (state.account.userId == '') commit('ACCOUNTS_ADD_ERROR', 'Es necesario seleccionar un usuario para la cuenta')

    if (state.errors.length == 0) {
      if (state.account.accountId == 0) {
        return await dispatch('createAccountHTTP')
      } else {
        return await dispatch('updateAccountHTTP')
      }
    }
  },
  async createAccountHTTP({ state, rootState }) {
    let account = state.account
    account.createdBy = rootState.user.userId
    await api.post('/configuration/accounts', account)
    return 'Cuenta creada'
  },
  async updateAccountHTTP({ state, rootState }) {
    let account = state.account
    account.createdBy = rootState.user.userId
    account.updatedBy = rootState.user.userId
    await api.put('/configuration/accounts', account)
    return 'Cuenta actualizada'
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}