import api from "../services/api"
import {addProperyIfNotExists} from "../services/utils"

const state = {
  rateGroup: {},
  newRateGroup: {
    id: 0,
    description: '',
    ratesByGroup: [],
    createdBy: 0,
    updatedBy: 0,
    createdAt: '',
    updatedAt: ''
  },
  ratesGroup: [],
  errors: []
}

const mutations = {
  RATES_GROUP_RATES_GROUP ( state, ratesGroup ) { state.ratesGroup = ratesGroup },
  RATES_GROUP_RATE_GROUP ( state, rateGroup ) { state.rateGroup = addProperyIfNotExists(rateGroup, state.newRateGroup) },
  RATES_GROUP_RATE_GROUP_PROP ( state, payload ) { state.rateGroup[payload.property] = payload.value },
  RATES_GROUP_EMPTY_ERRORS ( state ) { state.errors = [] },
  RATES_GROUP_ADD_ERROR ( state, error ) { state.errors.push(error)}
}

const actions = {
  async getRatesGroupHTTP ( { commit }) {
    const rates = await api.get('/configuration/ratesGroup')
    const data = rates.data.data;
    data.forEach( rate => rate.ratesByGroup.forEach( item => { item.checked = false } ))
    console.log(data)
    commit('RATES_GROUP_RATES_GROUP', data)
  },
  async saveRateGroup ( { state, commit, dispatch } ) {
    
    commit('RATES_GROUP_EMPTY_ERRORS')
    if( state.rateGroup.description == '') commit('RATES_GROUP_ADD_ERROR', 'La descripción del grupo de tarifas es requerida');
    
    if( state.errors.length == 0 ) {
      if( state.rateGroup.id == 0 ) {
        
        return await dispatch('createRateGroupHTTP')
      } else {
        return await dispatch('updateRateGroupHTTP')
      }
    }
  },
  async createRateGroupHTTP ( { state, rootState } ) {
    let rateGroup = state.rateGroup
    rateGroup.createdBy = rootState.user.userId
    await api.post('/configuration/ratesGroup', rateGroup )
    return 'Grupo de tarifas creada'
  },
  async updateRateGroupHTTP ( { state, rootState } ) {
    let rateGroup = state.rateGroup
    rateGroup.createdBy = rootState.user.userId
    rateGroup.updatedBy = rootState.user.userId
    await api.put('/configuration/ratesGroup', rateGroup )
    return 'Grupo de tarifas actualizado'
  },
  async deleteRateGroupHTTP ( { state } ) {
    let rateGroup = state.rateGroup
    await api.delete(`/configuration/ratesGroup/${rateGroup.id}` )
    return 'Grupo de tarifas eliminado'
  },
  async sendRatesToPrincipalHTTP ( { state }, selection ) {
    let data = {
      selection,
      ratesGroupId: state.rateGroup.id,
    }
    await api.post(`/configuration/ratesGroup/transferRates`, data )
    return 'Pacientes seleccionados pasados a la tarifa principal';
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}