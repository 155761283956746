<template>
  <div class="psHomeGroupsInPhase">
    <div class="title">Grupos de mi fase</div>
    <div class="container" v-if="indicators && indicators.groupsInPhase">
      <div class="scrollContainer">
        <div class="group" v-for="(group, index) in indicators.groupsInPhase" :key="index">{{group.groupDescription}}</div>
      </div>
    </div>
    <div class="withoutGroups" v-else>
      Sin grupos para mostrar
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PsHomeGroupsInPhase',
  computed: {
    ...mapState('home', ['indicators']),
  }
}
</script>

<style scoped>
  .psHomeGroupsInPhase { width: 380px; display: flex; flex-direction: column; align-items: flex-start; }
  .title { font-weight: bold; font-size: 1.25rem; margin-bottom: 10px;} 
  .scrollContainer { display: flex; flex-wrap: wrap;}
  .group { 
    width: 75px; 
    height: 75px; 
    border-radius: 16px; 
    background-color: white; 
    box-shadow: 1px 1px 1px 1px #bbb; 
    color: black; 
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px 8px 0;
  }
  .withoutGroups { min-height: 250px; width: 100%; display: flex; justify-content: center; align-items: center; background-color: white; border-radius: 16px;}

    /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .psHomeGroupsInPhase { margin-top: 20px; }
    .container { flex-wrap: nowrap; width: 100%; overflow-x: scroll; }
    .container::-webkit-scrollbar { display: none}
    .scrollContainer { flex-wrap: nowrap; min-width: 500px;  }
  }
</style>