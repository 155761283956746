<template>
  <div class="lateralBar">
    <figure class="isologotipo">
      <img width="100%" src="@/assets/images/isologotipo.png" alt="Isologotipo Plan A">
    </figure>
    <div class="containerMenu">
      <div class="menu">
        <ps-item-menu v-for="(item, index) in isAdmin == false ? itemsMenu.filter(i => i.permissionCode == 2) : itemsMenu" :key="index" :item="item" :labels="labels"></ps-item-menu>
      </div>
      <div class="closeSeccionItem">
        <ps-item-menu :item="{ route: '/', active: false, icon: 'el-icon-close', title: 'Cerrar sesión'}" :labels="labels"></ps-item-menu>
      </div>
    </div>
  </div>
</template>

<script>
import PsItemMenu from '@/components/layout/PsItemMenu.vue'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'PsLateralBar',
  components: { PsItemMenu },
  props: ['labels'],
  computed: {
    ...mapState('routes', ['itemsMenu']),
    ...mapGetters(['isAdmin']),
  }
}
</script>

<style scoped>
  .lateralBar { min-height: 500px; min-width: 100px; }

  .isologotipo { width: 100%; padding: 5px}
  .menu { display: flex; flex-direction: column; align-items: center;}
  .closeSeccionItem { display: none }

    /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .isologotipo { width: 130px; margin: 0 auto;}
    .menu { align-items: flex-start;  }
    .containerMenu { height: 100%; display: flex; flex-direction: column; justify-content: space-between; padding: 0 16px; }
    .closeSeccionItem { display: inline; margin-top: 50px}
  }
</style>