<template>
  <div class="psPatientPaymentDetail">
    <div class="row">
      <div class="column w-33">
        <div class="label">Fecha del pago</div>
        <el-date-picker 
          :value="payment.paymentDate" 
          type="date" 
          placeholder="Seleccione una fecha" 
          format="dd/MM/yyyy"
          :default-value="new Date()"
          @input="changeProperty($event, 'paymentDate')"
          style="width: 200px"
          ></el-date-picker>
      </div>
      <div class="column w-33">
        <div class="label" style="padding-left: 15px;">Importe</div>
        <div style="display: flex">
          <span style="margin-right: 6px; line-height: 40px">$</span>
          <el-input-number 
            style="width: 200px;" 
            :min="0" 
            :controls="false" 
            :value="payment.paymentAmount" 
            @input="changeProperty($event, 'paymentAmount')"
            @focus="deleteZero"
            ref="paymentAmount"
          ></el-input-number>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="column w-50">
        <div class="label">Forma de pago</div>
        <el-select :value="payment.wayToPayDescription" placeholder="Seleccione forma de pago" @change="changeProperty($event, 'wayToPay')">
          <el-option v-for="(wayToPay, index) in waysToPay" :key="index" :label="wayToPay.wayToPayDescription" :value="wayToPay"></el-option>
        </el-select>
      </div>
      <div class="column w-50">
        <div class="label">Cuenta</div>
        <el-select :value="payment.accountDescription" placeholder="Seleccione una cuenta" @change="changeProperty($event, 'account')">
          <el-option v-for="(account, index) in accounts" :key="index" :label="account.accountDescription" :value="account"></el-option>
        </el-select>
      </div>
    </div>


    <el-button @click="save" icon="el-icon-check" type="success" round class="btnSave">Guardar</el-button>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsPatientPaymentDetail',
  computed: {
    ...mapState('patients', ['payment']),
    ...mapState('waysToPay', ['waysToPay']),
    ...mapState('accounts', ['accounts']),
  },
  methods: {
    ...mapMutations('patients', ['PATIENTS_PAYMENT', 'PATIENTS_PAYMENT_PROP', 'PATIENTS_DIALOG_PAYMENT']),
    ...mapActions('patients', ['savePayment', 'getPatientHTTP']),
    changeProperty ( value, property ) {
      if ( property == 'wayToPay' ) {
        this.PATIENTS_PAYMENT_PROP( {property: 'wayToPayId', value: value.wayToPayId } )
        this.PATIENTS_PAYMENT_PROP( {property: 'wayToPayDescription', value: value.wayToPayDescription } )
      } else if ( property == 'account' ) {
        this.PATIENTS_PAYMENT_PROP( {property: 'accountId', value: value.accountId } )
        this.PATIENTS_PAYMENT_PROP( {property: 'accountDescription', value: value.accountDescription } )
      } else {
        this.PATIENTS_PAYMENT_PROP( {property, value})
      }      
    },
    deleteZero () {
      this.$refs.paymentAmount.select()
    },


    async save () {
      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Guardando pago...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });   

        await this.savePayment()
        this.$message.success( 'Pago guardado!' )
        setTimeout( async () => {
          await this.getPatientHTTP( this.$route.params['patientId'])
          loading.close()
          this.PATIENTS_PAYMENT({})
          this.PATIENTS_DIALOG_PAYMENT(false)
        }, 1000)
      } catch ( err ) { this.$message( { message: err.response.data.err.details, type: 'error' })}
      finally { loading.close()}
     
    },
    
  }
}
</script>

<style scoped>
  .psPatientPaymentDetail > div.row { justify-content: center; align-items: center;}
  .btnSave { margin-top: 15px}

  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .psPatientPaymentDetail > .row { flex-direction: column; width: 100%; align-items: center;}
    .psPatientPaymentDetail > .row > .w-33 { width: 100%; margin-bottom: 15px; align-items: center;}
    .psPatientPaymentDetail > .row > .w-33:nth-child(1) { margin-left: 28px}
  }
</style>