<template>
  <el-dialog
    class="psCalendarDialogNewEvent"
    title="Nuevo evento"
    :visible.sync="newEventDialogVisible"
    :width="isMobile == true ? '100%' : '50%'"
    :destroy-on-close="true"
    :before-close="handleClose"
    >

      <div class="column columnContent">
        <div class="row rowDate">
          <div class="column">
            <div class="label">Fecha</div>
            <el-date-picker 
              :value="calendarItem.calendarDate" 
              type="date" 
              placeholder="Seleccione una fecha" 
              format="dd/MM/yyyy"
              :picker-options="pickerOptionsFutureDate" 
              :default-value="new Date()"
              @input="changeProperty($event, 'calendarDate')"
              ></el-date-picker>
          </div>
          <div class="column">
            <div class="row">
              <div class="column">
                <div class="label">Tipo de evento</div>
                <el-select :value="calendarItem.calendarTypeDescription" @change="changeProperty($event, 'calendarTypeDescription')">
                  <el-option v-for="(calendarType, index) in calendarTypes" :key="index" :value="calendarType">{{calendarType.calendarTypeDescription}}</el-option>
                </el-select>
              </div>
            </div>
          </div>      
        </div>

        <div class="column w-100 columnSelectPatient" >
          <div class="label"  v-if="calendarItem.calendarType == 1">Seleccionar paciente</div>
          <el-switch v-model="choosePatientEvent"  v-if="calendarItem.calendarType == 1">Seleccionar paciente</el-switch>
          <el-autocomplete 
            v-if="choosePatient == true"
            class="patient"
            :value="calendarItem.patient" 
            @input="inputPatient" 
            :fetch-suggestions="patientsList" 
            placeholder="Comience a escribir el nombre del paciente" 
            @select="selectPatient"></el-autocomplete>
        </div>

        <div class="row">
          <div class="column w-100 columnTextarea" >
            <div class="label">Observaciones</div>
            <textarea name="calendarDescription" class="textarea" id="calendarDescription" cols="58" rows="5" v-model="calendarDescription" placeholder="Observaciones"></textarea>         
          </div>
        </div>

        <div class="row rowButton">
          <el-button type="primary" round @click="save" :disabled="!disabledSaveButton">Guardar</el-button>
          <el-button type="danger" round plain @click="deleteEvent" v-if="calendarItem.calendarId > 0">Eliminar</el-button>          
        </div>
    </div>
  
  </el-dialog>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsCalendarDialogNewEvent',
  data () {
    return {
      pickerOptionsFutureDate: {
        disabledDate(time) {
          const d = new Date()
          return time.getTime() < new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0,0,0);
        },
      },
      choosePatient: false
    }
  },
  computed: {
    ...mapState('calendar', ['newEventDialogVisible', 'calendarItem', 'errors']),
    ...mapState('calendarTypes', ['calendarTypes', 'calendarType']),
    ...mapState('patients', ['patients']),
    ...mapState(['isMobile']),
    calendarDescription: {
      set ( calendarDescription ) {
        this.CALENDAR_CALENDAR_ITEM_PROP( {property: 'calendarDescription', value: calendarDescription} )
      },
      get () {
        return this.calendarItem.calendarDescription
      }
    },
    choosePatientEvent: {
      set ( value ) {
        this.choosePatient = value
        this.changeProperty(0, 'patientId')
        this.changeProperty('', 'patient')
      },
      get () {
        return this.choosePatient
      }
    },
    disabledSaveButton () {
      if ( this.calendarItem && this.calendarItem.calendarDate && this.calendarItem.calendarType > 0 ) {
        if ( this.calendarItem.calendarType == 1 ) {
          return this.calendarItem.calendarDescription != ''
        } else {
          return this.calendarItem.patientId > 0
        }        
      } else {
        return false
      }
      
      
    }
  },  
  methods: {
    ...mapMutations('calendar', ['CALENDAR_NEW_EVENT_DIALOG_VISIBLE', 'CALENDAR_CALENDAR_ITEM_PROP']),
    ...mapActions('calendar', ['saveCalendarEvent', 'getCalendarHTTP', 'deleteCalendarEventHTTP']),
    async selectPatient( patient ) {
      if(patient) {
        this.changeProperty(patient.patientId, 'patientId')
        this.changeProperty(patient.value, 'patient')
      }
    },
    patientsList (query, cb) {
      let result = query ? this.patients.filter( patient => patient.value.toLowerCase().includes(query.toLowerCase())) : this.patients
      return cb(result)
    },
    inputPatient (newValue) {
      let patient;
      if(newValue != '') {
        let patients = this.patients
        patient = newValue ? patients.find( patient => patient.value.toLowerCase() == newValue.toLowerCase()) : {}
      } 

      if(patient) {
        this.selectPatient(patient)
      } else {
        this.changeProperty(newValue, 'patient')
        this.changeProperty(0, 'patientId')
      }
    },    
    handleClose () {
      this.CALENDAR_NEW_EVENT_DIALOG_VISIBLE(false)
    },
    changeProperty ( value, property ) {
      if( property == 'calendarTypeDescription' ) {
        this.CALENDAR_CALENDAR_ITEM_PROP({property, value: value.calendarTypeDescription})
        this.CALENDAR_CALENDAR_ITEM_PROP({property: 'calendarType', value: value.calendarTypeId})

        if( value.calendarTypeId > 1 ) {
          this.choosePatient = true
        } else {
          if( this.calendarItem.patientId == 0) {
            this.choosePatient = false
          }

        }
      } else {
        this.CALENDAR_CALENDAR_ITEM_PROP({property, value})
      }
    },
    async save () {
      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });   

        const message = await this.saveCalendarEvent()
        if(this.errors.length > 0 ) {
          this.showValidationAlert()
        } else {
          this.$message( { message, type: 'success' } )
          await this.getCalendarHTTP()
        }
      } catch ( err ) { this.$message( { message: err.response.data.err.details, type: 'error' })}
      finally { 
        loading.close()
        this.CALENDAR_NEW_EVENT_DIALOG_VISIBLE(false)
      }

      
    },
    async deleteEvent () {
      this.$confirm('¿Confirma eliminar este evento?', 'Advertencia', {
        type: 'warning',
        confirmButtonText: 'Sí',
        cancelButtonText: 'No'
      })
      .then( async () => {
        await this.deleteCalendarEventHTTP()
        this.$message.success('Evento eliminado!')
        await this.getCalendarHTTP()
      })
      .catch( () => null )
      .finally (() => this.CALENDAR_NEW_EVENT_DIALOG_VISIBLE(false))
    },
    showValidationAlert () {      
      let str = '<p>';
      if(this.errors.length > 0) {
        this.errors.forEach(err => str += `${err}<br>`)
        str = str + '</p>'
        this.$alert(str, '', {confirmButtonText: 'Entendido', type: 'warning', dangerouslyUseHTMLString: true})
      }
    },
  },
  created () {
    if (this.calendarItem.patientId > 0) {
      this.choosePatient = true
    } else {
      this.choosePatient = false
    }
  }
}
</script>

<style scoped>
  .rowDate { justify-content: space-between;}
  .column > .row > .column:nth-child(2) > .row > .column, .column > .row > .column:nth-child(2), .columnTextarea { margin-right: 0;}
  .columnContent { align-items: center; margin-right: 0;}
  .columnSelectPatient { width: 100%; max-width: 475px;  padding-left: 12px; align-items: flex-start; margin-bottom: 15px}
  .patient { margin-top: 15px; margin-bottom: 30px; width: 100%}
  .textarea { width: 100%; font-family: Helvetica, sans-serif; color: #606266; border-radius: 4px; padding: 5px }
  .textarea:focus-visible { outline-color: #3e8091; outline-width: 0px; border-color: #3e8091}


    /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .rowDate { flex-direction: column; width: 100%}
    .column { margin-right: 0;}
    .rowDate > .column { margin-bottom: 15px;}
    .rowDate > .column > .row, .rowDate > .column > .row > .column { width: 100%}
  }
  
</style>