<template>
  <div class="upperBar column">
    <div class="menu" @click="openMenu"><i class="icon-text-align-justify"></i></div>
    <div class="row">
      <ps-back-button v-if="backButton"></ps-back-button>
      <div class="containerSite column">
        <div class="site">{{ site.site }}</div>
        <div class="greeting">Hola {{user.username | textCapitalize}}</div>
      </div>
      <ps-new-button v-if="newButton" :tooltip="tooltip" @newItem="newItem"></ps-new-button>
      <ps-avatar class="avatar"></ps-avatar>
    </div>
    
  </div>
</template>

<script>
import PsAvatar from '@/components/layout/PsAvatar.vue'
import PsBackButton from '@/components/PsBackButton.vue'
import PsNewButton from '@/components/PsNewButton.vue'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'PsUpperBar',
  props: {
    site: Object,
    backButton: {
      type: Boolean,
      default: false
    },
    newButton: {
      type: Boolean,
      default: false
    },
    tooltip: String
  },
  components: { PsAvatar, PsBackButton, PsNewButton },
  computed: {
    ...mapState('routes', ['routeSite']),
    ...mapState(['user']),
    latestItemRoute () {
      if(this.routeSite.type != 'pagina') {
        return `${this.routeSite.prefix} ${this.$options.filters.textCapitalize(this.routeSite.description)}`
      } else {
        return this.$options.filters.textCapitalize(this.routeSite.description)
      }
    }
  },
  methods: {
    ...mapMutations(['DRAWER_MENU']),
    openMenu () {
      this.DRAWER_MENU(true)
    },
    newItem () {
      this.$emit('newItem')
    }
  },
}
</script>

<style scoped>
  .upperBar { height: 60px;  display: flex; margin-top: 50px; }
  .containerSite { display: flex; flex-direction: column; align-items: flex-start;}
  .site { font-weight: bold; font-size: 1.25rem; }
  .greeting { color: #0290af; font-size: 0.9rem; font-weight: 300;}
  .menu { display: none; font-size: 1.25rem;}

    /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .upperBar {margin-top: 16px; height: auto; margin-right: 0;}
    .avatar { display: none; }
    .menu { display: block; align-self: flex-end;}
    .site { text-align: left;}
  }
</style>