import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './services/api'
import ElementUI from 'element-ui';
import './assets/styles/theme/index.css';
import locale from 'element-ui/lib/locale/lang/es'

Vue.use(ElementUI, { locale })

Vue.config.productionTip = false

Vue.filter('textCapitalize', (value) => {
  if (!value) return ''

  let array = value.toLowerCase().split(' ')
  return array.map(item => item.substring(0, 1).toUpperCase() + item.substring(1, item.length)).join(' ')
})

Vue.filter('toUpperCase', (value) => {
  if (!value) return ''
  return value.toUpperCase();
})

Vue.filter('numberTwoDecimal', (value) => {
  if (isNaN(value)) {
    return 0.00
  } else {
    value = Number(value)
    return value == 0 ? '' : value.toFixed(2)
  }
})

Vue.filter('numberWithoutDecimal', (value) => {
  if (isNaN(value)) {
    return 0
  } else {
    return value == 0 ? '' : value.toFixed(0)
  }
})

Vue.filter('numberOneDecimal', (value) => {

  if (isNaN(value)) {
    return 0.0
  } else {
    return Number(value) == 0 ? '' : Number(value).toFixed(1)
  }
})

Vue.filter('formatDateDDMMYYYY', (value) => {

  let fecha = new Date(value)
  let dia = fecha.getDate()
  let mes = fecha.getMonth() + 1
  let anio = fecha.getFullYear()

  if (dia < 10) dia = '0' + dia
  if (mes < 10) mes = '0' + mes

  return !dia ? '' : dia.toString() + '/' + mes.toString() + '/' + anio.toString()
})

Vue.filter('formatDatetimeDDMMYYYYhhmm', (value) => {
  let fecha = new Date(value)
  let dia = fecha.getDate()
  let mes = fecha.getMonth() + 1
  let anio = fecha.getFullYear()
  let hora = fecha.getHours()
  let minutos = fecha.getMinutes()

  if (dia < 10) dia = '0' + dia
  if (mes < 10) mes = '0' + mes
  if (hora < 10) hora = '0' + hora
  if (minutos < 10) minutos = '0' + minutos

  return dia.toString() + '/' + mes.toString() + '/' + anio.toString() + ' ' + hora.toString() + ':' + minutos.toString()
})

Vue.filter('trim', (value) => {
  if (value) {
    return String(value).trim()
  } else {
    return ''
  }
})

Vue.filter('money', (value) => {
  if (value) {
    value = value.toString().replace('.', ',')
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = '$1.';
    return `$ ${value.toString().replace(exp, rep)}`
  } else {
    return '$ 0.00'
  }
})

Vue.filter('percentage', (value) => {
  if (value) {
    return `${Number(value).toFixed(1)} %`
  } else {
    return ''
  }
})





api.interceptors.request.use(
  config => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {
      config.headers['token'] = user.token
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

api.interceptors.response.use(
  response => {
    return response
  },
  err => {
    console.log({ err })
    if (err.response && err.response.status == 401) {
      router.replace('/')
      return Promise.reject(err);
    }
    return Promise.reject(err);
  }
)



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


