<template>
  <div class="psAdministrationTransactions">
    <ps-upper-bar :site="{ site: 'Administración' }" :back-button="true"></ps-upper-bar>
    
    <div class="containerIndicator">
      <div class="row rowDate">
        <div class="rowDateFilter">
          <div class="column w-40">
            <div class="label" style="margin-bottom: 8px;">Mes</div>
            <el-date-picker 
              type="month" 
              placeholder="Seleccionar un mes" 
              :value="monthSelected" 
              @input="selectMonth($event)"
              :picker-options="pickerOptions"
              :format="'MM/yyyy'"></el-date-picker>
          </div>
          <div class="column w-10">
            <div class="label"  style="margin-bottom: 8px;">&nbsp;</div>
            <el-button round type="primary" @click="filterData">Filtrar</el-button>
          </div>
        </div>

        <ps-indicator-amount :value="transactions.incomings" :config="configIncomings"></ps-indicator-amount>
        <ps-indicator-amount :value="transactions.expenses" :config="configExpenses"></ps-indicator-amount>

      </div>
    </div>

    <div class="containerTransactions">
      <div class="row">
        <span class="title">Movimientos</span>
        <ps-administration-btn-new-transaction></ps-administration-btn-new-transaction>
      </div>

      <ps-administration-transactions-filters></ps-administration-transactions-filters>

      <ps-administration-transactions-list></ps-administration-transactions-list>

      <template>
              <el-backtop target=".psAdministrationTransactions" :visibility-height="300">
        <div
          style="{
            height: 100%;
            width: 100%;
            background-color: #60E2FF;
            box-shadow: 0 0 6px rgba(0,0,0, .12);
            text-align: center;
            line-height: 40px;
            color: #333;
            border-radius: 50%
          }"
        > <i class="el-icon-arrow-up"></i></div>
      </el-backtop>
      </template>

    </div>
  </div>
</template>

<script>
import PsUpperBar from '@/components/layout/PsUpperBar.vue'
import PsIndicatorAmount from '@/components/PsIndicatorAmount.vue'
import PsAdministrationBtnNewTransaction from '@/views/administration/pages/transactions/widgets/PsAdministrationBtnNewTransaction.vue'
import PsAdministrationTransactionsFilters from '@/views/administration/pages/transactions/widgets/PsAdministrationTransactionsFilters.vue'
import PsAdministrationTransactionsList from '@/views/administration/pages/transactions/widgets/PsAdministrationTransactionsList.vue'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsAdministrationTransactions',
  components: { PsUpperBar, PsIndicatorAmount, PsAdministrationBtnNewTransaction, PsAdministrationTransactionsFilters, PsAdministrationTransactionsList },
  data () {
        return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      configIncomings: {
        amount: {
          color: 'white',
          'background-color': '#3E8091',  
        },
        labelIndicator: {
          text: 'Ingresos',
          color: 'black'
        },
      },
      configExpenses: {
        amount: {
          color: '#3E8091',
          'background-color': 'white',   
        },
        labelIndicator: {
          text: 'Egresos',
          color: 'black'
        },
      },
    }
  },
  computed: {
    ...mapState('administration', ['monthSelected', 'transactions']),
    ...mapState(['isMobile']),
    isMilagros () {
      return this.$route.params['userId'] == 3 ? true : false 
    },
    isSofia () {
      return this.$route.params['userId'] == 4 ? true : false
    },
    isValidUser () {
      return this.isMilagros || this.isSofia ? true : false
    }
  },
  methods: {
    ...mapMutations('administration', ['ADMINISTRATION_MONTH_SELECTED']),
    ...mapActions('administration', ['getAdministrationTransactionsHTTP']),
    ...mapActions('accounts', ['getAccountsHTTP']),
    ...mapActions('concepts', ['getConceptsHTTP']),
    ...mapActions('waysToPay', ['getWaysToPayHTTP']),
    selectMonth ( month ) {
      console.log(month)
      this.ADMINISTRATION_MONTH_SELECTED(month)
    },    
    async filterData () {
      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Filtrando mes seleccionado...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        let year = this.monthSelected.getFullYear()
        let month = this.monthSelected.getMonth() + 1
        await this.getAdministrationTransactionsHTTP( { year, month } )
      } catch ( err ) { this.$message( { message: 'Error al buscar la informacion. Inténtelo nuevamente', type: 'danger'})}
      finally { loading.close()}
    }
  },
  async created () {
    let today = new Date()
    let year = today.getFullYear()
    let month = today.getMonth() + 1
    await this.getAdministrationTransactionsHTTP( { year, month })
    await this.getAccountsHTTP()
    await this.getConceptsHTTP()
    await this.getWaysToPayHTTP()
  }
}
</script>

<style scoped>
  .psAdministrationTransactions { overflow-y: scroll; height: 100%; }
  .psAdministrationTransactions::-webkit-scrollbar { display: none; }

  .rowDateFilter { display: flex; width: 500px}

  .containerIndicator { 
    display: flex; 
    flex-direction: column; 
    background-color: white; 
    padding: 25px; 
    border-radius: 20px; 
    width: 100%; 
    margin-bottom: 30px;
    margin-top: 20px;
    box-shadow: 0px 1px 2px 1px #bbb;
  }

  .containerTransactions { 
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
    background-color: white; 
    padding: 25px; 
    border-radius: 20px;  
    margin-top: 10px;
    box-shadow: 0px 1px 2px 1px #bbb;
    margin-bottom: 30px;
    width: 100%;
  }

  .title { font-size: 1.25rem; line-height: 2.25rem; font-weight: bold; text-align: left; align-self: flex-start; margin-bottom: 20px}
  


    /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .tooltip { display: none;}    

    .rowDateFilter { width: 100%; margin-bottom: 25px}
    .rowDate > .w-20 { width: 40%}
    .containerIndicator { width: auto}
    .containerIndicator > .row { flex-direction: column; align-items: center;}
    .psIndicatorAmount { min-width: 250px;}
  }

</style>