<template>
  <div class="psBackButton">
    <i class="el-icon-arrow-left" @click="back"></i>
  </div>
</template>

<script>
export default {
  name: 'PsBackButton',
  methods: {
    back () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
  .psBackButton { justify-content: center; padding: 6px; cursor: pointer; width: 40px; border-radius: 20px; height: 40px; display: flex; flex-direction: column; justify-content: center; align-items: center; margin-right: 12px;}
  .psBackButton:hover { background-color: rgba(256, 256, 256, 0.3); box-shadow: 0 1px 2px 1px #999;  }
  .psBackButton:hover .el-icon-arrow-left { font-weight: bold; }
  .el-icon-arrow-left { line-height: 20px; font-size: 24px;}
</style>