import api from "../services/api"
import { addProperyIfNotExists } from "../services/utils"

const state = {
  summary: {},
  transactions: {},
  transactionsFiltered: [],
  subscriptions: {},
  subscriptionsFiltered: [],
  transaction: {},
  typeTransaction: 'Egreso',
  newTransaction: {
    accountDescription: '',
    accountId: 0,
    accountDestinyDescription: '',
    accountDestinyId: 0,
    conceptDescription: '',
    conceptId: 0,
    createdAt: null,
    createdBy: 0,
    entity: '',
    id: 0,
    month: new Date().getMonth() + 1,
    paidOutDatetime: null,
    patientId: 0,
    total: 0,
    type: '',
    updatedAt: null,
    updatedBy: 0,
    userId: 0,
    wayToPayId: 0,
    wayToPayDescription: '',
    year: new Date().getFullYear()
  },
  errors: [],
  dialogTransaction: false,
  monthSelected: new Date(),
  statesSubscriptions: [{ id: 0, description: 'Cancelada' }, { id: 1, description: 'Pendiente de pago' }, { id: 2, description: 'Pago parcial' }, { id: 3, description: 'Pagado' }]
}

const mutations = {
  ADMINISTRATION_SUMMARY(state, summary) { state.summary = summary },
  ADMINISTRATION_TRANSACTIONS(state, transactions) { state.transactions = transactions },
  ADMINISTRATION_TRANSACTION(state, transaction) { state.transaction = addProperyIfNotExists(transaction, state.newTransaction) },
  ADMINISTRATION_TRANSACTION_PROP(state, payload) { state.transaction[payload.property] = payload.value },
  ADMINISTRATION_EMPTY_ERRORS(state) { state.errors = [] },
  ADMINISTRATION_ADD_ERROR(state, error) { state.errors.push(error) },
  ADMINISTRATION_DIALOG_TRANSACTION(state, dialogTransaction) { state.dialogTransaction = dialogTransaction },
  ADMINISTRATION_MONTH_SELECTED(state, monthSelected) { state.monthSelected = monthSelected },
  ADMINISTRATION_TRANSACTIONS_FILTERED(state, transactionsFiltered) { state.transactionsFiltered = transactionsFiltered },
  ADMINISTRATION_TYPE_TRANSACTION(state, typeTransaction) { state.typeTransaction = typeTransaction },
  ADMINISTRATION_SUBSCRIPTIONS(state, subscriptions) { state.subscriptions = subscriptions },
  ADMINISTRATION_SUBSCRIPTIONS_FILTERED(state, subscriptionsFiltered) { state.subscriptionsFiltered = subscriptionsFiltered },
  ADMINISTRATION_SET_INVOICED(state, payload) {
    const index = state.subscriptionsFiltered.findIndex(i => i.id == payload.id);
    state.subscriptionsFiltered[index].invoiced = payload.value;
  }
}

const actions = {
  async getAdministrationSummaryHTTP({ commit }, { year, month }) {
    const summary = await api.get(`/administration/summary/${year}/${month}`)
    commit('ADMINISTRATION_SUMMARY', summary.data.data)
  },
  async getAdministrationTransactionsHTTP({ commit }, { year, month }) {
    const transactions = await api.get(`/administration/transactions/${year}/${month}`)
    commit('ADMINISTRATION_TRANSACTIONS', transactions.data.data)
    commit('ADMINISTRATION_TRANSACTIONS_FILTERED', transactions.data.data.transactions)
  },
  async getAdministrationSubscriptionsHTTP({ commit }, { year, month }) {
    const subscriptions = await api.get(`/administration/subscriptions/${year}/${month}`)
    commit('ADMINISTRATION_SUBSCRIPTIONS', subscriptions.data.data)
    commit('ADMINISTRATION_SUBSCRIPTIONS_FILTERED', subscriptions.data.data)
  },
  async saveTransaction({ state, dispatch }) {
    console.log(state.transaction)
    if (state.transaction.id == 0) {
      return await dispatch('createTransactionHTTP')
    } else {
      return await dispatch('updateTransactionHTTP')

    }

  },
  async createTransactionHTTP({ state, rootState }) {
    let transaction = state.transaction
    transaction.typeTransaction = state.typeTransaction
    transaction.createdBy = rootState.user.userId


    await api.post('/administration', transaction)
    return 'Transaccion creada'
  },
  async updateTransactionHTTP({ state, rootState }) {
    let transaction = state.transaction
    transaction.typeTransaction = state.typeTransaction
    transaction.updatedBy = rootState.user.userId
    await api.put('/administration', transaction)
    return 'Transaccion actualizada'
  },
  //eslint-disable-next-line
  async setInvoicedHTTP({ state }, id) {
    return await api.put(`/administration/invoiced/${id}`);
  },
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}