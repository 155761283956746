<template>
  <div class="psPatientMedication">
    <div class="row">
      <div class="row rowTitle">Medicación</div>
      <ps-patient-btn-new-medication></ps-patient-btn-new-medication>
    </div>
    <div class="container">
      <div
        class="item"
        v-for="(medication, index) in patient.medications"
        :key="index"
      >
        {{ medication.description }}
        <i class="icon el-icon-edit" @click="editItem(medication)"></i>
      </div>
    </div>
  </div>
</template>

<script>
import PsPatientBtnNewMedication from "./PsPatientBtnNewMedication.vue";
import { mapState, mapMutations } from "vuex";

export default {
  name: "PsPatientMedication",
  components: { PsPatientBtnNewMedication },
  computed: {
    ...mapState("patients", ["patient"])
  },
  methods: {
    ...mapMutations("patients", [
      "PATIENTS_ITEM_MEDICATION",
      "PATIENTS_DIALOG_MEDICATION"
    ]),
    editItem(medication) {
      this.PATIENTS_ITEM_MEDICATION(JSON.parse(JSON.stringify(medication)));
      this.PATIENTS_DIALOG_MEDICATION(true);
    }
  }
};
</script>

<style scoped>
.psPatientMedication {
  background-color: white;
  border-radius: 20px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  width: 48%;
  margin-top: 35px;
}
.rowTitle {
  font-size: 24px;
  font-weight: bold;
  line-height: 35px;
  align-items: center;
}
.week {
  justify-content: space-between;
}
.day {
  font-size: 20px;
  font-weight: 500;
  color: #efaf00;
}
.weight {
  font-size: 16px;
  font-weight: 500;
}
.column {
  height: 55px;
  justify-content: space-between;
}
.week .column {
  align-items: center;
}
.item {
  width: 100%;
  text-align: left;
  padding-bottom: 4px;
  margin-bottom: 8px;
  border-bottom: 1px solid #ccc;
}
.icon {
  line-height: 30px;
  margin-left: 10px;
  cursor: pointer;
  transition: 0.2s ease;
}
.icon:hover {
  font-size: 1.1rem;
  font-weight: bold;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .psPatientMedication {
    width: 100%;
  }
  .patientDiseases,
  .patientEventuals {
    flex-direction: column;
  }
}
</style>
