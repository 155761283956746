<template>
  <div class="psConfigurationOss">
    <ps-upper-bar :site="{ site: 'Configuración - Obras Sociales' }" :backButton="true"></ps-upper-bar>
    <div class="container">
      <div class="row">
        <el-button round plain icon="el-icon-plus" @click="addNewOs" :disabled="newOs">Nueva obra social</el-button>
      </div>
      <div class="row rowData">
        <div class="column w-25">
          <div class="label">Descripcion</div>
          <el-input 
            :value="os.osDescription" 
            @input="changeProperty($event, 'osDescription')" 
            placeholder="Ingrese una descripción"
            :disabled="!newOs"></el-input>
        </div>
        <div class="column w-25">
          <div class="label">CUIT</div>
          <el-input 
            :value="os.osCuit" 
            @input="changeProperty($event, 'osCuit')" 
            placeholder="Ingrese el CUIT"
            :disabled="!newOs"></el-input>
        </div>
        <div class="column">
          <div class="label">Activa</div>
          <el-switch :value="os.active" @input="changeProperty($event, 'active')" :disabled="!newOs"></el-switch>
        </div>
      </div>
      <div class="row rowButtons">
        <el-button round plain type="success" :disabled="!saveDisabled" @click="save">Guardar</el-button>
        <el-button round plain type="danger" :disabled="!newOs" @click="cancel">Cancelar</el-button>
      </div>

      <el-table 
        :data="oss"
        style="width: 800px;" 
        :highlight-current-row="true" 
        size="mini" 
        height="305" 
        empty-text="No hay cuentas ingresadas"
        @cell-dblclick="selectRow">
        <el-table-column prop="osId" label="Código" width="80"  v-if="!isMobile" ></el-table-column>
        <el-table-column prop="osDescription" label="Descripción" :width="isMobile == true ? '' : 200" ></el-table-column>
        <el-table-column prop="osCuit" label="CUIT" :width="isMobile == true ? '' : 150" ></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import PsUpperBar from '@/components/layout/PsUpperBar.vue'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsConfigurationOss',
  components: { PsUpperBar },
  data () {
    return {
      activeNames: [],
      newOs: false
    }
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapState('socialsWorkers', ['oss', 'os', 'errors']),
    saveDisabled () {
      return this.newOs && this.os.osDescription != ''
    }
  },
  methods: {
    ...mapMutations('socialsWorkers', ['OSS_OS', 'OSS_OS_PROP']),
    ...mapActions('socialsWorkers', ['saveOs', 'getOssHTTP']),
    selectRow ( row ) {
      if( row ) {
        this.newOs = true
        this.OSS_OS(JSON.parse(JSON.stringify(row)))
      }
    },
    changeProperty ( value, property ) {
      this.OSS_OS_PROP({property, value})
    },
    addNewOs () {
      this.newOs = true
      this.OSS_OS({})
    },
    cancel () {
      this.newOs = false
      this.OSS_OS({})
    },
    async save () {
      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Guardando...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        }); 

        const message = await this.saveOs()
        if(this.errors.length > 0 ) {
          this.showValidationAlert()
        } else {
          this.cancel()
          this.$message( { message, type: 'success' } )
          this.getOssHTTP()
        }
    } catch ( err ) { this.$message( { message: err.response.data.err.details, type: 'error' })}
      finally { loading.close(); }
    },
    showValidationAlert () {      
      let str = '<p>';
      if(this.errors.length > 0) {
        this.errors.forEach(err => str += `${err}<br>`)
        str = str + '</p>'
        this.$alert(str, '', {confirmButtonText: 'Entendido', type: 'warning', dangerouslyUseHTMLString: true})
      }
    },
  },
  async created () {
    this.OSS_OS({})
    await this.getOssHTTP()
  }
}
</script>

<style scoped>
  .psConfigurationOss { min-height: 90%;}
  .container { background-color: white; border-radius: 20px; padding: 32px; min-height: 90%; height: 100%; overflow-y: scroll;}
  .container::-webkit-scrollbar { display: none}
  .rowData { margin: 40px 0 20px}

  
  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .container { padding: 32px 16px}
    .rowData { flex-direction: column;}
    .rowData .column { margin-right: 0 }
    .rowData .column .el-input-number, .rowData .w-25 { width: 100%; margin-bottom: 8px; }
    .rowButtons { justify-content: space-evenly;}
  }
</style>