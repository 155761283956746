<template>
  <div class="psGroupsDialogPatients">
    <el-dialog :visible.sync="dialogPatients" title="Seleccionar paciente" :before-close="handleClose" :width="isMobile == true ? '90%' : '30%'" >
      <div class="container">
        <div class="patient" v-for="(patient, index) in patients" :key="index" @click="select(patient)">
          {{patient.name | textCapitalize}} {{patient.surname | textCapitalize}} {{patient.nickname ? '(' + patient.nickname + ')' : '' | textCapitalize}}
        </div>        
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'

export default {
  name: 'PsGroupsDialogPatients',
  computed: {
    ...mapState('groups', ['dialogPatients', 'patients']),
    ...mapState(['isMobile']),
  },
  methods: {
    ...mapMutations('groups', ['GROUPS_DIALOG_PATIENTS']),
    ...mapActions('groups', ['selectPatientHTTP']),
    handleClose () {
      this.GROUPS_DIALOG_PATIENTS(false)
    },
    async select ( patient ) {
      console.log(patient)
      await this.selectPatientHTTP(patient)
      this.handleClose()
    },
  }
}
</script>

<style scoped>
  .container { display: flex; flex-direction: column; align-items: center;}
  .patient { 
    cursor: pointer; 
    font-size: 1.1rem; 
    width: 100%; 
    border: 1px solid #eee;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 0px #aaa;
    padding: 8px;
    margin-bottom: 8px
  }

      /* Medium devices (tablets, less than 992px) */
  /* @media (max-width: 991.98px) {
    .container { flex-direction: column; align-items: center;}
  } */
</style>