<template>
  <div class="psPatientDetailUpperBar column">
    <ps-open-menu-button></ps-open-menu-button>
    <div class="row">
      <ps-back-button></ps-back-button>
      <div class="containerSite column">
        <div class="row" style="margin-bottom: 0">          
          <div class="site">{{ site.site }}</div>
          <i :class="icon" class="icon" @click="clickIcon" v-if="isAdmin"></i>
          <!-- <btn-fake-patient></btn-fake-patient> -->
        </div>
        <div class="greeting" v-if="patient.groupDescription != ''">{{patient.groupDescription}}</div>
      </div>
      <ps-avatar class="avatar"></ps-avatar>
    </div>
    
  </div>
</template>

<script>
import PsAvatar from '@/components/layout/PsAvatar.vue'
import PsBackButton from '@/components/PsBackButton.vue'
import PsOpenMenuButton from '@/components/PsOpenMenuButton.vue'
// import BtnFakePatient from './BtnFakePatient.vue'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'PsPatientDetailUpperBar',
  props: {
    site: Object,
    icon: String
  },
  components: { PsAvatar, PsBackButton, PsOpenMenuButton },
  computed: {
    ...mapState('routes', ['routeSite']),
    ...mapState('patients', ['editionMode', 'patient']),
    ...mapState(['user']),
    ...mapGetters(['isAdmin'])
  },
  methods: {
    clickIcon () {
      this.$emit('clickIcon')
    }
  }
}
</script>

<style scoped>
  .psPatientDetailUpperBar { height: 60px;  display: flex; margin-top: 50px; }
  .containerSite { display: flex; flex-direction: column; align-items: flex-start;}
  .site { font-weight: bold; font-size: 1.25rem; }
  .greeting { color: #0290af; font-size: 0.9rem; font-weight: 300;}

  .icon { line-height: 20px; margin-left: 20px; cursor: pointer; transition: 0.2s ease} 
  .icon:hover { font-size: 1.1rem; font-weight: bold;}


    /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .psPatientDetailUpperBar {margin-top: 16px; height: 80px; margin-right: 0;}
    .avatar { display: none; }
    .menu { display: block; align-self: flex-end;}
  }
</style>