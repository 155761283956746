<template>
  <div class="psAdministrationSubscriptions">
    <ps-upper-bar :site="{ site: 'Administración' }" :back-button="true"></ps-upper-bar>

    <div class="containerTransactions">
      
      <div class="row rowDate">
        <span class="title">Suscripciones</span>
        <div>
          <div class="column w-60">
            <div class="label" style="margin-bottom: 8px;">Mes</div>
            <el-date-picker 
              type="month" 
              placeholder="Seleccionar un mes" 
              :value="monthSelected" 
              @input="selectMonth($event)"
              :format="'MM/yyyy'"></el-date-picker>
          </div>
          <div class="column w-10">
            <div class="label"  style="margin-bottom: 8px;">&nbsp;</div>
            <el-button round type="primary" @click="filterData">Filtrar</el-button>
          </div>
        </div>
      </div>     

      <ps-administration-subscriptions-filters></ps-administration-subscriptions-filters>

      <ps-administration-subscriptions-list></ps-administration-subscriptions-list>

      <template>
              <el-backtop target=".psAdministrationSubscriptions" :visibility-height="300">
        <div
          style="{
            height: 100%;
            width: 100%;
            background-color: #60E2FF;
            box-shadow: 0 0 6px rgba(0,0,0, .12);
            text-align: center;
            line-height: 40px;
            color: #333;
            border-radius: 50%
          }"
        > <i class="el-icon-arrow-up"></i></div>
      </el-backtop>
      </template>

    </div>
  </div>
</template>

<script>
import PsUpperBar from '@/components/layout/PsUpperBar.vue'
import PsAdministrationSubscriptionsFilters from '@/views/administration/pages/subscriptions/widgets/PsAdministrationSubscriptionsFilters.vue'
import PsAdministrationSubscriptionsList from '@/views/administration/pages/subscriptions/widgets/PsAdministrationSubscriptionsList.vue'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'psAdministrationSubscriptions',
  components: { PsUpperBar, PsAdministrationSubscriptionsFilters, PsAdministrationSubscriptionsList },
  data () {
    return {
      configIncomings: {
        amount: {
          color: 'white',
          'background-color': '#3E8091',  
        },
        labelIndicator: {
          text: 'Ingresos',
          color: 'black'
        },
      },
      configExpenses: {
        amount: {
          color: '#3E8091',
          'background-color': 'white',   
        },
        labelIndicator: {
          text: 'Egresos',
          color: 'black'
        },
      },
    }
  },
  computed: {
    ...mapState('administration', ['monthSelected', 'transactions']),
    ...mapState(['isMobile']),
    isMilagros () {
      return this.$route.params['userId'] == 3 ? true : false 
    },
    isSofia () {
      return this.$route.params['userId'] == 4 ? true : false
    },
    isValidUser () {
      return this.isMilagros || this.isSofia ? true : false
    }
  },
  methods: {
    ...mapMutations('administration', ['ADMINISTRATION_MONTH_SELECTED']),
    ...mapActions('administration', ['getAdministrationSubscriptionsHTTP']),
    ...mapActions('accounts', ['getAccountsHTTP']),
    ...mapActions('rates', ['getRatesHTTP']),
    ...mapActions('waysToPay', ['getWaysToPayHTTP']),
    selectMonth ( month ) {
      console.log(month)
      this.ADMINISTRATION_MONTH_SELECTED(month)
    },    
    async filterData () {
      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Filtrando mes seleccionado...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        let year = this.monthSelected.getFullYear()
        let month = this.monthSelected.getMonth() + 1
        await this.getAdministrationSubscriptionsHTTP( { year, month } )
      } catch ( err ) { this.$message( { message: 'Error al buscar la informacion. Inténtelo nuevamente', type: 'danger'})}
      finally { loading.close()}
    }
  },
  async created () {
    let today = new Date()
    let year = today.getFullYear()
    let month = today.getMonth() + 1
    await this.getAdministrationSubscriptionsHTTP( { year, month })
    await this.getAccountsHTTP()
    await this.getRatesHTTP()
    await this.getWaysToPayHTTP()
  }
}
</script>

<style scoped>
  .psAdministrationSubscriptions { overflow-y: scroll; height: 100%; }
  .psAdministrationSubscriptions::-webkit-scrollbar { display: none; }

  .rowDate, .rowDate > div { display: flex}
  /* .rowDate { line-height: 20px;} */
  .containerTransactions { 
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
    background-color: white; 
    padding: 25px; 
    border-radius: 20px;  
    margin-top: 10px;
    box-shadow: 0px 1px 2px 1px #bbb;
    margin-bottom: 30px;
    width: 100%;
  }

  .title { font-size: 1.25rem; line-height: 5rem; font-weight: bold; text-align: left; align-self: flex-start; margin-right: 50px;}
  


    /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .tooltip { display: none;}
    .rowDate{ flex-direction: column;}    
    .rowDate > div { display: flex; width: 100%}
    .rowDate .w-20 { width: 50%}
    .psIndicatorNumber { min-width: 250px;}
  }

</style>