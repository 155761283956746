import api from "../services/api"
import { addProperyIfNotExists, formatDDMMYYY } from "../services/utils"
import axios from 'axios'
import { saveAs } from 'file-saver'

const state = {
  group: {},
  newGroup: {
    date: '',
    groupId: 0,
    groupDescription: '',
    groupPathImage: '',
    patients: [],
    phaseId: '',
    phaseDescription: '',
    active: true,
    createdBy: 0,
    updatedBy: 0,
    createdAt: '',
    updatedAt: ''
  },
  groups: [],
  errors: [],
  imageGroup: '',
  dialogCropImage: false,
  editionMode: false,
  dialogSelectDate: false,
  selectedDate: null,
  patients: [],
  dialogPatients: false
}

const mutations = {
  GROUPS_GROUPS(state, groups) { state.groups = groups },
  GROUPS_EMPTY_GROUPS(state) { state.groups = [] },
  GROUPS_GROUP(state, group) { state.group = Object.assign({}, addProperyIfNotExists(group, state.newGroup)) },
  GROUPS_GROUP_PROP(state, payload) { state.group[payload.property] = payload.value },
  GROUPS_GROUP_PATIENT_PROP(state, { property, value, index }) { state.group.patients[index][property] = value },
  GROUPS_EMPTY_ERRORS(state) { state.errors = [] },
  GROUPS_ADD_ERROR(state, error) { state.errors.push(error) },
  GROUPS_IMAGE_GROUP(state, imageGroup) { state.imageGroup = imageGroup },
  GROUPS_DIALOG_CROP_IMAGE(state, dialogCropImage) { state.dialogCropImage = dialogCropImage },
  GROUPS_EDITION_MODE(state, editionMode) { state.editionMode = editionMode },
  GROUPS_DIALOG_SELECT_DATE(state, dialogSelectDate) { state.dialogSelectDate = dialogSelectDate },
  GROUPS_SELECTED_DATE(state, selectedDate) { state.selectedDate = selectedDate },
  GROUPS_PATIENTS(state, patients) { state.patients = patients },
  GROUPS_DIALOG_PATIENTS(state, dialogPatients) { state.dialogPatients = dialogPatients }
}

const actions = {
  async getGroupsHTTP({ commit }, payload) {
    let url = `/groups/${payload.page}`

    if (payload.filter && payload.filter != '') {
      url = `${url}/${payload.filter}`
    }
    const groups = await api.get(url)
    commit('GROUPS_GROUPS', groups.data.data)
  },
  async getGroupHTTP({ state, commit, rootState }, groupId) {

    if (state.selectedDate == null) {
      commit('GROUPS_SELECTED_DATE', formatDDMMYYY(new Date()))
    }


    let userId = rootState.user.userId
    let date = formatDDMMYYY(state.selectedDate)

    let result = await api.get(`/groups/one/${groupId}/${date.replaceAll('/', '')}/${userId}`)

    console.log(result.data.data)

    commit('GROUPS_GROUP', {})
    commit('GROUPS_GROUP', result.data.data)
  },
  //eslint-disable-next-line
  async getPatientsGroupHTTP({ state, commit }, groupId) {

    let result = await api.get(`/groups/patientsGroup/${groupId}`)

    console.log(result.data.data)

    commit('GROUPS_PATIENTS', {})
    commit('GROUPS_PATIENTS', result.data.data)
  },
  async selectPatientHTTP({ state, rootState }, patient) {

    let userId = rootState.user.userId
    let date = formatDDMMYYY(state.selectedDate).split('/').join('')

    patient.userId = userId
    patient.date = date
    patient.groupId = state.group.groupId
    await api.post('/groups/createWeight', patient)
  },
  async saveAccount({ state, commit, dispatch }) {
    commit('GROUPS_EMPTY_ERRORS')
    if (state.group.groupDescription == '') commit('GROUPS_ADD_ERROR', 'La descripción del grupo es requerida')

    if (state.errors.length == 0) {
      if (state.group.groupId == 0) {
        return await dispatch('createGroupHTTP')
      } else {
        return await dispatch('updateGroupHTTP')
      }
    }
  },
  async createGroupHTTP({ state, rootState }) {
    let group = state.group
    group.createdBy = rootState.user.userId
    await api.post('/groups', group)
    return 'Grupo creado'
  },
  async updateGroupHTTP({ state, rootState }) {
    let group = state.group
    group.createdBy = rootState.user.userId
    group.updatedBy = rootState.user.userId
    await api.put('/groups', group)
    return 'Grupo actualizado'
  },
  async uploadImageGroupHTTP({ commit }, file) {
    var formData = new FormData()
    formData.append('file', file.file)
    formData.append('upload_preset', 'a3cu9uah')
    axios({
      method: 'POST',
      url: `https://api.cloudinary.com/v1_1/planadev/upload`,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: formData
    })
      .then(data => {
        commit('GROUPS_GROUP_PROP', { property: 'groupPathImage', value: data.data.secure_url })
      })
      .catch(err => { alert({ err }) })
  },
  async saveGroup({ state, commit, dispatch }) {
    const group = Object.assign({}, state.group)
    commit('GROUPS_EMPTY_ERRORS')

    if (!group.groupDescription || group.groupDescription == '') commit('GROUPS_ADD_ERROR', 'Debe ingresar el nombre del grupo')

    if (state.errors.length == 0) {
      if (state.group.groupId == 0) {
        return await dispatch('createGroupHTTP')
      } else {
        return await dispatch('updateGroupHTTP')
      }
    }
  },
  async updateStatusPictureHTTP({ rootState }, payload) {
    payload.userId = rootState.user.userId

    await api.post('/groups/updateStatusPicture', payload)
  },
  async updateWeightHTTP({ rootState }, payload) {
    payload.userId = rootState.user.userId
    payload.date = formatDDMMYYY(state.selectedDate).split('/').join('')

    await api.post('/groups/updateWeight', payload)
  },
  async updateTableInformationHTTP({ rootState }, payload) {
    payload.userId = rootState.user.userId
    await api.post('/groups/updateTableInformation', payload)
  },
  async showWeekSummaryHTTP({ state }) {
    let selectedDate = state.selectedDate
    let to = new Date(selectedDate.getTime())
    to.setDate(to.getDate() - 7)
    let filename = `Grupo ${state.group.groupDescription} - ${formatDDMMYYY(to).split('/').join('-')} al ${formatDDMMYYY(selectedDate).replaceAll('/', '-')}.pdf`
    let blob = await api.post(`/groups/weekSummary`, {
      phaseId: state.group.phaseId,
      groupId: state.group.groupId,
      groupDescription: state.group.groupDescription,
      date: formatDDMMYYY(selectedDate).split('/').join(''),
      filename
    }, { responseType: 'blob' })
    const pdfBlob = new Blob([blob.data], { type: 'application/pdf' })
    saveAs(pdfBlob, filename)
  }

}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}