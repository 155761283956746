<template>
  <el-dropdown class="avatar">
    <span class="avatarSesion">
      <i class="el-icon-user"></i>
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item>
        <div class="column">
          <div class="username">Usuario: {{user.username}}</div>
        </div>
      </el-dropdown-item>
      <el-dropdown-item>
        <el-button @click="closeUserSession">Cerrar sesión</el-button>
      </el-dropdown-item>
      <el-dropdown-item :disabled="true">
        <p class="version">v 1.0.5</p>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>  
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'PsAvatar',
  methods: {
    ...mapMutations(['LOGOUT']),
    errorHandler () {
      return true
    },
    closeUserSession () {
      this.LOGOUT()
      this.$router.replace('/')
    }
  },
  computed: {
    ...mapState(['user']),
  }
}
</script>

<style scoped>
  .avatar, .avatarSesion { display: flex; align-items: center;}
  .avatar { 
    position: absolute; 
    right: 0; 
    width: 110px; 
    cursor: pointer; 
    margin-top: 2px; 
    margin-right: 15px; 
  }
  .avatarSesion { height: 40px; width: 40px; background-color: #C4C4C4; border-radius: 25px; font-size: 28px; display: flex; align-items: center; justify-content: center;}
  .username { width: 130px; text-align: left; font-family: 'Roboto', sans-serif; }
  .version { text-align: center}
</style>