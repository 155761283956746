<template>
  <div class="psAdministrationSubscriptionsList">
    <div class="rowTotal">
      <div class="date">&nbsp;</div>
      <div class="concept">&nbsp;</div>
      <div class="type">&nbsp;</div>
      <div>
        <div class="entity">MONTO SUMADO ($)</div>
        <div class="total">&nbsp;&nbsp;{{(subscriptionsFiltered.reduce( (acc, curr) => { acc += Number(curr.total); return acc; } , 0)).toFixed(2) | money}}</div>
      </div>
      <div class="wayToPay">&nbsp;&nbsp;{{subscriptionsFiltered.length}} suscripciones</div>
      <div class="account">&nbsp;</div>
    </div>


    <div class="rowTitles">
      <div class="patient">PACIENTE</div>
      <div class="rate">TARIFA</div>
      <div class="total">TOTAL ($)</div>
      <div class="state">ESTADO</div>
      <div class="wayToPay">MEDIO DE PAGO</div>
      <div class="account">CUENTA</div>
      <div class="invoice">FACTURA</div>
    </div>

    <div class="transaction" v-for="(subscription, index) in subscriptionsFiltered" :key="index" >
      <div class="patient patientLink" @click="selectPatient(subscription.patientId)">{{subscription.name | textCapitalize}} {{subscription.surname | textCapitalize}} {{subscription.nickname ? '(' + subscription.nickname + ')' : '' | textCapitalize}}</div>
      <div class="rate">{{subscription.rateDescription | textCapitalize}}</div>
      <div class="total">{{subscription.total | money}}</div>
      <div class="state">{{stateDescription(subscription.statusPaidOut) | textCapitalize}}</div>
      <div class="state stateMobile" :class="stateColor(subscription.statusPaidOut)"></div>
      <div class="wayToPay">{{subscription.wayToPayDescription | textCapitalize}}</div>
      <div class="account">{{subscription.accountDescription | textCapitalize}}</div>
      <div class="invoice">{{subscription.takesInvoice == true ? 'Si' : ''}} 
        <template v-if="subscription.takesInvoice == true">
          -
          <el-button 
            size="mini" 
            plain
            @click="confirmInvoiced(subscription)"
            :icon="subscription.invoiced == 1 ? 'el-icon-delete' : 'el-icon-check' "
            :type="subscription.invoiced == 1 ? 'danger' : 'success'"          
            ></el-button>
        </template> 
      </div>
    </div>


  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'psAdministrationSubscriptionsList',
  computed: {
    ...mapState('administration', ['subscriptionsFiltered', 'statesSubscriptions']),
  },
  methods: {
    ...mapMutations('administration', ['ADMINISTRATION_SET_INVOICED']),
    ...mapActions('administration', [ 'setInvoicedHTTP']),
    stateDescription ( id ) {
      const state = this.statesSubscriptions.find( s => s.id == id)
      return state.description
    },
    selectPatient(patientId) {
      this.$router.push(`/patients/detail/${patientId}`)
    },
    stateColor ( state ) {
      let clase = ''

      if(state == 3) {
        clase = 'pagado'
      } else if ( state == 2) {
        clase = 'parcial'
      } else if ( state == 1 ) {
        clase = 'pendiente'
      } else {
        clase = 'cancelada'
      }
      return clase
    },
    async confirmInvoiced(subscription) {
      const result = await this.setInvoicedHTTP(subscription.id);
      this.ADMINISTRATION_SET_INVOICED({id: subscription.id, value: result.data.data.invoiced})
    }
  }
}
</script>

<style scoped>
  .psAdministrationTransactionsList { display: flex; flex-direction: column; }
  .rowTitles, .rowTotal { display: flex; margin: 25px 0 10px ; color: #3E8091; font-size: 13px; font-weight: bold; text-align: left; padding-left: 12px}
  .rowTotal { font-size: 14px; font-size: 0.8rem; flex-direction: row;  margin-left: 320px}

  .rowTotal > div { display: flex;}
  .rowTotal .entity, .rowTotal .total { background-color: #60E2FF; color: #333; padding: 8px 0}
  
  .rowTotal .entity { border-top-left-radius: 8px; border-bottom-left-radius: 8px; padding-left: 16px }
  .rowTotal .wayToPay { background-color: #3E8091; color: white; line-height: 32px; border-top-right-radius: 8px; border-bottom-right-radius: 8px; padding-right: 6px; padding-left: 12px}
  
  .patient, .rate { width: 250px}
  .total { width: 130px}
  .state, .wayToPay, .account { width: 160px }

  .stateMobile { display: none }

  .transaction { 
    display: flex; 
    padding: 12px; 
    box-shadow: 0px 1px 2px 1px #bbb; 
    border-radius: 8px; 
    margin-bottom: 8px; 
    font-size: 14px; 
    text-align: left;
    line-height: 23px;
  }

  .patientLink { cursor: pointer; }

  .patientLink:hover { font-weight: bold;}

  @media (max-width: 991.98px) {
    .rowTotal > .date, .rowTotal > .concept, .rowTotal > .type, .rowTotal > .account { display: none }
    .rowTotal { font-size: 0.8rem; flex-direction: column; margin-left: 0 }

    .rowTotal .total { border-top-right-radius: 8px; border-bottom-right-radius: 8px}
    .rowTotal .wayToPay { border-radius: 8px; margin-top: 8px}

    .rowTitles, .transaction { font-size: 0.75rem;}

    
    .rowTitles > .rate, .rowTitles > .state, .rowTitles > .entity, .rowTitles > .invoice { display: none; }
    .transaction > .rate, .transaction > .tstateype, .transaction > .entity, .transaction > .invoice { display: none; }

    .rowTitles > .total, .transaction > .total, .rowTitles > .patient, .transaction > .patient { width: 110px}
    .rowTitles > .wayToPay, .transaction > .wayToPay { width: 160px}

    .rowTitles > .account, .transaction > .account { width: 120px}

    .pagado { background-color: rgb(2,218,2) ;}
    .parcial { background-color: #c9aa04;}
    .pendiente { background-color: red;}
    .cancelada { background-color: #870101;}

    .transaction > .state { display: none}
    .transaction > .state.stateMobile { display: block; width: 12px; height: 12px; border-radius: 6px; margin-right: 12px;}
  }
</style>