<template>
  <div class="psIndicatorNumber">
    <div class="measure" :style="config.measure">
      <div 
        v-if="editionMode == false || config.editValue == false"
        class="value" 
        :class=" { variation: config.measure.variation }">
          <span v-if="config.measure.variation">{{config.measure.variationType == 'positive' ? '+' : '-'}}</span>
          {{value || 0}} {{unit}}
      </div>
      <input v-else :value="patient[config.property]" :type="config.inputType" class="inputValue" min="0" @input="changeProperty($event)"/>
    </div>
    <div class="labelIndicator" :style="config.labelIndicator">{{labelIndicator}}</div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'PsIndicatorNumber',
  props: [ 'config', 'value', 'unit', 'labelIndicator' ],
  computed: {
    ...mapState('patients', ['patient', 'editionMode'])
  },
  methods: {
    ...mapMutations('patients', ['PATIENTS_PATIENT_PROP']),
    changeProperty ( value ) {
      this.PATIENTS_PATIENT_PROP({property: this.config.property, value: value.target.value})
    }
  }
}
</script>

<style scoped>
  .psIndicatorNumber { min-width: 200px; width: 12%;}
  .measure { height: 100px; display: flex; justify-content: center; align-items: center; border-radius: 8px; position: relative}
  .value { font-size: 30px; font-weight: 900;  }
  .variation { font-size: 37px; font-weight: 500 ;}
  .labelIndicator { font-size: 15px; font-weight: bold; margin-top: 8px;}
  .editIcon { font-size: 1.25rem; position: absolute; top: 6px; right: 6px; transition: 0.2s ease; cursor: pointer}
  .editIcon:hover { font-size: 1.35rem;}
  input { border: none; border-bottom: 2px solid white; padding: 0 0 5px; width: 100%;  color: white; font-weight: bold; background-color: transparent; font-size: 30px; margin: 0 30px; text-align: center; font-family: 'Roboto', sans-serif;}
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input.inputValue:focus { outline: none; border-bottom: 3px solid white ;}

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

        /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .psIndicatorNumber { min-width: 140px; width: 45%; margin-bottom: 16px;}
    .measure { height: 80px; }
    .value { font-size: 30px; }
    .variation { font-size: 25px; }
    .labelIndicator { font-size: 13px;}
    input { font-size:  30px;}
    
  }
</style>