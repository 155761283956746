<template>
  <div class="psPatientBtnNewEventual">
    <el-button circle style="border: 2px solid black; margin: 4px 0; color: black; font-weight: bold;" size="mini" @click="newEventual">
      <i class="el-icon-plus" style="font-weight: 900"></i>
    </el-button>
    <span class="tooltip">Nueva información eventual</span>    
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsPatientBtnNewEventual',
  computed: {
    ...mapState('patients', ['patient'])
  },
  methods: {
    ...mapMutations('patients', ['PATIENTS_DIALOG_EVENTUALS', 'PATIENTS_LOADING', 'PATIENTS_ITEM_EVENTUAL']),
    ...mapActions('eventuals', ['getEventualsHTTP']),
    async newEventual () {
      this.PATIENTS_LOADING(true)
      this.PATIENTS_DIALOG_EVENTUALS(true)
      await this.getEventualsHTTP()
      this.PATIENTS_ITEM_EVENTUAL({})
      this.PATIENTS_LOADING(false)
    }
  }
}
</script>

<style scoped>
  .psPatientBtnNewEventual { position: relative; margin-left: 15px;}
  .tooltip { 
    visibility: hidden;
    width: 180px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    text-align: center;
    padding: 4px 0;
    border-radius: 12px;
    position: absolute;
    z-index: 10;
    font-size: 0.8rem;
    left: 5px;
    top: -30px
  }

  .psPatientBtnNewEventual:hover .tooltip {
    visibility: visible;
  }

  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .tooltip { display: none;}    
  }
</style>