<template>
  <div class="psGroupsListSearchBar">
    <div class="row">
      <input type="text" placeholder="Buscar grupo" class="searchBar" v-model="filter">
      <button class="btnSearch" @click="search"><i class="el-icon-search"></i></button>
    </div>
    <el-button icon="el-icon-close" circle plain @click="deleteFilter" round type="danger" class="btnDeleteFilter" :disabled="filter == ''"></el-button>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsGroupsListSearchBar',
  data () {
    return {
      filter: ''
    }
  },
  methods: {
    ...mapMutations('groups', ['GROUPS_EMPTY_GROUPS']),
    ...mapActions('groups', ['getGroupsHTTP']),
    search () {
      if(this.filter && this.filter != '') {
        
        this.GROUPS_EMPTY_GROUPS()
        this.getGroupsHTTP({page: 1, filter: this.filter})
      }
    },
    deleteFilter () {
      this.filter = ''
      this.GROUPS_EMPTY_GROUPS()
      this.getGroupsHTTP({page: 1})
    }
  }
}
</script>

<style scoped>
  .psGroupsListSearchBar { display: flex;}
  .psGroupsListSearchBar .row { display: flex; align-items: center; }

  .searchBar { 
    width: 260px;
    padding: 0 15px;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
    border: 1px solid #ddd;
    color: #444;
    font-family: 'Roboto', sans-serif;
  }

  .searchBar:focus-visible {
    outline: 1px solid #666
  }

  .btnSearch { 
    border: none; 
    font-size: 1.15rem; 
    height: 26px; 
    line-height: 28px; 
    color: #ddd; 
    position: relative; 
    left: -30px; 
    background-color: white;
    cursor: pointer;
    transition: 0.2s ease; }
  
  .btnSearch:hover { font-weight: bold; color: black}
  .btnDeleteFilter { width: 36px; height: 36px; display: flex; justify-content: center; align-items: center;}
  
  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .searchBar { width: 200px;}
  }

</style>