<template>
  <div class="psPatientsListSearchBar">
    <div class="row">
      <input type="text" placeholder="Buscar paciente" class="searchBar" @input="changeFilter($event)" :value="filter" @keyup.enter="search">
      <button class="btnSearch" @click="search"><i class="el-icon-search"></i></button>
    </div>
    <el-button icon="el-icon-close" circle plain @click="deleteFilter" round type="danger" class="btnDeleteFilter" :disabled="filter == ''"></el-button>
    <el-button icon="el-icon-search" rounded plain type="primary" @click="patientsWithoutGroup" round class="btnPatientsWithoutGroupFilter" size="mini" >Buscar pacientes sin grupo</el-button>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsPatientListSearchBar',
  computed: {
    ...mapState('patients', ['filter']),
  },
  methods: {
    ...mapMutations('patients', ['PATIENTS_EMPTY_PATIENTS', 'PATIENTS_FILTER']),
    ...mapActions('patients', ['getPatientsHTTP']),
    changeFilter ( value ) {
      this.PATIENTS_FILTER(value.target.value)

    },
    search () {
      if(this.filter && this.filter != '') {
        this.PATIENTS_EMPTY_PATIENTS()
        this.getPatientsHTTP({page: 1})
      }
    },
    deleteFilter () {
      this.PATIENTS_FILTER('')
      this.PATIENTS_EMPTY_PATIENTS()
      this.getPatientsHTTP({page: 1})
    },
    patientsWithoutGroup () {
      this.PATIENTS_FILTER('Sin grupo')
      this.PATIENTS_EMPTY_PATIENTS()
      this.getPatientsHTTP({page: 1})
    }
  }
}
</script>

<style scoped>
  .psPatientsListSearchBar { display: flex;}
  .psPatientsListSearchBar .row { display: flex; align-items: center; }

  .searchBar { 
    width: 260px;
    padding: 0 15px;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
    border: 1px solid #ddd;
    color: #444;
    font-family: 'Roboto', sans-serif;
  }

  .searchBar:focus-visible {
    outline: 1px solid #666
  }

  .btnSearch { 
    border: none; 
    font-size: 1.15rem; 
    height: 26px; 
    line-height: 28px; 
    color: #ddd; 
    position: relative; 
    left: -30px; 
    background-color: white;
    cursor: pointer;
    transition: 0.2s ease; }
  
  .btnSearch:hover { font-weight: bold; color: black}
  .btnDeleteFilter { width: 36px; height: 36px; display: flex; justify-content: center; align-items: center;}
  .btnPatientsWithoutGroupFilter { height: 36px; margin-left: 100px ;}
  
  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .searchBar { width: 200px;}
  }

</style>