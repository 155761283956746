<template>
  <div class="psPageNotFound">
    pagina no encontrada
  </div>
</template>

<script>
export default {
  name: 'PsPageNotFound',
}
</script>

<style scoped>

</style>