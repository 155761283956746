<template>
  <div class="PsPatientGeneralInformation">
    <div class="row">
      <div class="row rowTitle">Información general</div>
      <ps-patient-btn-new-general-information></ps-patient-btn-new-general-information>
    </div>
    <div class="container">
      <div
        class="item"
        v-for="(generalInformation, index) in patient.generalInformation"
        :key="index"
      >
        {{ generalInformation.description }}
        <i class="icon el-icon-edit" @click="editItem(generalInformation)"></i>
      </div>
    </div>
  </div>
</template>

<script>
import PsPatientBtnNewGeneralInformation from "./PsPatientBtnNewGeneralInformation.vue";
import { mapState, mapMutations } from "vuex";

export default {
  name: "PsPatientGeneralInformation",
  components: { PsPatientBtnNewGeneralInformation },
  computed: {
    ...mapState("patients", ["patient"])
  },
  methods: {
    ...mapMutations("patients", [
      "PATIENTS_ITEM_GENERAL_INFORMATION",
      "PATIENTS_DIALOG_GENERAL_INFORMATION"
    ]),
    editItem(generalInformation) {
      this.PATIENTS_ITEM_GENERAL_INFORMATION(
        JSON.parse(JSON.stringify(generalInformation))
      );
      this.PATIENTS_DIALOG_GENERAL_INFORMATION(true);
    }
  }
};
</script>

<style scoped>
.PsPatientGeneralInformation {
  background-color: white;
  border-radius: 20px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 35px;
  min-height: 200px;
  max-height: 400px;
  overflow: auto;
}
.rowTitle {
  font-size: 24px;
  font-weight: bold;
  line-height: 35px;
  align-items: center;
}
.week {
  justify-content: space-between;
}
.day {
  font-size: 20px;
  font-weight: 500;
  color: #efaf00;
}
.weight {
  font-size: 16px;
  font-weight: 500;
}
.column {
  height: 55px;
  justify-content: space-between;
}
.week .column {
  align-items: center;
}
.item {
  width: 100%;
  text-align: left;
  padding-bottom: 4px;
  margin-bottom: 8px;
  word-wrap: break-word;
  border-bottom: 1px solid #ccc;
}
.icon {
  line-height: 30px;
  margin-left: 10px;
  cursor: pointer;
  transition: 0.2s ease;
}
.icon:hover {
  font-size: 1.1rem;
  font-weight: bold;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .PsPatientGeneralInformation {
    width: 100%;
  }
}
</style>
