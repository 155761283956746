<template>
  <div class="psGroups view">
    <ps-page-container :site="{ site: 'Grupos' }"></ps-page-container>
    <ps-groups-dialog-crop-image></ps-groups-dialog-crop-image>
    <ps-groups-dialog-select-date></ps-groups-dialog-select-date>
    <ps-groups-dialog-patients></ps-groups-dialog-patients>
  </div>
</template>

<script>
import PsPageContainer from '@/components/layout/PsPageContainer.vue'
import PsGroupsDialogCropImage from './widgets/PsGroupsDialogCropImage.vue'
import PsGroupsDialogSelectDate from './widgets/PsGroupsDialogSelectDate.vue'
import PsGroupsDialogPatients from './widgets/PsGroupsDialogPatients.vue'

export default {
  name: 'PsGroups',
  components: { PsPageContainer, PsGroupsDialogCropImage, PsGroupsDialogSelectDate, PsGroupsDialogPatients }
}
</script>

<style scoped>

</style>