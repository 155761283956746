<template>
  <div class="psGroupsDialogSelectDate">
    <el-dialog :visible.sync="dialogSelectDate" title="" :before-close="handleClose" :width="isMobile == true ? '90%' : '30%'" >
      <div class="container">
        <div class="column columnDate">
          <div class="label">Seleccionar fecha</div>
          <el-date-picker 
              :value="selectedDate" 
              type="date" 
              placeholder="Seleccione una fecha" 
              format="dd/MM/yyyy"
              :picker-options="pickerOptionsDate" 
              @input="selectDate"
              class="selectedDate"
              :clearable="false"
              ></el-date-picker>
        </div>
        <div class="row rowMove">
          <el-button circle class="btnMove" size="mini" @click="moveTo(-7)">-1S</el-button>
          <el-button circle class="btnMove" size="mini" @click="moveTo(-1)">-1D</el-button>
          <el-button class="btnMove today" size="mini" @click="moveTo()">HOY</el-button>            
          <el-button circle class="btnMove" size="mini" @click="moveTo(+1)" :disabled="disabledBtnMove(+1)">+1D</el-button>
          <el-button circle class="btnMove" size="mini" @click="moveTo(+7)" :disabled="disabledBtnMove(+7)">+1S</el-button>
        </div>
        <div class="column">
          <div class="label">&nbsp;</div>
          <el-button @click="select" type="success">Seleccionar</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'

export default {
  name: 'PsGroupsDialogCropImage',
  computed: {
    ...mapState('groups', ['dialogSelectDate', 'selectedDate']),
    ...mapState(['isMobile']),
    

  },
  data () {
    return {
      pickerOptionsDate: {
        disabledDate() {
          // return time ? time.getTime() > Date.now() : true;
          return false
        },
      },
      today: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0).getTime()
    }
  },
  methods: {
    ...mapMutations('groups', ['GROUPS_DIALOG_SELECT_DATE', 'GROUPS_SELECTED_DATE']),
    ...mapActions('groups', ['getGroupHTTP']),
    handleClose () {
      this.GROUPS_DIALOG_SELECT_DATE(false)
    },
    selectDate ( value ) {
      this.GROUPS_SELECTED_DATE(value)
    },
    async select () {
      await this.getGroupHTTP(this.$route.params['groupId'])
      this.handleClose()
    },
    moveTo ( days ) {
      let present = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), 0, 0, 0).getTime()
      let date 

      if ( days == undefined ) {
        date = this.today
      } else {
        date = present + ( days * 1000 * 60 * 60 * 24 )
      }
      
      this.GROUPS_SELECTED_DATE(new Date(date))
      this.select()
    },
    //eslint-disable-next-line
    disabledBtnMove ( days = 0 ) {
      return this.selectedDate ? this.selectedDate.getTime() + days * 1000 * 60 * 60 * 24 > this.today ? true : false : true
    }
  }
}
</script>

<style scoped>
  .container { display: flex; flex-direction: column; align-items: center;}
  .columnDate { width: 125px; margin: 0;}
  .columnDate > .label { width: 100%; text-align: center;}
  .selectedDate { display: flex; justify-content: center; }
  .selectedDate /deep/ .el-input__inner { padding-right: 0 }
  .rowMove { align-items: center; margin-top: 15px; width: 100%; justify-content: space-evenly; }  
  .btnMove { width: 40px; height: 40px;}
  .rowMove /deep/ .el-button { margin-left: 0}
  .btnMove.today { width: 60px; margin: 0 20px}

      /* Medium devices (tablets, less than 992px) */
  /* @media (max-width: 991.98px) {
    .container { flex-direction: column; align-items: center;}
  } */
</style>