import api from "../services/api"
import {addProperyIfNotExists} from "../services/utils"

const state = {
  phase: {},
  newPhase: {
    phaseId: 0,
    phaseDescription: '',
    active: true,
    userId: 0,
    user: '',
    createdBy: 0,
    updatedBy: 0,
    createdAt: '',
    updatedAt: ''
  },
  phases: [],
  errors: []
}

const mutations = {
  PHASES_PHASES ( state, phases ) { state.phases = phases },
  PHASES_PHASE ( state, phase ) { state.phase = addProperyIfNotExists(phase, state.newPhase) },
  PHASES_PHASE_PROP ( state, payload ) { state.phase[payload.property] = payload.value },
  PHASES_EMPTY_ERRORS ( state ) { state.errors = [] },
  PHASES_ADD_ERROR ( state, error ) { state.errors.push(error)}
}

const actions = {
  async getPhasesHTTP ( { commit }) {
    const phases = await api.get('/configuration/phases')
    commit('PHASES_PHASES', phases.data.data)
  },
  async savePhase ( { state, commit, dispatch } ) {
    commit('PHASES_EMPTY_ERRORS')
    if( state.phase.phaseDescription == '') commit('PHASES_ADD_ERROR', 'La descripción de la fase es requerida')

    
    if( state.errors.length == 0 ) {
      if( state.phase.phaseId == 0 ) {
        
        return await dispatch('createPhaseHTTP')
      } else {
        return await dispatch('updatePhaseHTTP')
      }
    }
  },
  async createPhaseHTTP ( { state, rootState } ) {
    
    let phase = state.phase
    phase.createdBy = rootState.user.userId
    await api.post('/configuration/phases', phase )
    return 'Fase creada'
  },
  async updatePhaseHTTP ( { state, rootState } ) {
    let phase = state.phase
    phase.createdBy = rootState.user.userId
    phase.updatedBy = rootState.user.userId
    await api.put('/configuration/phases', phase )
    return 'Fase actualizada'
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}