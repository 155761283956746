import api from "../services/api"
import {addProperyIfNotExists} from "../services/utils"

const state = {
  calendarItem: {},
  newCalendarItem: {
    calendarId: 0,
    calendarType: 0,
    patientId: 0,
    patient: '',
    calendarDescription: '',
    calendarDate: null,
    calendarDateString: '',
    calendarTypeDescription: '',
    calendarWeekDay: '',
    createdAt: null,
    updatedAt: null,
    createdBy: null,
    updatedBy: null
  },
  calendar: [],
  errors: [],
  newEventDialogVisible: false,
  calendarType: []
}

const mutations = {
  CALENDAR_CALENDAR ( state, calendar ) { state.calendar = calendar },
  CALENDAR_CALENDAR_ITEM ( state, calendarItem ) { state.calendarItem = addProperyIfNotExists(calendarItem, state.newCalendarItem) },
  CALENDAR_CALENDAR_ITEM_PROP ( state, payload ) { state.calendarItem[payload.property] = payload.value },
  CALENDAR_EMPTY_ERRORS ( state ) { state.errors = [] },
  CALENDAR_ADD_ERROR ( state, error ) { state.errors.push(error)},
  CALENDAR_NEW_EVENT_DIALOG_VISIBLE ( state, newEventDialogVisible ) { state.newEventDialogVisible = newEventDialogVisible },
  CALENDAR_CALENDAR_TYPES ( state, calendarTypes ) { state.calendarTypes = calendarTypes }
}

const actions = {
  async getCalendarHTTP ( { commit }) {
    const calendar = await api.get('/calendar')
    commit('CALENDAR_CALENDAR', calendar.data.data)
  },
  async saveCalendarEvent ( { state, commit, dispatch } ) {
    commit('CALENDAR_EMPTY_ERRORS')
    if( !state.calendarItem.calendarDate || state.calendarItem.calendarDate == '') commit('CALENDAR_ADD_ERROR', 'La fecha es requerida')
    if( state.calendarItem.calendarType <= 0) commit('CALENDAR_ADD_ERROR', 'El tipo de evento es requerido')

    if ( state.calendarItem.calendarType == 1) {
      if( state.calendarItem.calendarDescription == '') commit('CALENDAR_ADD_ERROR', 'La observación es requerida')
    } else {
      if( state.calendarItem.patientId == 0) commit('CALENDAR_ADD_ERROR', 'El paciente es requerido')
    }

    if( state.errors.length == 0 ) {
      if( state.calendarItem.calendarId == 0 ) {
        return await dispatch('createCalendarEventHTTP')
      } else {
        return await dispatch('updateCalendarEventHTTP')
      }
    }
  },
  async createCalendarEventHTTP ( { state, rootState } ) {
    let calendarItem = state.calendarItem
    calendarItem.createdBy = rootState.user.userId
    await api.post('/calendar', calendarItem )
    return 'Evento de calendario creado'
  },
  async updateCalendarEventHTTP ( { state, rootState } ) {
    let calendarItem = state.calendarItem
    calendarItem.updatedBy = rootState.user.userId
    await api.put('/calendar', calendarItem )
    return 'Evento de calendario actualizado'
  },
  async deleteCalendarEventHTTP ( { state } ) {
    let calendarItem = state.calendarItem
    await api.delete(`/calendar/${calendarItem.calendarId}`, calendarItem )
    return 
  },
  async updateLastMonthPatientHTTP ( { rootState }, { patientId, lastMonth } ) {
    let userId = rootState.user.userId
    await api.put('/calendar/calendarLastMonthPatient', { userId, patientId, lastMonth } )
    return
  },
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}