import api from "../services/api"
import {addProperyIfNotExists} from "../services/utils"

const state = {
  concept: {},
  newConcept: {
    conceptId: 0,
    conceptDescription: '',
    active: true,
  },
  concepts: [],
  errors: []

}

const mutations = {
  CONCEPTS_CONCEPTS ( state, concepts ) { state.concepts = concepts },
  CONCEPTS_CONCEPT ( state, concept ) { state.concept = addProperyIfNotExists(concept, state.newConcept) },
  CONCEPTS_CONCEPT_PROP ( state, payload ) { state.concept[payload.property] = payload.value },
  CONCEPTS_EMPTY_ERRORS ( state ) { state.errors = [] },
  CONCEPTS_ADD_ERROR ( state, error ) { state.errors.push(error)}
}

const actions = {
  async getConceptsHTTP ( { commit }) {
    const concepts = await api.get('/configuration/concepts')
    commit('CONCEPTS_CONCEPTS', concepts.data.data)
  },
  async saveConcept ( { state, commit, dispatch } ) {
    commit('CONCEPTS_EMPTY_ERRORS')
    if( state.concept.conceptDescription == '') commit('CONCEPTS_ADD_ERROR', 'La descripción del concepto es requerida')

    if( state.errors.length == 0 ) {
      if( state.concept.conceptId == 0 ) {
        return await dispatch('createConceptHTTP')
      } else {
        return await dispatch('updateConceptHTTP')
      }
    }
  },
  async createConceptHTTP ( { state, rootState } ) {
    let concept = state.concept
    concept.createdBy = rootState.user.userId
    await api.post('/configuration/concepts', concept )
    return 'Concepto creado'
  },
  async updateConceptHTTP ( { state, rootState } ) {
    let concept = state.concept
    concept.createdBy = rootState.user.userId
    concept.updatedBy = rootState.user.userId
    await api.put('/configuration/concepts', concept )
    return 'Concepto actualizado'
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}