function addProperyIfNotExists (objeto, objetoBase) {
  
  for(let k in objetoBase) {
  
    if(!Object.prototype.hasOwnProperty.call(objeto, k)) {
      objeto[k] = objetoBase[k]
    }
  }
  
  // solo para verificacion
  // for(let k in objeto) {
  
  //   if(!Object.prototype.hasOwnProperty.call(objetoBase, k)) {
  //     console.log(`La propiedad ${k} no está en el objeto base`)
  //   }
  // }

  
  return objeto
}

function formatDDMMYYY ( value ) {
  let fecha = new Date( value)
  let dia = fecha.getDate()
  let mes = fecha.getMonth() + 1
  let anio = fecha.getFullYear()

  if(dia < 10) dia = '0' + dia
  if(mes < 10) mes = '0' + mes

  return `${dia.toString()}/${mes.toString()}/${anio.toString()}`
}

export { 
  addProperyIfNotExists,
  formatDDMMYYY
}