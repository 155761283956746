<template>
  <div class="psPatientGeneralInformationDetail">
    <div class="row">
      <div class="column w-100">
        <div class="label">Información general</div>
        <el-input 
          type="textarea"
          :rows="5"
          :maxlength="1000"
          show-word-limit
          :value="itemGeneralInformation.description" 
          @input="PATIENTS_ITEM_GENERAL_INFORMATION_PROP({property: 'description', value: $event})"
        ></el-input>
      </div>
      <div class="column" v-if="itemGeneralInformation.id > 0">
        <div class="label">&nbsp;</div>
        <el-button type="danger" icon="el-icon-delete" plain @click="deleteGeneralInformation"></el-button>
      </div>
    </div>
    <el-button @click="saveGeneralInformation" icon="el-icon-check" type="success" round class="btnSave" :disabled="itemGeneralInformation.description == ''">{{ itemGeneralInformation.id > 0 ? 'Actualizar' : 'Guardar' }}</el-button>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsPatientMedicationDetail',
  computed: {
    ...mapState('patients', ['itemGeneralInformation'])
  },
  methods: {
    ...mapMutations('patients', ['PATIENTS_ITEM_GENERAL_INFORMATION_PROP', 'PATIENTS_DIALOG_GENERAL_INFORMATION']),
    ...mapActions('patients', ['saveGeneralInformationPatient', 'getPatientHTTP', 'deleteGeneralInformationatientHTTP']),
    async saveGeneralInformation () {
      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Guardando...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });   

        const message = await this.saveGeneralInformationPatient()
        this.$message( { message, type: 'success' } )
        setTimeout( async () => {
          await this.getPatientHTTP( this.$route.params['patientId'])
          loading.close()
          this.PATIENTS_DIALOG_GENERAL_INFORMATION(false)
        }, 1000)
      } catch ( err ) { this.$message( { message: err.response.data.err.details, type: 'error' })}
      finally { loading.close()}
     
    },
    async deleteGeneralInformation () {
      let loading
      try {
        this.$confirm('¿Quiere eliminar esta información general?', 'Confirmación', {
          type: 'warning',
          confirmButtonText: 'Si',
          cancelButtonText: 'No'
        })
        .then( async () => {
          loading = this.$loading({
            lock: true,
            text: 'Eliminando...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          }); 
          const message = await this.deleteGeneralInformationatientHTTP()
          setTimeout( async () => {
            await this.getPatientHTTP( this.$route.params['patientId'])
            this.PATIENTS_DIALOG_GENERAL_INFORMATION(false)
            loading.close()
            this.$message.success(message)
          }, 1000)
        })
      } catch ( err ) { this.$message.error('No se pudo eliminar esta información general.')}
    }
  }
}
</script>

<style>

</style>