import api from "../services/api"
import {addProperyIfNotExists} from "../services/utils"

const state = {
  rate: {},
  newRate: {
    rateId: 0,
    rateDescription: '',
    rateValue: 0,
    active: true,
    ratesGroupId: 0,
    ratesGroupDescription: '',
    ratePrincipal: false,
    createdBy: 0,
    updatedBy: 0,
    createdAt: '',
    updatedAt: ''
  },
  rates: [],
  errors: [],
  historyRates: []
}

const mutations = {
  RATES_RATES ( state, rates ) { state.rates = rates },
  RATES_HISTORY_RATES ( state, historyRates ) { state.historyRates = historyRates },
  RATES_RATE ( state, rate ) { state.rate = addProperyIfNotExists(rate, state.newRate) },
  RATES_RATE_PROP ( state, payload ) { state.rate[payload.property] = payload.value },
  RATES_EMPTY_ERRORS ( state ) { state.errors = [] },
  RATES_ADD_ERROR ( state, error ) { state.errors.push(error)}
}

const actions = {
  async getRatesHTTP ( { commit }) {
    const rates = await api.get('/configuration/rates')
    commit('RATES_RATES', rates.data.data)
  },
  async getHistoryRatesHTTP ( { state, commit }) {
    const rates = await api.get(`/configuration/rates/history/${state.rate.rateId}`)
    commit('RATES_HISTORY_RATES', rates.data.data)
  },
  async saveRate ( { state, commit, dispatch } ) {
    
    commit('RATES_EMPTY_ERRORS')
    if( state.rate.rateDescription == '') commit('RATES_ADD_ERROR', 'La descripción de la tarifa es requerida')
    if( state.rate.rateValue == '') commit('RATES_ADD_ERROR', 'El valor de la tarifa es requerido')

    
    if( state.errors.length == 0 ) {
      if( state.rate.rateId == 0 ) {
        
        return await dispatch('createRateHTTP')
      } else {
        return await dispatch('updateRateHTTP')
      }
    }
  },
  async createRateHTTP ( { state, rootState } ) {
    let rate = state.rate
    rate.createdBy = rootState.user.userId
    await api.post('/configuration/rates', rate )
    return 'Tarifa creada'
  },
  async updateRateHTTP ( { state, rootState } ) {
    let rate = state.rate
    rate.createdBy = rootState.user.userId
    rate.updatedBy = rootState.user.userId
    await api.put('/configuration/rates', rate )
    return 'Tarifa actualizada'
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}