<template>
  <div class="cropModal">
    <div class="containerCrop">
      <div class="row firstLine">
        <div class="cropArea">
          <vue-cropper
            v-if="isMounted"
            ref="cropper"
            :src="imageGroup"
            alt="Source image"
            :aspect-ratio="2.30769"
          ></vue-cropper>
          <img v-else src='@/assets/images/placeholder.png' width="100%" alt="Placeholder image">          
        </div>
      </div>
      <div class="row secondLine">
        <div>
          <el-button icon="el-icon-crop" type="info" plain @click="crop">Cortar</el-button>
        </div>        
      </div>
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsCropModal',
  components: { VueCropper },
  data () {
    return {
      isMounted: false,
      cropImg: '',
    }
  },
  computed: {
    ...mapState('groups', ['imageGroup', 'group'])
  },
  methods: {
    ...mapMutations('groups', ['GROUPS_GROUP_PROP', 'GROUPS_DIALOG_CROP_IMAGE', 'GROUPS_IMAGE_GROUP']),
    ...mapActions('groups', ['uploadImageGroupHTTP']),
    
    async crop () {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();      
      let fileImage = await this.base64toFile( this.cropImg, 'cropImage.jpg' )
      let pathImage = await this.uploadImageGroupHTTP({file: fileImage })
      this.GROUPS_GROUP_PROP( { property: 'groupPathImage', value: pathImage})
      this.GROUPS_DIALOG_CROP_IMAGE(false)
    },
    async base64toFile( dataurl, filename ) {
      let res = await fetch(dataurl)
      let blob = await res.blob()
      return new File([blob], filename, { type: 'image/jpeg'})
    },
    exit () {
      this.GROUPS_CROP()      
    },

  },
  mounted () {
    setTimeout( () => this.isMounted = true, 600)
  }
}
</script>

<style scoped>
  .cropModal { 
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000000ee;
    display: flex;
    justify-content: center; align-items: center;
    z-index: 100;
  }

  .containerCrop { background-color: white; display: flex; flex-direction: column; justify-content: space-between; width: 100%; height: 100%; padding: 20px}
  .firstLine { display: flex; justify-content: center; flex-grow: 4; overflow: hidden;}
  .secondLine { display: flex; justify-content: center; padding-top: 20px;}
  .cropArea { width: 100%; height: 100%; }
  .cropArea > div { height: 100%; width: 100%; }
  .cropper-hide { max-width: 100%; max-height: 100%; object-fit: contain;}
  .btnCrop { align-self: center;}
  

  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .cropModal { height: 100%;}
  }
</style>