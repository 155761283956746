<template>
  <div class="psAdministrationTransactionsFilters" :style="closed == true ? 'padding-bottom: 0' : 'padding-bottom: 15px;'" >
    <div class="row filterTitle"  @click="clickFilters" :style="closed == true ? 'margin-bottom: 20px;' : 'margin-bottom: 0'" >Filtros<span><i :class="closed == true ? 'el-icon-caret-bottom' : 'el-icon-caret-top'"></i></span></div>
    
    <div class="row rowFilters" :class="closed == true ? 'closed' : 'opened'">
      
      <div class="first w-40">
        <div class="column w-50">
          <div class="label">Concepto</div>
          <el-select v-model="concept" value-key="conceptDescription" multiple collapse-tags size="mini">
            <el-option v-for="(concept, index) in concepts" :key="index" :label="concept.conceptDescription" :value="concept"></el-option>
          </el-select>
        </div>

        <div class="column w-50">
          <div class="label">Tipo movimiento</div>
          <el-select v-model="type" multiple collapse-tags size="mini">
            <el-option v-for="(typeTransaction, index) in ['Ingreso', 'Egreso']" :key="index" :label="typeTransaction" :value="typeTransaction"></el-option>
          </el-select>
        </div>
      </div>

      <div class="second w-40">
        <div class="column w-50">
          <div class="label">Medio de pago</div>
          <el-select v-model="wayToPay" value-key="wayToPayDescription" multiple collapse-tags size="mini">
            <el-option v-for="(wayToPay, index) in waysToPayWithEmpty" :key="index" :label="wayToPay.wayToPayDescription" :value="wayToPay"></el-option>
          </el-select>
        </div>

        <div class="column w-50">
          <div class="label">Cuenta</div>
          <el-select v-model="account" value-key="accountDescription" multiple collapse-tags size="mini">
            <el-option v-for="(account, index) in accountsWithEmpty" :key="index" :label="account.accountDescription" :value="account"></el-option>
          </el-select>
        </div>
      </div>

      <div class="thirth w-10">
        <div class="column w-50">
          <div class="label">&nbsp;</div>
          <el-button round type="primary" @click="filterData" size="mini">Filtrar</el-button>
        </div>
        <div class="column w-50">
          <div class="label">&nbsp;</div>        
          <el-button icon="el-icon-close" circle size="mini" type="danger" plain @click="cleanFilters"></el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'PsAdministrationTransactionsFilters',
  data () {
    return {
      closed: true,
      concept: [],
      type: [],
      wayToPay: [],
      account: []
    }
  },
  computed: {
    ...mapState('concepts', ['concepts']),
    ...mapState('waysToPay', ['waysToPay']),
    ...mapState('accounts', ['accounts']),
    ...mapState('administration', ['transactions']),
    waysToPayWithEmpty () {
      let waysToPay = this.waysToPay.slice()
      waysToPay.push( { wayToPayId: 0, wayToPayDescription: 'Sin medio de pago' })
      return waysToPay
    },
    accountsWithEmpty () {
      let accounts = this.accounts.slice()
      accounts.push( { accountId: 0, accountDescription: 'Sin cuenta' })
      return accounts
    },
  },
  methods: {
    ...mapMutations('administration', ['ADMINISTRATION_TRANSACTIONS_FILTERED']),
    clickFilters () {
      this.closed = !this.closed
    },
    cleanFilters () {
      this.concept = this.type = this.wayToPay = this.account = []
      this.filterData()
    },
    filterData () {
      let transactionsFiltered = this.transactions.transactions.slice()

      if ( this.concept && this.concept.length > 0) {
        transactionsFiltered = transactionsFiltered.filter( i => [...this.concept.map( c => c.conceptId )].includes(i.conceptId) )
      }
      if ( this.type && this.type.length > 0) {
        transactionsFiltered = transactionsFiltered.filter( i => this.type.includes(i.type) )
      }
      if ( this.wayToPay && this.wayToPay.length > 0) {
        transactionsFiltered = transactionsFiltered.filter( i => [...this.wayToPay.map( w => w.wayToPayId )].includes(i.wayToPayId) )
      }
      if ( this.account && this.account.length > 0) {
        transactionsFiltered = transactionsFiltered.filter( i => [...this.account.map( a => a.accountId )].includes(i.accountId) )
      }

      this.ADMINISTRATION_TRANSACTIONS_FILTERED( transactionsFiltered )
    }
  }
}
</script>

<style scoped>
  .psAdministrationTransactionsFilters { display: flex; flex-direction: column; border-bottom: 1px solid #3E8091; }
  .filterTitle { color: #3E8091;  }
  .filterTitle > span { margin-left: 20px}
  .rowFilters { height: 0px; overflow: hidden; margin-bottom: 0; }
  .closed { height: 80px}

  .first, .second, .thirth { display: flex}


  @media (max-width: 991.98px) {
    .rowFilters { flex-direction: column; width: 100% }
    
    .closed { height: 160px}

    .first, .second { width: 100%}
    .thirth { width: 45%}

  }
</style>