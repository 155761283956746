<template>
  <div class="psHomeIndicators">
    <ps-upper-bar :site="{ site: 'Home' }"></ps-upper-bar>
    
    <div class="container">
      <ps-home-decrease-in-patients></ps-home-decrease-in-patients>
      <ps-home-subscriptions></ps-home-subscriptions>
      <ps-home-groups-in-phase></ps-home-groups-in-phase>
      <ps-home-invoiced></ps-home-invoiced>
    </div>
  </div>
</template>

<script>
import PsUpperBar from '@/components/layout/PsUpperBar.vue'
import PsHomeDecreaseInPatients from './widgets/PsHomeDecreaseInPatients.vue'
import PsHomeGroupsInPhase from './widgets/PsHomeGroupsInPhase.vue'
import PsHomeSubscriptions from './widgets/PsHomeSubscriptions.vue'
import PsHomeInvoiced from './widgets/PsHomeInvoiced.vue'
import { mapActions } from 'vuex'

export default {
  name: 'PsHomeIndicadores',
  components: { PsUpperBar, PsHomeDecreaseInPatients, PsHomeGroupsInPhase, PsHomeSubscriptions, PsHomeInvoiced },
  methods: {
    ...mapActions('home', ['getIndicatorsHTTP']),
  },
  async created () {
    await this.getIndicatorsHTTP()

  }
}
</script>

<style scoped>
  .psHomeIndicators { height: 100%;}
  .container { width: 100%; display: flex; flex-wrap: wrap; overflow-y: scroll; height: 100%; max-width: 1200px; align-content: baseline; gap: 40px 20px}
  .container::-webkit-scrollbar { display: none }
</style>