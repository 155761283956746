<template>
  <div class="psWaysToPay">
    <ps-upper-bar :site="{ site: 'Configuración - Formas de pago' }" :backButton="true"></ps-upper-bar>
    <div class="container">
      <div class="row">
        <el-button round plain icon="el-icon-plus" @click="addNewWayToPay" :disabled="newWayToPay">Nueva forma de pago</el-button>
      </div>
      <div class="row rowData">
        <div class="column w-25">
          <div class="label">Descripcion</div>
          <el-input 
            :value="wayToPay.wayToPayDescription" 
            @input="changeProperty($event, 'wayToPayDescription')" 
            placeholder="Ingrese una descripción"
            :disabled="!newWayToPay"></el-input>
        </div>
        <div class="column">
          <div class="label">Activa</div>
          <el-switch :value="wayToPay.active" @input="changeProperty($event, 'active')" :disabled="!newWayToPay"></el-switch>
        </div>
      </div>
      <div class="row rowButtons">
        <el-button round plain type="success" :disabled="!saveDisabled" @click="save">Guardar</el-button>
        <el-button round plain type="danger" :disabled="!newWayToPay" @click="cancel">Cancelar</el-button>
      </div>

      <el-table 
        :data="waysToPay"
        style="width: 800px;" 
        :highlight-current-row="true" 
        size="mini" 
        height="305" 
        empty-text="No hay formas de pago ingresadas"
        @cell-dblclick="selectRow">
        <el-table-column prop="wayToPayId" label="Código" width="80" v-if="!isMobile" ></el-table-column>
        <el-table-column prop="wayToPayDescription" label="Descripción" :width="isMobile == true ? '' : 300" ></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import PsUpperBar from '@/components/layout/PsUpperBar.vue'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsWaysToPay',
  components: { PsUpperBar },
  data () {
    return {
      activeNames: [],
      newWayToPay: false
    }
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapState('waysToPay', ['waysToPay', 'wayToPay', 'errors']),
    saveDisabled () {
      return this.newWayToPay && this.wayToPay.rateDescription != ''
    }
  },
  methods: {
    ...mapMutations('waysToPay', ['WAYS_TO_PAY_WAY_TO_PAY', 'WAYS_TO_PAY_WAY_TO_PAY_PROP']),
    ...mapActions('waysToPay', ['saveWayToPay', 'getWaysToPayHTTP']),
    selectRow ( row ) {
      if( row ) {
        this.newWayToPay = true
        this.WAYS_TO_PAY_WAY_TO_PAY(JSON.parse(JSON.stringify(row)))
      }
    },
    changeProperty ( value, property ) {
      this.WAYS_TO_PAY_WAY_TO_PAY_PROP({property, value})
    },
    addNewWayToPay () {
      this.newWayToPay = true
      this.WAYS_TO_PAY_WAY_TO_PAY({})
    },
    cancel () {
      this.newWayToPay = false
      this.WAYS_TO_PAY_WAY_TO_PAY({})
    },
    async save () {
      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Guardando...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        }); 

        const message = await this.saveWayToPay()
        if(this.errors.length > 0 ) {
          this.showValidationAlert()
        } else {
          this.cancel()
          this.$message( { message, type: 'success' } )
          this.getWaysToPayHTTP()
        }
    } catch ( err ) {  this.$message( { message: err.response.data.err.details, type: 'error' })}
      finally { loading.close(); }
    },
    showValidationAlert () {      
      let str = '<p>';
      if(this.errors.length > 0) {
        this.errors.forEach(err => str += `${err}<br>`)
        str = str + '</p>'
        this.$alert(str, '', {confirmButtonText: 'Entendido', type: 'warning', dangerouslyUseHTMLString: true})
      }
    },
  },
  created () {
    this.WAYS_TO_PAY_WAY_TO_PAY({})
    this.getWaysToPayHTTP()
  }
}
</script>

<style scoped>
  .psWaysToPay { min-height: 90%;}
  .container { background-color: white; border-radius: 20px; padding: 32px; min-height: 90%; height: 100%; overflow-y: scroll; }
  .container::-webkit-scrollbar { display: none}
  .rowData { margin: 40px 0 20px}

    /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .container { padding: 32px 16px}
    .rowData { flex-direction: column;}
    .rowData .column { margin-right: 0 }
    .rowData .column .el-input-number, .rowData .w-25 { width: 100%; margin-bottom: 8px; }
    .rowButtons { justify-content: space-evenly;}
  }
</style>