<template>
  <div class="psGroupsList">
    <ps-upper-bar :site="{ site: 'Grupos' }" :newButton="true" :tooltip="'Nuevo grupo'" @newItem="newGroup"></ps-upper-bar>
    <div class="row">
      <ps-groups-list-search-bar></ps-groups-list-search-bar>
    </div>
    <div class="column columnGroups">
      <div class="title">Mis grupos</div>
      <div class="content">
        <ps-groups-list-item v-for="(group, index) in groups" :key="index" :group="group"></ps-groups-list-item>
      </div>
    </div>
    
  </div>
</template>

<script>
import PsUpperBar from '@/components/layout/PsUpperBar.vue'
import PsGroupsListItem from './widgets/PsGroupsListItem.vue'
import PsGroupsListSearchBar from './widgets/PsGroupsListSearchBar.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'PsGroupsList',
  components: { PsUpperBar, PsGroupsListItem,  PsGroupsListSearchBar },
  computed: {
    ...mapState('groups', ['groups'])
  },
  methods: {
    ...mapActions('groups', ['getGroupsHTTP']),
    newGroup () {
      this.$router.push('/groups/detail/0')
    }
  },
  async created () {
    await this.getGroupsHTTP({page: 1})
  }
}
</script>

<style scoped>
  .psGroupsList { height: 98%;}
  .columnGroups { height: 100%;}
  .title { font-weight: bold; margin-top: 20px;}
  .content { display: flex; flex-wrap: wrap; margin-top: 10px; overflow-y: scroll; height: 65%; align-content: flex-start; width: 100%;}

  .content::-webkit-scrollbar {
    width: 8px;     
    height: 100%;    
    display: none;  
  }

   /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .columnGroups { margin-right: 0;}
    .content {  justify-content: flex-start; align-items: center; height: 75%; }
  }

  @media (max-width: 400px) {
    .columnGroups { margin-right: 0;}
    .content { display: flex; flex-wrap: nowrap; flex-direction: column; overflow-y: scroll;}
  }
</style>