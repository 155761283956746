<template>
  <div class="psPatientSubscriptionDetail">
    <div class="row" style="font-size: 1.25rem; justify-content: flex-start; margin-bottom: 10px; color: #3E8091">Suscripción</div>
    
    <div class="row" style="margin-bottom: 10px; justify-content: flex-start;" v-if="subscription.id == 0">
      <div class="column">
        <div class="label">Seleccionar otro mes</div>
          <el-date-picker
            style="width: 243px"
            v-model="month"
            type="month"
            format="MM/yyyy"
            @input="changeProperty($event, 'selectedMonth')"
            placeholder="Seleccione un año">
          </el-date-picker>
      </div>
    </div>

    <div class="row rowDataAmount">
      <div class="column w-80">
        <div class="row w-100">
          <div class="row w-50" style="border: 1px solid #ddd; justify-content: flex-start; padding: 8px 0;">
            <div class="column"   style="padding-left: 16px;">
              <div class="label">Mes</div>
              <div  class="data" style="line-height: 40px; height: 40px">{{months[subscription.month - 1]}} {{subscription.year > 0 ? subscription.year : ''}}</div>              
            </div>
          </div>
        <div class="row w-50" style="border: 1px solid #ddd; padding: 8px 0">
          <div class="column"  style="padding-left: 16px;">
            <div class="label">Tarifa</div>
            <el-select :value="subscription.rateValue" placeholder="Seleccione una tarifa" @change="changeProperty($event, 'rate')">
              <el-option v-for="(rate, index) in rates" :key="index" :label="`${rate.rateDescription} ${rate.rateValue}`" :value="rate"></el-option>
            </el-select>
          </div>
        </div>
        </div>
        <div class="row w-100">
          <div class="containerVariationSubscription" style="border: 1px solid #ddd; padding: 8px 16px">
            <div class="label">Descuento</div>
            <div class="row">
              <span style="line-height: 40px; margin-right: 5px">$</span>
              <el-input-number :controls="false" :min="0" :value="subscription.discountAmount" @input="changeProperty($event, 'discountAmount')" style="width: 75px;"></el-input-number>
            </div>
            <div class="row data" style="line-height: 38px">{{subscription.discountRate | numberTwoDecimal}} %</div>
          </div>
          <div class="containerVariationSubscription" style="border: 1px solid #ddd; padding: 8px 16px">
            <div class="label">Recargo</div>
            <div class="row">
              <span style="line-height: 40px; margin-right: 5px">$</span>
              <el-input-number :controls="false" :min="0" :value="subscription.surchargeAmount" @input="changeProperty($event, 'surchargeAmount')" style="width: 75px;"></el-input-number>
            </div>
            <div class="row data"  style="line-height: 38px">{{subscription.surcharge | numberTwoDecimal}} %</div>
          </div>
          <div class="containerVariationSubscription" style="border: 1px solid #ddd; padding: 8px 0">
            <div class="label">Comisiones</div>
            <div class="row">
              <span style="line-height: 40px; margin-right: 5px">$</span>
              <el-input-number :controls="false" :min="0"  :value="subscription.assignment" @input="changeProperty($event, 'assignment')" style="width: 75px;"></el-input-number>
            </div>
          </div>
        </div>
      </div>
      <div class="column w-20" style="border: 1px solid #ddd; justify-content: center; align-items: center">
        <div class="label">Total</div>
        <div class="data" style="font-size: 1.35rem;">$ {{subscription.total | numberTwoDecimal}}</div>
      </div>
    </div>

    <div class="row" style="justify-content: space-between; padding-top: 10px">
      <div style="display: flex; justify-content: flex-start">
        <div class="label" style="line-height: 40px; margin-right: 20px">Estado: </div>
        <div class="status" style="line-height: 40px; font-weight: bold">
          <span v-if="subscription.statusPaidOut == 3" style="color: rgb(2, 218, 2)">Pagado</span>
          <span v-else-if="subscription.statusPaidOut == 2" style="color: #c9aa04">Pago parcial</span>
          <span v-else-if="subscription.statusPaidOut == 0" style="color: #870101">Cancelado</span>
          <span v-else style="color: red">Pendiente de pago</span>
        </div>
      </div>
      <el-button type="danger" plain size="mini" @click="cancelSuscription" v-if="subscription.statusPaidOut != 0">Cancelar suscripcion</el-button>
    </div>

    <div class="row">
      <div class="column w-100">
        <div class="label">Observaciones</div>
        <textarea name="observations" class="textarea" id="observations" cols="58" rows="2" v-model="observations" placeholder="Observaciones"></textarea>         
      </div>
    </div>

    <template v-if="subscription.id > 0">
      <div class="row" style="font-size: 1.25rem; justify-content: flex-start; margin-bottom: 10px; margin-top: 40px; color: #3E8091">Pagos</div>
      <div class="dataPaymentsTable" style="width: 100%; display: flex; flex-direction: column; align-items: flex-start">
        <div class="row titles">
          <div class="id">ID</div>
          <div class="date">Fecha</div>
          <div class="amount">Importe</div>
        </div>

        <div class="paymentAssinedItem" v-for="(item, index) in paymentsAssigned" :key="index">
          <div class="id">{{item.paymentId}}</div>
          <div class="date">{{item.createdAt | formatDateDDMMYYYY}}</div>
          <div class="amount">$ {{item.paymentAmount}}</div>
        </div>
      </div>
    </template>


    <el-button @click="save" icon="el-icon-check" type="success" round class="btnSave">Guardar</el-button>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsPatientSubscriptionDetail',
  data () {
    return {
      month: null,
    }
  },
  computed: {
    ...mapState('patients', ['subscription', 'paymentsAssigned']),
    ...mapState('rates', ['rates']),
    ...mapState(['months']),
    observations: {
      set ( observations ) {
        this.changeProperty(observations, 'observations')
      },
      get () {
        return this.subscription.observations 
      }
    }
  },
  methods: {
    ...mapMutations('patients', ['PATIENTS_SUBSCRIPTION_PROP', 'PATIENTS_DIALOG_SUBSCRIPTION', 'PATIENTS_SUBSCRIPTION']),
    ...mapActions('patients', ['saveSubscription', 'getPatientHTTP', 'updatePatientSubscriptionHTTP']),
    changeProperty ( value, property ) {
      try {
      if(property == 'rate') {
        this.PATIENTS_SUBSCRIPTION_PROP( {property: 'rateId', value: value.rateId})
        this.PATIENTS_SUBSCRIPTION_PROP( {property: 'rateValue', value: value.rateValue})
      } else if (property == 'selectedMonth') {
        this.PATIENTS_SUBSCRIPTION_PROP( {property: 'month', value: value.getMonth() +1})
        this.PATIENTS_SUBSCRIPTION_PROP( {property: 'year', value: value.getFullYear()})
      } else {
        this.PATIENTS_SUBSCRIPTION_PROP( {property, value})
      }
      // let discountAmount = Number(this.subscription.rateValue) * Number(this.subscription.discountRate) / 100
      let discountRate =  (Number(this.subscription.discountAmount) / Number(this.subscription.rateValue)) * 100
      let surcharge = (Number(this.subscription.surchargeAmount) / ((Number(this.subscription.rateValue) - this.subscription.discountAmount)) ) * 100
      let assignment = Number(this.subscription.assignment)
      this.PATIENTS_SUBSCRIPTION_PROP( {property: 'discountRate', value: discountRate })
      this.PATIENTS_SUBSCRIPTION_PROP( {property: 'surcharge', value: surcharge})
      this.PATIENTS_SUBSCRIPTION_PROP( {property: 'assignment', value: assignment})
      this.PATIENTS_SUBSCRIPTION_PROP( {property: 'total', value: Number(this.subscription.rateValue) - this.subscription.discountAmount + this.subscription.surchargeAmount - assignment})
      } catch (err) { console.log(err)}
      
    },
    
    async cancelSuscription () {
      this.$confirm('¿Quiere cancelar esta suscripción?', 'Confirmación', {
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        type: 'warning'
      })
      .then( async () => {
        this.changeProperty(0, 'statusPaidOut')
        await this.save()
      })
      .catch( () => null)
    },

    async save () {
      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Guardando...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });   

        await this.saveSubscription()
        this.month = null
        this.$message.success( 'Subscripción guardada!' )
        setTimeout( async () => {
          await this.getPatientHTTP( this.$route.params['patientId'])
          loading.close()
          this.PATIENTS_SUBSCRIPTION({})
          this.PATIENTS_DIALOG_SUBSCRIPTION(false)
        }, 1000)
      } catch ( err ) { console.log(err); this.$message( { message: err.response.data.err.details, type: 'error' })}
      finally { loading.close()}
     
    },
    
  }
}
</script>

<style scoped>
  .psPatientSubscriptionDetail div.row { justify-content: center;}
  .el-radio-button.is-active.debe /deep/ .el-radio-button__inner { background-color: red; border: 1px solid red; box-shadow: none;}
  .el-radio-button.is-active.pago /deep/ .el-radio-button__inner { background-color: #67c23a; border: 1px solid #67c23a; box-shadow: none;}
  div.row, .column { margin: 0; }
  .rowDataAmount > .row, .rowPayment > .row { width: 50%}
  .containerVariationSubscription { display: flex; flex-direction: column; width: 33.33%; justify-content: flex-start; align-items: center}
  .btnSave { margin-top: 30px;}
  .paymentAssinedItem { display: flex; padding: 5px 0}
  .titles { display: flex; align-items: flex-start; border-bottom: 1px solid #ddd; padding-bottom: 5px; }              
  .date, .amount { width: 140px; text-align: left }
  .id { width: 80px; }
  .textarea { width: 100%; font-family: Helvetica, sans-serif; color: #606266; border-radius: 4px; padding: 5px }
  .textarea:focus-visible { outline-color: #3e8091; outline-width: 0px; border-color: #3e8091}
      
      
  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .rowDataAmount, .rowPayment { width: 100%; flex-direction: column;}
    .rowDataAmount > .w-80, .rowDataAmount > .w-20 { width: 100%}

    .rowPayment > .row { width: 100%}
    .rowPayment > .w-33 { width: 100%; margin-bottom: 30px; align-items: center;}
  }

  @media (max-width: 400px) {
    .rowDataAmount > .w-80 > .w-100:nth-child(2) { flex-direction: column;}
    .containerVariationSubscription { width: 100%}
  }
</style>