<template>
  <div class="psConfigurationLink">
    <i :class="icon" class="icon"></i>
    <router-link :to="to">{{label}}</router-link>
  </div>
</template>

<script>
export default {
  name: 'PsConfigurationLink',
  props: ['icon', 'label', 'to']
}
</script>

<style scoped>
  .psConfigurationLink { font-size: 1.1rem; display: flex; justify-content: flex-start;  transition: 0.2s ease}
  a { margin-left: 12px; margin-bottom: 16px; color: #2c3e50; text-decoration: none;}
  a:active, a:hover, a:visited { color: #2c3e50; }
  .psConfigurationLink:hover { transform: translateX(5px); font-weight: bold;}
</style>