<template>
  <div class="psCalendarGridDay" :style="styleHeight" :id="refGrid" >
    <div class="row">
      <div class="date">
        <div class="weekDay">{{calendarDay.calendarWeekDay}}</div>
        <div class="dateNumber">{{calendarDay.dayNumber}}</div>
      </div>
    </div>
    <div class="containerEvents" :style="styleHeightContainerEvents" :id="refContainerEvent">
      <ps-calendar-grid-day-event v-for="(calendarEvent, index) in calendarDay.calendarEvents" :key="index" :calendar-event="calendarEvent" :index="index"></ps-calendar-grid-day-event>
    </div>
    <el-button :icon="expanded == false ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" type="default" @click="expand" circle=""  class="iconExpand"></el-button>
  </div>
</template>

<script>
import PsCalendarGridDayEvent from './PsCalendarGridDayEvent.vue'


export default {
  name: 'PsCalendarGridDay',
  components: { PsCalendarGridDayEvent },
  props: ['calendarDay', 'index'],
  data () {
    return {
      expanded: false,
      expandedHeight: null,
      compressedHeight: 262
    }
  },
  computed: {
    
    refContainerEvent () {
      return `gridItemContainerEvents-${this.index}`
    },
    refGrid () {
      return `gridItem-${this.index}`
    },
    styleHeight () {
      return {
        height: (this.expanded == false ? this.compressedHeight : this.expandedHeight) + 'px',
      }
    },
    styleHeightContainerEvents () {
      return {
        minHeight: this.expanded == false ? '62%' : '0px',
      }
    }
  },
  methods: {
    expand () {
      this.expanded = !this.expanded
    }
  },
  created () {
    setTimeout(() => {
      let gridItemContainerEvent = document.querySelector(`#${this.refContainerEvent}`)
      let gridItem = document.querySelector(`#${this.refGrid}`)
      
      this.expandedHeight = gridItem.clientHeight + gridItemContainerEvent.scrollHeight - gridItemContainerEvent.clientHeight + 30
    }, 2000)
  }
}
</script>
  
<style scoped>
  .psCalendarGridDay { 
    background-color: white; 
    border-radius: 20px; 
    width: 270px; 
    height: 262px;
    position: relative;
    padding: 15px;
  }

  .iconExpand { border: none; font-size: 28px; position: absolute; bottom: 7px; right: 10px;}

  .date { display: flex; flex-direction: column; border-radius: 20px; box-shadow: 0 3px 6px 1px #ccc; width: 76px; height: 70px; padding: 8px  }
  .weekDay { color: #0290af; font-size: 11px; font-weight: 300;}
  .dateNumber { font-size: 36px; font-weight: 900;}

  .containerEvents { height: 62%; overflow: hidden; }


        /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .psCalendarGridDay { width: 100%;} 
  }
</style>