import api from "../services/api"
import {addProperyIfNotExists} from "../services/utils"

const state = {
  os: {},
  newOs: {
    osId: 0,
    osDescription: '',
    osCuit: '',
    active: true,
    createdBy: 0,
    updatedBy: 0,
    createdAt: '',
    updatedAt: ''
  },
  oss: [],
  errors: []

}

const mutations = {
  OSS_OSS ( state, oss ) { state.oss = oss },
  OSS_OS ( state, os ) { state.os = addProperyIfNotExists(os, state.newOs) },
  OSS_OS_PROP ( state, payload ) { state.os[payload.property] = payload.value },
  OSS_EMPTY_ERRORS ( state ) { state.errors = [] },
  OSS_ADD_ERROR ( state, error ) { state.errors.push(error)}
}

const actions = {
  async getOssHTTP ( { commit }) {
    const oss = await api.get('/configuration/oss')
    commit('OSS_OSS', oss.data.data)
  },
  async saveOs ( { state, commit, dispatch } ) {
    commit('OSS_EMPTY_ERRORS')
    if( state.os.osDescription == '') commit('OSS_ADD_ERROR', 'La descripción de la obra social es requerida')
    if( state.os.osCuit == '') commit('OSS_ADD_ERROR', 'El CUIT de la obra social es requerido')

    if( state.errors.length == 0 ) {
      if( state.os.osId == 0 ) {
        return await dispatch('createOsHTTP')
      } else {
        return await dispatch('updateOsHTTP')
      }
    }
  },
  async createOsHTTP ( { state, rootState } ) {
    let os = state.os
    os.createdBy = rootState.user.userId
    await api.post('/configuration/oss', os )
    return 'Obra Social creada'
  },
  async updateOsHTTP ( { state, rootState } ) {
    let os = state.os
    os.createdBy = rootState.user.userId
    os.updatedBy = rootState.user.userId
    await api.put('/configuration/oss', os )
    return 'Obra Social actualizada'
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}