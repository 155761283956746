<template>
  <div class="psPatientsListTable" id="psPatientsListTable">
    <div class="titles">
      <div class="titleRow firstRow">
        <div class="nameSurname">NOMBRE Y APELLIDO</div>
        <div class="group">GRUPO</div>
      </div>
      <div class="titleRow secondRow">
        <div class="dateOfBirth">FECHA NACIMIENTO</div>
        <div class="targetWeight">PESO OBJETIVO</div>
      </div>
    </div>
    <div class="contentItems" id="contentItems"  ref="contentItems">
      <ps-patients-list-item v-for="(patient, index) in patients" :key="index" :patient="{ index, ...patient }"></ps-patients-list-item>
      <infinite-loading :distance="200" :identifier="infiniteId" @infinite="infiniteHandler" slot="append">
          <div slot="no-more" class="noMore">No hay más pacientes.</div>
      </infinite-loading>
    </div>

  </div>

</template>

<script>
import PsPatientsListItem from './PsPatientsListItem.vue'
import InfiniteLoading from 'vue-infinite-loading';
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsPatientsListTable',
  components: { PsPatientsListItem, InfiniteLoading },
  data () {
    return {
      page: 1,
      infiniteId: +new Date(),
    }
  },
  computed: {
    ...mapState('patients', ['patients'])
  },
  methods: {
    ...mapMutations('patients', ['PATIENTS_EMPTY_PATIENTS']),
    ...mapActions('patients', ['getPatientsHTTP']),
    async infiniteHandler ($state) {
      try {
        let complete = await this.getPatientsHTTP( { page: this.page })
        if ( complete == true ) {
          $state.complete()
        } else {
          $state.loaded()
          this.page = this.page + 1
        }
      } catch ( err ) { return err }
    }
  },
  created () {
    this.PATIENTS_EMPTY_PATIENTS()
    this.page = 1
  }
}
</script>

<style scoped>
  .psPatientsListTable { padding: 20px 0; height: 98%;  }
  .titles { color: #0290af; font-weight: bold; font-size: 0.9rem; display: flex; margin-bottom: 10px; padding: 0 16px}
  .titles div { text-align: left;}
  .titleRow { display: flex}
  .nameSurname { width: 345px}
  .group { width: 140px}
  .dateOfBirth, .targetWeight { width: 180px }

  .contentItems { height: 100%; overflow-y: scroll; padding-right: 10px; padding-top: 5px; }
  .contentItems::-webkit-scrollbar {
    width: 8px;     
    height: 100%;    
    display: none;  
  }

  /* Ponemos un color de fondo y redondeamos las esquinas del thumb */
.contentItems::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.contentItems::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
.contentItems::-webkit-scrollbar-thumb:active {
    background-color: #999999;
}

    /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .psPatientsListTable { padding: 0; height: 100%;}
    .titles { padding: 0 10px 0 0  }
    .firstRow, .secondRow { width: 100%; justify-content: space-between;}
    .secondRow { display: none}
    .nameSurname, .group, .dateOfBirth, .targetWeight { width: auto }
    .contentItems { padding: 0; height: 75%;}
  }
</style>