<template>
  <div id="app">
    <router-view/>
    <el-drawer 
      :visible.sync="drawerMenu" 
      direction="ltr" 
      title="" 
      size="70%" 
      show-close
      :before-close="handleClose">
        <ps-lateral-bar :labels="true"></ps-lateral-bar>
      </el-drawer>
  </div>
</template>

<script>
import PsLateralBar from '@/components/layout/PsLateralBar.vue'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'App',
  components: { PsLateralBar },
  computed: {
    ...mapState(['drawerMenu']),
  },
  methods: {
    ...mapMutations(['DRAWER_MENU', 'IS_MOBILE']),
    handleClose () {
      this.DRAWER_MENU(false)
    },
    myEventHandler () {
      let app = document.querySelector('#app')
      if ( app.clientWidth < 992 ) {
        this.IS_MOBILE(true)
      } else {
        this.IS_MOBILE(false)
      }

    }
  },
  created() {
    window.addEventListener("load", this.myEventHandler);
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("load", this.myEventHandler);
    window.removeEventListener("resize", this.myEventHandler);
  },

}
</script>


<style>

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* { margin: 0; padding: 0; box-sizing: border-box;}
#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100vw;
  height: 100vh;
}

@font-face {
  font-family: 'icomoon';
  src:  url('assets/fonts/icomoon/icomoon.eot?jhfq1i');
  src:  url('assets/fonts/icomoon/icomoon.eot?jhfq1i#iefix') format('embedded-opentype'),
    url('assets/fonts/icomoon/icomoon.ttf?jhfq1i') format('truetype'),
    url('assets/fonts/icomoon/icomoon.woff?jhfq1i') format('woff'),
    url('assets/fonts/icomoon/icomoon.svg?jhfq1i#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cross:before {
  content: "\e908";
}
.icon-pos:before {
  content: "\e909";
}
.icon-shield-lock:before {
  content: "\e90a";
}
.icon-wallet:before {
  content: "\e90b";
}
.icon-text-align-justify:before {
  content: "\e907";
}
.icon-bell:before {
  content: "\e900";
}
.icon-calendar-dates:before {
  content: "\e901";
}
.icon-database:before {
  content: "\e902";
}
.icon-home:before {
  content: "\e903";
}
.icon-menu-dots:before {
  content: "\e904";
}
.icon-settings:before {
  content: "\e905";
}
.icon-user:before {
  content: "\e906";
}

.view { height: 100%; display: flex; width: 100%;}
.column { display: flex; flex-direction: column; align-items: flex-start; margin-right: 20px;}
.row { display: flex; flex-direction: row; margin-bottom: 8px}
.tooltip { font-family: 'Roboto', sans-serif;}
.label { font-size: 0.8rem; color: rgba(0, 0, 0, 0.6); margin-bottom: 4px;}
.el-date-picker { font-family: 'Roboto', sans-serif;}
.el-select-dropdown { font-family: 'Roboto', sans-serif;}
.el-input, div.el-date-editor.el-input, div.el-date-editor.el-input__inner, div.el-select { width: 100%; }
.w-100 { width: 100%}
.w-80 { width: 80%}
.w-75 { width: 75%}
.w-66 { width: 66.6%}
.w-50 { width: 50%}
.w-40 { width: 40%}
.w-33 { width: 33.3%}
.w-30 { width: 30%}
.w-25 { width: 25%}
.w-20 { width: 20%}
.w-16 { width: 16.6%}
.w-15 { width: 15%}
.w-10 { width: 10%}
.w-5 { width: 5%}
.space-between { justify-content: space-between;}
/* .inputLogin { border: none} */
.inputLogin { border: none; border-bottom: 1px solid #0A4B97;}
.inputLogin { color: #3E8091;}
.el-message, .el-message-box, .el-select-dropdown { font-family: 'Roboto', sans-serif;}
.el-textarea__inner, .el-autocomplete-suggestion__list > li { font-family: 'Roboto', sans-serif; }
.el-message-box {
	width: 98%!important;
	max-width: 420px;
}
.data { font-size: 1.15rem;}

    /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .data { font-size: 0.9rem; font-weight: bold;}
  }
</style>
