<template>
  <div class="psGroupsDialogCropImage">
    <el-dialog :visible.sync="dialogCropImage" title="Cortar imagen" :before-close="handleClose" :width="isMobile == true ? '100%' : '40%'" >
      <div class="container">
        <ps-groups-crop-image v-if="dialogCropImage"></ps-groups-crop-image>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PsGroupsCropImage from './PsGroupsCropImage.vue'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'PsGroupsDialogCropImage',
  components: { PsGroupsCropImage },
  computed: {
    ...mapState('groups', ['dialogCropImage']),
    ...mapState(['isMobile'])
  },
  methods: {
    ...mapMutations('groups', ['GROUPS_DIALOG_CROP_IMAGE']),
    handleClose () {
      this.GROUPS_DIALOG_CROP_IMAGE(false)
    }
  }
}
</script>

<style scoped>
  .container { min-width: 300px; min-height: 600px;}

      /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .container { min-height: 400px;}
  }
</style>