<template>
  <div class="itemMenu" :class="{itemActive: item.active || active}" @mouseenter="mouseEnterItemActive" @mouseleave="mouseLeaveItemActive" @click="irA">
    <i :class="item.icon" class="icon" ></i>
    <span class="label" v-if="labels == true">{{item.title}}</span>
    <span class="contentTooltip">{{item.title}}</span>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'PsItemMenu',
  data () {
    return {
      active: false
    }
  },
  props: ['item', 'labels'],
  methods: {
    ...mapMutations('routes', ['ACTIVATE_ITEM_MENU']),
    ...mapMutations(['DRAWER_MENU', 'LOGOUT']),
    mouseEnterItemActive () {
      this.active = true
    },
    mouseLeaveItemActive () {
      this.active = false
    },
    irA () {
      this.ACTIVATE_ITEM_MENU(this.item.title)
      this.DRAWER_MENU(false)
      
      if(this.item.title == 'Cerrar sesión') {
        this.LOGOUT()
        this.$router.replace('/')
      } else {
        this.$router.push(this.item.route)
      }
    }
  }
}
</script>

<style scoped>
  .itemMenu { color: rgba(0, 0, 0, 0.4);  display: flex;  padding: 24px 0; transition: 0.2s ease-in-out all; position: relative}
  a { text-decoration: none;}
  .icon {  font-size: 2rem;}

  .itemActive { color: rgba(0, 0, 0, 1); ;  font-weight: bold; position: relative; }
  .itemActive > i.icon { font-weight: semi-bold;}
  .label { line-height: 30px; margin-left: 15px}
  .contentTooltip { 
    visibility: hidden;
    width: 120px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    text-align: center;
    padding: 4px 0;
    border-radius: 12px;
    position: absolute;
    z-index: 10;
    font-size: 0.8rem;
    left: 40px;
    top: 27px
  }

  .itemMenu:hover .contentTooltip {
    visibility: visible;
  }

      /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .itemMenu { padding: 8px 0;}
    .icon { font-size: 1.7rem;}
    .itemMenu:hover .contentTooltip {
      visibility: hidden;
    }
  }
</style>