<template>
  <div class="psPatientBtnNewTransaction">
    <el-button circle style="border: 2px solid black; margin: 4px 0; color: black; font-weight: bold;" size="mini" @click="newTransaction">
      <i class="el-icon-plus" style="font-weight: 900"></i>
    </el-button>
    <span class="tooltip">Nuevo movimiento</span>    
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'PsPatientBtnNewTransaction',
  methods: {
    ...mapMutations('administration', ['ADMINISTRATION_DIALOG_TRANSACTION', 'ADMINISTRATION_TRANSACTION', 'ADMINISTRATION_TYPE_TRANSACTION']),
    async newTransaction () {
      this.ADMINISTRATION_DIALOG_TRANSACTION(true)
      this.ADMINISTRATION_TRANSACTION({ paidOutDatetime: new Date()})
      this.ADMINISTRATION_TYPE_TRANSACTION('Egreso')
    }
  }
}
</script>

<style scoped>
  .psPatientBtnNewTransaction { position: relative; margin-left: 15px;}
  .tooltip { 
    visibility: hidden;
    width: 180px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    text-align: center;
    padding: 4px 0;
    border-radius: 12px;
    position: absolute;
    z-index: 10;
    font-size: 0.8rem;
    left: 5px;
    top: -30px
  }

  .psPatientBtnNewTransaction:hover .tooltip {
    visibility: visible;
  }

  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .tooltip { display: none;}    
  }
</style>