import api from "../services/api"
import {addProperyIfNotExists} from "../services/utils"

const state = {
  calendarType: {},
  newCalendarType: {
    calendarTypeId: 0,
    calendarTypeDescription: '',
    active: true,
    createdBy: 0,
    updatedBy: 0,
    createdAt: '',
    updatedAt: ''
  },
  calendarTypes: [],
  errors: [],
}

const mutations = {
  CALENDAR_TYPES_CALENDAR_TYPES ( state, calendarTypes ) { state.calendarTypes = calendarTypes },
  CALENDAR_TYPES_CALENDAR_TYPE ( state, calendarType ) { state.calendarType = addProperyIfNotExists(calendarType, state.newCalendarType) },
  CALENDAR_TYPES_CALENDAR_TYPE_PROP ( state, payload ) { state.calendarType[payload.property] = payload.value },
  CALENDAR_TYPES_EMPTY_ERRORS ( state ) { state.errors = [] },
  CALENDAR_TYPES_ADD_ERROR ( state, error ) { state.errors.push(error)}
}

const actions = {
  async getCalendarTypesHTTP ( { commit }) {
    const calendarTypes = await api.get('/configuration/calendarTypes')
    commit('CALENDAR_TYPES_CALENDAR_TYPES', calendarTypes.data.data)
  },
  // async saveRate ( { state, commit, dispatch } ) {
    
  //   commit('RATES_EMPTY_ERRORS')
  //   if( state.rate.rateDescription == '') commit('RATES_ADD_ERROR', 'La descripción de la tarifa es requerida')
  //   if( state.rate.rateValue == '') commit('RATES_ADD_ERROR', 'El valor de la tarifa es requerido')

    
  //   if( state.errors.length == 0 ) {
  //     if( state.rate.rateId == 0 ) {
        
  //       return await dispatch('createRateHTTP')
  //     } else {
  //       return await dispatch('updateRateHTTP')
  //     }
  //   }
  // },
  // async createRateHTTP ( { state, rootState } ) {
  //   let rate = state.rate
  //   rate.createdBy = rootState.user.userId
  //   await api.post('/configuration/rates', rate )
  //   return 'Tarifa creada'
  // },
  // async updateRateHTTP ( { state, rootState } ) {
  //   let rate = state.rate
  //   rate.createdBy = rootState.user.userId
  //   rate.updatedBy = rootState.user.userId
  //   await api.put('/configuration/rates', rate )
  //   return 'Tarifa actualizada'
  // }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}