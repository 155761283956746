<template>
  <div class="psPatientsListItem" @click="selectPatient">
    <div class="firstRow">
      <div class="containerNameSurname">
        <el-avatar 
          :size="40" 
          :src="patient.pathImagePatient && patient.pathImagePatient != '' ? patient.pathImagePatient : `${urlServer}/assets/images/avatarPlaceholder.png` ">
        </el-avatar>
        <div class="nameSurname">{{patient.name | textCapitalize}} {{patient.surname | textCapitalize}}</div>
      </div>
      <div class="group">{{patient.groupDescription | textCapitalize}} </div>
    </div>
    <div class="secondRow">
      <div class="dateOfBirth">{{patient.dateOfBirth}} </div>
      <div class="targetWeight">{{patient.targetWeight}} </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PsPatientsListItem',
  data () {
    return {
      contentItemsHeight: 0,
      dynamicRouteHeight: 0
    }
  },
  props: {
    patient: Object
  },
  computed: {
    urlServer () {
      return process.env.VUE_APP_URLAPI
    }
  },
  methods: {
    selectPatient () {
      this.$router.push(`/patients/detail/${this.patient.patientId}`)
    }
  }
}
</script>

<style scoped>
  .psPatientsListItem { 
    display: flex; 
    justify-content: flex-start; 
    padding: 12px 16px; 
    background-color: white; 
    border-radius: 10px;
    line-height: 40px;
    color: black;
    font-weight: 500;
    text-align: left;
    margin-bottom: 8px;
    box-shadow: 1px 1px 3px 1px #ccc;
    cursor: pointer;
    transition: 0.2s ease;
  }
  .psPatientsListItem:hover { transform: translate(2px, -2px); box-shadow: 2px 2px 5px 1px #aaa;}
  .containerNameSurname { display: flex; margin-right: 18px; }
  .nameSurname { width: 270px; margin-left: 20px}    
  .group { width: 140px}
  .dateOfBirth, .targetWeight { width: 180px }

  .firstRow, .secondRow { display: flex;  }


    /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .psPatientsListItem  { flex-direction: column; padding-right: 12px;}
    .containerNameSurname { display: flex; flex-grow: 3;}
    .nameSurname { width: auto; margin-left: 10px}
    .group { flex-grow: 1; width: auto; text-align: right;}
    .secondRow { display: none}

  }
</style>