<template>
  <div class="psConfigurationEventuals">
    <ps-upper-bar :site="{ site: 'Configuración - Información eventual' }" :backButton="true"></ps-upper-bar>
    <div class="container">
      <div class="row">
        <el-button round plain icon="el-icon-plus" @click="addNewEventual" :disabled="newEventual">Nueva información eventual</el-button>
      </div>
      <div class="row rowData">
        <div class="column w-25">
          <div class="label">Descripcion</div>
          <el-input 
            :value="eventual.eventualDescription" 
            @input="changeProperty($event, 'eventualDescription')" 
            placeholder="Ingrese una descripción"
            :disabled="!newEventual"></el-input>
        </div>
        <div class="column">
          <div class="label">Activa</div>
          <el-switch :value="eventual.active" @input="changeProperty($event, 'active')" :disabled="!newEventual"></el-switch>
        </div>
      </div>
      <div class="row rowButtons">
        <el-button round plain type="success" :disabled="!saveDisabled" @click="save">Guardar</el-button>
        <el-button round plain type="danger" :disabled="!newEventual" @click="cancelEventual">Cancelar</el-button>
      </div>

      <el-table 
        :data="eventuals"
        style="width: 800px;" 
        :highlight-current-row="true" 
        size="mini" 
        height="305" 
        empty-text="No hay información eventual ingresadas"
        @cell-dblclick="selectRow">
        <el-table-column prop="eventualId" label="Código" width="80" class-name="eventualId" id="eventualId" v-if="!isMobile"></el-table-column>
        <el-table-column prop="eventualDescription" label="Descripción" :width="isMobile == true ? '' : 300" ></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import PsUpperBar from '@/components/layout/PsUpperBar.vue'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsConfigurationEventuals',
  components: { PsUpperBar },
  data () {
    return {
      newEventual: false
    }
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapState('eventuals', ['eventuals', 'eventual', 'errors']),
    saveDisabled () {
      return this.newEventual && this.eventual.eventualDescription != ''
    }
  },
  methods: {
    ...mapMutations('eventuals', ['EVENTUALS_EVENTUAL', 'EVENTUALS_EVENTUAL_PROP']),
    ...mapActions('eventuals', ['saveEventual', 'getEventualsHTTP']),
    selectRow ( row ) {
      if( row ) {
        this.newEventual = true
        this.EVENTUALS_EVENTUAL(JSON.parse(JSON.stringify(row)))
      }
    },
    changeProperty ( value, property ) {
      this.EVENTUALS_EVENTUAL_PROP({property, value})
    },
    addNewEventual () {
      this.newEventual = true
      this.EVENTUALS_EVENTUAL({})
    },
    cancelEventual () {
      this.newEventual = false
      this.EVENTUALS_EVENTUAL({})
    },
    async save () {
      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Guardando...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        }); 

        const message = await this.saveEventual()
        if(this.errors.length > 0 ) {
          this.showValidationAlert()
        } else {
          this.cancelEventual()
          this.$message( { message, type: 'success' } )
          this.getEventualsHTTP()
        }
    } catch ( err ) { this.$message( { message: err.response.data.err.details, type: 'error' })}
      finally { loading.close(); }
    },
    showValidationAlert () {      
      let str = '<p>';
      if(this.errors.length > 0) {
        this.errors.forEach(err => str += `${err}<br>`)
        str = str + '</p>'
        this.$alert(str, '', {confirmButtonText: 'Entendido', type: 'warning', dangerouslyUseHTMLString: true})
      }
    },
  },
  created () {
    this.EVENTUALS_EVENTUAL({})
    this.getEventualsHTTP()
  }
}
</script>

<style scoped>
  .psConfigurationEventuals { min-height: 90%;}
  .container { background-color: white; border-radius: 20px; padding: 32px; min-height: 90%; overflow-y: scroll; height: 100%; }
  .container::-webkit-scrollbar { display: none}
  .rowData { margin: 40px 0 20px}
  .rowHistory { margin-bottom: 20px;}
  .el-collapse { width: 500px }
  .itemHistoryRate { text-align: left;}
  .stateRate { color: red; margin-left: 15px}
  .active { color: green}


  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .container { padding: 32px 16px}
    .rowData { flex-direction: column;}
    .rowData .column { margin-right: 0 }
    .rowData .column .el-input-number, .rowData .w-25 { width: 100%; margin-bottom: 8px; }
    .rowButtons { justify-content: space-evenly;}
  }
</style>