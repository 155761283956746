<template>
  <div class="psHomeInvoiced">
    <div class="template" v-if="indicators && indicators.paidOutActualMonth">
      <div class="pastMonths" >
        <div class="month" v-for="(month, index) in indicators.paidOutLastThreeMonths" :key="index">
          <div class="monthNumber">{{month.month}}</div>
          <div class="containerMonth">
            <div class="monthName">{{months[month.month -1]}}</div>
            <div class="monthInvoiced">$ {{ month.total }} K</div>
          </div>
        </div>
      </div>
      <div class="actualMonth">
        <div class="dataMonth" v-if=" indicators && indicators.paidOutActualMonth">
          <div class="monthName">{{months[indicators.paidOutActualMonth.month - 1]}}</div>
          <div class="monthName">$ {{ indicators.paidOutActualMonth.total }} K</div>
        </div>
        <div class="graphContainer" v-if="indicators && indicators.paidOutActualMonth">
          <div class="graph">
            <div class="valueMonth" v-for="(value, index) in indicators.paidOutActualMonth.data" :key="index">
              <div class="containerBar">
                <div class="bar" :style="{height: `${value.percentage}%`, backgroundColor: value.isMax == true ? '#FF4DCD' : '#3E8091'}"></div>
              </div>
              <div class="containerMarkDay">
                <div class="markDay"></div>
                <div class="numberDay">{{value.day}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="reference">
          <div class="referenceItem">
            <div class="referenceColor"></div>
            <div class="referenceText">Día</div>
          </div>
          <div class="referenceItem">
            <div class="referenceColor"></div>
            <div class="referenceText">+ Facturado</div>
          </div>
        </div>
      </div>
    </div>
    <div class="withoutInvoced" v-else>
      Sin facturación para mostrar
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PsHomeInvoiced',
  computed: {
    ...mapState(['months']),
    ...mapState('home', ['indicators']),
  }
}
</script>

<style scoped>
  .psHomeInvoiced { width: 480px; height: 310px; display: flex; flex-direction: column;  margin-left: 60px }
  .pastMonths, .actualMonth { display: flex; }
  .pastMonths { justify-content: space-between;}
  .month { 
    background-color: white; 
    width: 150px; 
    padding: 12px; 
    border-radius: 8px; 
    box-shadow: 1px 1px 1px #aaa; 
    display: flex; 
    align-items: center; 
    margin-bottom: 8px;
  }
  .monthNumber { font-weight: bold; font-size: 1.75rem; line-height: 1.75rem; width: 35px; text-align: right;}
  .containerMonth { margin-left: 16px; display: flex; flex-direction: column; align-items: flex-start; }
  .monthName { font-size: 0.8rem; font-weight: bold;}
  .monthInvoiced { font-size: 0.8rem; }

  .actualMonth { 
    background-color: white; 
    border-radius: 8px; 
    box-shadow: 1px 1px 1px #aaa; 
    width: 100%; 
    display: flex;
    flex-direction: column;
     margin-bottom: 200px 
  }
  .dataMonth { display: flex; justify-content: space-between; width: 100%; padding: 20px 30px}
  .dataMonth > .monthName { font-size: 1.1rem;}

  .graphContainer { width: 100%; height: 200px; position: relative;}
  .graph { border-bottom: 1px solid black; height: 160px; position: absolute;  left: 25px; right: 25px; display: flex  }
  .valueMonth { width: calc(100% / 31); height: 100%; position: relative; bottom: -25px; display: flex; justify-content: center; }
  .containerBar { display: flex; justify-content: flex-start; width: 75%; position: absolute; height: calc(100% - 25px);}
  .bar { background-color: #3E8091; width: 100%; border-radius: 10px; position: absolute; bottom: 0;  }
  .containerMarkDay { width: 25%; height: 25px; width: 100%;  position: absolute; bottom: 0}
  .markDay { width: 50%; height: 25%; border-right: 1px solid black;}
  .numberDay { width: 100%; height: 50%; font-size: 0.6rem; color: black; padding-top: 5px}
  .reference { display: flex; margin-left: 30px; margin-bottom: 20px;}
  .referenceItem { display: flex; margin-right: 20px; line-height: 0.7rem;}
  .referenceItem:nth-child(2) > .referenceColor { background-color: #FF4DCD;}
  .referenceColor { width: 10px; height: 10px; background-color: #3E8091; margin-right: 5px;}
  
  .referenceText { font-size: 0.7rem;}

  .withoutInvoced { min-height: 250px; width: 100%; display: flex; justify-content: center; align-items: center; background-color: white; border-radius: 16px;}

    /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .psHomeInvoiced { margin-left: 0; width: 100%; height: auto; flex-direction: column-reverse; margin-top: 20px;}
    .monthNumber { display: none}
    .month { width: 30%}
  }

</style>