<template>
  <div class="psPatientBtnNewMedication">
    <el-button circle style="border: 2px solid black; margin: 4px 0; color: black; font-weight: bold;" size="mini" @click="newMedication">
      <i class="el-icon-plus" style="font-weight: 900"></i>
    </el-button>
    <span class="tooltip">Nueva medicación</span>    
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'PsPatientBtnNewMedication',
  computed: {
    ...mapState('patients', ['patient'])
  },
  methods: {
    ...mapMutations('patients', ['PATIENTS_DIALOG_MEDICATION', 'PATIENTS_LOADING', 'PATIENTS_ITEM_MEDICATION']),
    async newMedication () {
      this.PATIENTS_DIALOG_MEDICATION(true)
      this.PATIENTS_ITEM_MEDICATION({})
    }
  }
}
</script>

<style scoped>
  .psPatientBtnNewMedication { position: relative; margin-left: 15px;}
  .tooltip { 
    visibility: hidden;
    width: 180px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    text-align: center;
    padding: 4px 0;
    border-radius: 12px;
    position: absolute;
    z-index: 10;
    font-size: 0.8rem;
    left: 5px;
    top: -30px
  }

  .psPatientBtnNewMedication:hover .tooltip {
    visibility: visible;
  }

  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .tooltip { display: none;}    
  }
</style>