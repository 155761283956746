<template>
  <div class="groupsDetail">
    <ps-groups-detail-upper-bar :site="{ site: 'Nuevo grupo' }" icon="el-icon-edit" @clickIcon="editProfile"></ps-groups-detail-upper-bar>
    <div class="container">
      <ps-groups-detail-header v-if="editionMode == true"></ps-groups-detail-header>
      <ps-groups-detail-daily v-if="$route.params['groupId'] > 0"></ps-groups-detail-daily>
    </div>
  </div>
</template>

<script>
import PsGroupsDetailUpperBar from './widgets/PsGroupsDetailUpperBar.vue'
import PsGroupsDetailHeader from './widgets/PsGroupsDetailHeader.vue'
import PsGroupsDetailDaily from './widgets/PsGroupsDetailDaily.vue'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsGroupsDetail',
  components: { PsGroupsDetailUpperBar, PsGroupsDetailHeader, PsGroupsDetailDaily },
  computed: {
    ...mapState('groups', ['editionMode'])
  },
  methods: {
    ...mapMutations('groups', ['GROUPS_GROUP', 'GROUPS_EDITION_MODE', 'GROUPS_SELECTED_DATE']),
    ...mapActions('groups', ['getGroupHTTP']),
    ...mapActions('phases', ['getPhasesHTTP']),
    editProfile () {
      this.GROUPS_EDITION_MODE(!this.editionMode)
    },
  },
  async created () {

    this.GROUPS_SELECTED_DATE(new Date())

    if( this.$route.params['groupId'] == 0) {
      this.GROUPS_GROUP({})
      this.GROUPS_EDITION_MODE(true)
    } else {
      await this.getGroupHTTP(this.$route.params['groupId'])
    }
    await this.getPhasesHTTP()

  }
}
</script>

<style scoped>
  .groupsDetail { height: 100%; width: 100%}
  .container { height: 80%; margin-top: 30px}

  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .container { width: 100%; overflow: hidden;}
  }
</style>