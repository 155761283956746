<template>
  <div class="psPatientChartTrend">
    <div class="container">
      <div class="column">
        <line-chart 
          :chart-data="chartData" 
          :chart-options="optionsChart" 
          :width="null"
          :height="null"
          id="chart"
        />
        <el-button @click="getChartWeightsHTTP" icon="el-icon-printer" style="margin-top: 20px">Imprimir</el-button>
      </div>
    </div>
    <div class="column">
      <el-table :data="patient.lastWeights.data" style="width: 310px" size="mini">
        <el-table-column prop="date" label="Fecha" width="150" :formatter="dateFormat"></el-table-column>
        <el-table-column prop="weight" label="Peso (kg)" width="150"></el-table-column>
      </el-table>
      <el-button @click="showAllWeights">Ver todos</el-button>
    </div>
    
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { Line as LineChart } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement} from 'chart.js'
import chartTrendline from "chartjs-plugin-trendline";
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement, chartTrendline, ChartDataLabels)

export default {
  name: 'PsPatientChartTrend',
  data () {
    return {
      optionsChart: {
        responsive: true,
        aspectRatio: 1.5,
        maintainAspectRatio: false
      },
      plugins: [ ]
    }
  },
  components: { LineChart } ,
  computed: {
    ...mapState('patients', ['patient']),
    chartData () {
      return {
        labels: [...this.patient.lastWeights.labels],
        datasets: [
          {
            label: 'Kgs',
            data: [...this.patient.lastWeights.weights],
            backgroundColor: ['rgba(255, 99, 132, 0.2)',],
            borderColor: ['rgba(255, 99, 132, 1)',],
            borderWidth: 2,
            order: 10,
            trendlineLinear: {
              style: "#95B3D7",
              lineStyle: "line",
              width: 4
            },
            datalabels: {
              anchor: 'end',
              align: 'top',
              font: {
                weight: 'bold'
              }
            }
          }
        ],
        plugins: [ChartDataLabels]
      }
    }
  },
  methods: {
    ...mapMutations('patients', ['PATIENTS_DRAWER_ALL_WEIGHTS']),
    ...mapActions('patients', ['getChartWeightsHTTP', 'getAllWeightsPatientHTTP']),
    dateFormat (row) {
      return `${row.date.substring(0,2)}/${row.date.substring(2,4)}/${row.date.substring(4,8)}`
    },
    showAllWeights () {
      this.PATIENTS_DRAWER_ALL_WEIGHTS(true);
      this.getAllWeightsPatientHTTP({page: 0});
    }
  }
}
</script>

<style scoped>
  .psPatientChartTrend { display: flex; background-color: white; border-radius: 20px; padding: 24px; margin-top: 30px;}

  .container { width: 700px; margin-right: 60px; }
  .container > .column { margin-right: 0;}

  #chart { width: 100%; height: 300px;}

  @media (max-width: 991.98px) {
    .psPatientChartTrend { flex-direction: column;}
    .container { width: 100%; margin: 0 }
  }
</style>