import api from "../services/api"
import {addProperyIfNotExists} from "../services/utils"

const state = {
  wayToPay: {},
  newWayToPay: {
    wayToPayId: 0,
    wayToPayDescription: '',
    active: true,
    createdBy: 0,
    updatedBy: 0,
    createdAt: '',
    updatedAt: ''
  },
  waysToPay: [],
  errors: []

}

const mutations = {
  WAYS_TO_PAY_WAYS_TO_PAY ( state, waysToPay ) { state.waysToPay = waysToPay },
  WAYS_TO_PAY_WAY_TO_PAY ( state, wayToPay ) { state.wayToPay = addProperyIfNotExists(wayToPay, state.newWayToPay) },
  WAYS_TO_PAY_WAY_TO_PAY_PROP ( state, payload ) { state.wayToPay[payload.property] = payload.value },
  WAYS_TO_PAY_EMPTY_ERRORS ( state ) { state.errors = [] },
  WAYS_TO_PAY_ADD_ERROR ( state, error ) { state.errors.push(error)}
}

const actions = {
  async getWaysToPayHTTP ( { commit }) {
    const rates = await api.get('/configuration/waysToPay')
    commit('WAYS_TO_PAY_WAYS_TO_PAY', rates.data.data)
  },
  async saveWayToPay ( { state, commit, dispatch } ) {
    commit('WAYS_TO_PAY_EMPTY_ERRORS')
    if( state.wayToPay.wayToPayDescription == '') commit('WAYS_TO_PAY_ADD_ERROR', 'La descripción de la forma de pago es requerida')

    if( state.errors.length == 0 ) {
      if( state.wayToPay.wayToPayId == 0 ) {
        return await dispatch('createWayToPayHTTP')
      } else {
        return await dispatch('updateWayToPayHTTP')
      }
    }
  },
  async createWayToPayHTTP ( { state, rootState } ) {
    let wayToPay = state.wayToPay
    wayToPay.createdBy = rootState.user.userId
    await api.post('/configuration/waysToPay', wayToPay )
    return 'Forma de pago creada'
  },
  async updateWayToPayHTTP ( { state, rootState } ) {
    let wayToPay = state.wayToPay
    wayToPay.createdBy = rootState.user.userId
    wayToPay.updatedBy = rootState.user.userId
    await api.put('/configuration/waysToPay', wayToPay )
    return 'Forma de pago actualizada'
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}