let state = {
  routeSite: {},
  itemsMenu: [
    { title: 'Home', icon: 'icon-home', active: true, route: '/home/indicators', permissionCode: 1 },
    { title: 'Pacientes', icon: 'icon-user', active: false, route: '/patients/list', permissionCode: 2 },
    { title: 'Grupos', icon: 'icon-menu-dots', active: false, route: '/groups/list', permissionCode: 2 },
    { title: 'Administración', icon: 'icon-database', active: false, route: '/administration/panel', permissionCode: 1 },
    { title: 'Calendario', icon: 'icon-calendar-dates', active: false, route: '/calendar/grid', permissionCode: 1 },
    { title: 'Configuración', icon: 'icon-settings', active: false, route: '/configuration/panel', permissionCode: 1 }
  ]
}

let mutations = {
  ROUTE_SITE ( state, route ) { state.routeSite = JSON.parse(JSON.stringify(route))},
  ACTIVATE_ITEM_MENU ( state, payload ) {
    state.itemsMenu.forEach( (item, index) => {
      return item.title.toLowerCase() == payload.toLowerCase() ? state.itemsMenu[index].active = true : state.itemsMenu[index].active = false
    })
  }
}

let actions = {
}
let getters = {}


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}