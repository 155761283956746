<template>
  <div class="psConfigurationRates">
    <ps-upper-bar :site="{ site: 'Configuración - Tarifas' }" :backButton="true"></ps-upper-bar>
    <div class="container">
      <div class="row">
        <el-button round plain icon="el-icon-plus" @click="addNewRate" :disabled="newRate">Nueva tarifa</el-button>
      </div>
      <div class="row rowData">
        <div class="column w-25">
          <div class="label">Descripcion</div>
          <el-input 
            :value="rate.rateDescription" 
            @input="changeProperty($event, 'rateDescription')" 
            placeholder="Ingrese una descripción"
            :disabled="!newRate"></el-input>
        </div>
        <div class="column">
          <div class="label">Valor</div>
          <el-input-number 
            :value="rate.rateValue" 
            @input="changeProperty($event, 'rateValue')" 
            placeholder="Ingrese un valor"
            :min="0" :controls="false" :step="1"
            :disabled="!newRate"></el-input-number>
        </div>
        <div class="column">
          <div class="label">Activa</div>
          <el-switch :value="rate.active" @input="changeProperty($event, 'active')" :disabled="!newRate"></el-switch>
        </div>
        <div class="column">
          <div class="label">Grupo de tarifas</div>
          <el-select 
            :value="rate.ratesGroupDescription" 
            @change="changeProperty($event, 'ratesGroup')"
            placeholder="Seleccionar grupo de tarifas"
            clearable
            @clear="clearRatesGroup"
            :disabled="!newRate"
          >
            <el-option v-for="(rateGroup, index) in ratesGroup" :key="index" :value="rateGroup" :label="rateGroup.description"></el-option>
          </el-select>
        </div>
        <div class="column">
          <div class="label">Tarifa principal</div>
          <el-switch :value="rate.ratePrincipal" @input="changeProperty($event, 'ratePrincipal')" :disabled="principalDisabled"></el-switch>
        </div>
      </div>
      <div class="row rowHistory">
        <el-collapse v-model="activeNames" @change="showHistoryRates">
          <el-collapse-item title="Historico" name="1" :disabled="!newRate">
            <div class="itemHistoryRate" v-for="(item, index) in historyRates" :key="index">Desde: {{item.createdAt | formatDatetimeDDMMYYYYhhmm}} - {{item.rateValue | money}} <span class="stateRate" :class="{active: item.active}">{{item.active == true ? 'Activa' : 'Inactiva' }}</span></div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="row rowButtons">
        <el-button round plain type="success" :disabled="!saveDisabled" @click="save">Guardar</el-button>
        <el-button round plain type="danger" :disabled="!newRate" @click="cancelRate">Cancelar</el-button>
      </div>

      <el-table 
        :data="rates"
        style="width: 800px;" 
        :highlight-current-row="true" 
        size="mini" 
        height="170" 
        empty-text="No hay tarifas ingresadas"
        @cell-dblclick="selectRow">
        <el-table-column prop="rateId" label="Código" width="80" class-name="rateId" id="rateId" v-if="!isMobile"></el-table-column>
        <el-table-column prop="rateDescription" label="Descripción" :width="isMobile == true ? '' : 300" ></el-table-column>
        <el-table-column prop="rateValue" label="Valor" :width="isMobile == true ? '' : 120"  :formatter="formatMoney"></el-table-column>
        <el-table-column prop="ratesGroupDescription" label="Grupo de tarifas" v-if="!isMobile" width="160" sortable :sort-method="sortRatesGroup" ></el-table-column>
        <el-table-column prop="ratePrincipal" label="Principal" v-if="!isMobile" width="120" >
          <template slot-scope="scope" v-if="scope.row.ratePrincipal == true">
            <span>1°</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import PsUpperBar from '@/components/layout/PsUpperBar.vue'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'PsConfigurationRates',
  components: { PsUpperBar },
  data () {
    return {
      activeNames: [],
      newRate: false
    }
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapState('rates', ['rates', 'rate', 'errors', 'historyRates']),
    ...mapState('ratesGroup', ['ratesGroup']),
    saveDisabled () {
      return this.newRate && this.rate.rateDescription != '' & this.rate.rateValue > 0 
    },
    principalDisabled () {
      return this.newRate == false || !(this.rate.ratesGroupId > 0)
    }
  },
  methods: {
    ...mapMutations('rates', ['RATES_RATE', 'RATES_RATE_PROP', 'RATES_HISTORY_RATES']),
    ...mapActions('rates', ['saveRate', 'getRatesHTTP', 'getHistoryRatesHTTP']),
    ...mapActions('ratesGroup', ['getRatesGroupHTTP']),
    formatMoney ( row, column, cellValue ) {
      return `$ ${cellValue}`
    },
    selectRow ( row ) {
      if( row ) {
        this.RATES_HISTORY_RATES([])
        this.activeNames = []
        this.newRate = true
        console.log(row)
        this.RATES_RATE(JSON.parse(JSON.stringify(row)))
      }
    },
    changeProperty ( value, property ) {
      if( property == 'ratesGroup' ) {
        this.RATES_RATE_PROP({property: 'ratesGroupId', value: value.id });
        this.RATES_RATE_PROP({property: 'ratesGroupDescription', value: value.description });
      } else {
        this.RATES_RATE_PROP({property, value});
      }
    },
    addNewRate () {
      this.newRate = true
      this.RATES_RATE({})
    },
    cancelRate () {
      this.newRate = false
      this.RATES_RATE({})
      this.RATES_HISTORY_RATES([])
    },
    async save () {
      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Guardando...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        }); 

        const message = await this.saveRate()
        if(this.errors.length > 0 ) {
          this.showValidationAlert()
        } else {
          this.cancelRate()
          this.$message( { message, type: 'success' } )
          this.getRatesHTTP()
          this.cancelRate()
        }
    } catch ( err ) { console.log({err}); this.$message( { message: err.response.data.err.details, type: 'error' })}
      finally { loading.close(); }
    },
    showValidationAlert () {      
      let str = '<p>';
      if(this.errors.length > 0) {
        this.errors.forEach(err => str += `${err}<br>`)
        str = str + '</p>'
        this.$alert(str, '', {confirmButtonText: 'Entendido', type: 'warning', dangerouslyUseHTMLString: true})
      }
    },
    async showHistoryRates () {
      await this.getHistoryRatesHTTP()
    },
    clearRatesGroup() {
      this.changeProperty({id:0, description: ''}, 'ratesGroup');
      this.changeProperty(false, 'ratePrincipal');
    },
    sortRatesGroup(a, b) {
      return a.ratesGroupDescription || '' > b.ratesGroupDescription || '' ? 1 : -1;
    }
  },
  created () {
    this.RATES_RATE({});
    this.getRatesHTTP();
    this.getRatesGroupHTTP();
  }
}
</script>

<style scoped>
  .psConfigurationRates { min-height: 90%;}
  .container { background-color: white; border-radius: 20px; padding: 32px; min-height: 90%; overflow-y: scroll; height: 100%; }
  .container::-webkit-scrollbar { display: none}
  .rowData { margin: 40px 0 20px}
  .rowHistory { margin-bottom: 20px;}
  .el-collapse { width: 500px }
  .itemHistoryRate { text-align: left;}
  .stateRate { color: red; margin-left: 15px}
  .active { color: green}


  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .container { padding: 32px 16px}
    .rowData { flex-direction: column;}
    .rowData .column { margin-right: 0 }
    .rowData .column .el-input-number, .rowData .w-25 { width: 100%; margin-bottom: 8px; }
    .rowButtons { justify-content: space-evenly;}
  }
</style>