<template>
  <div class="psPatientDetailPersonal">
    <!--  First line -->
    <div class="row rowAvatar">
      <div class="column w-50 columnAvatar">
        <div class="row rowNameSurname">
          <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :http-request="avatarUpload"
            :before-upload="avatarBeforeUpload"
            :disabled="!editionMode"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div class="divDisabled" v-if="!editionMode"></div>
          </el-upload>
          <div class="column" v-if="!editionMode">
            <div
              class="nameSurname"
              :class="patient.debtor == true ? 'debtorColor' : ''"
            >
              {{ patient.name | textCapitalize }}
              {{ patient.surname | textCapitalize }}
            </div>
            <div class="group">{{ patient.groupDescription }} - {{ age }}</div>
          </div>
        </div>
      </div>

      <div class="column w-33 columnSwitchs">
        <div class="column">
          <div class="label">Último mes</div>
          <el-switch
            :value="patient.lastMonth"
            @input="changeProperty($event, 'lastMonth')"
            :disabled="!editionMode"
          >
          </el-switch>
        </div>
        <div class="column">
          <div class="label">Activo</div>
          <el-switch
            :value="patient.active"
            @input="changeProperty($event, 'active')"
            :disabled="!editionMode"
          >
          </el-switch>
        </div>
      </div>
    </div>

    <!-- Second line -->
    <div class="row" v-if="editionMode">
      <div class="column w-33">
        <div class="label">Nombre</div>
        <el-input
          :value="patient.name"
          placeholder="Ingrese el nombre"
          :disabled="!editionMode"
          @input="changeProperty($event, 'name')"
        ></el-input>
      </div>
      <div class="column w-33">
        <div class="label">Apellido</div>
        <el-input
          :value="patient.surname"
          placeholder="Ingrese el apellido"
          :disabled="!editionMode"
          @input="changeProperty($event, 'surname')"
        ></el-input>
      </div>
      <div class="column w-33">
        <div class="label">Grupo</div>
        <el-select
          :value="patient.groupDescription"
          placeholder="Seleccione un grupo"
          :disabled="!editionMode"
          @change="changeProperty($event, 'group')"
        >
          <el-option
            v-for="(group, index) in groups"
            :key="index"
            :label="group.groupDescription"
            :value="group"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <!-- Third line -->
    <div class="row">
      <div class="column w-33">
        <div class="label">Fecha de nacimiento</div>
        <el-input
          :value="patient.dateOfBirth"
          placeholder="dd/MM/yyyy"
          @input="changeProperty($event, 'dateOfBirth')"
          :disabled="!editionMode"
        ></el-input>
      </div>
      <div class="column w-33">
        <div class="row">
          <div class="column w-50">
            <div class="label">Edad</div>
            <el-input :value="age" disabled>
              <template slot="prefix"
                ><i
                  class="el-icon-present"
                  style="line-height: 40px; padding-left: 8px; "
                ></i
              ></template>
            </el-input>
          </div>
          <div class="column w-50">
            <div class="label">DNI</div>
            <el-input
              :value="patient.dni"
              placeholder="Ingrese el DNI"
              :disabled="!editionMode"
              @input="changeProperty($event, 'dni')"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="column w-33">
        <div class="row">
          <div class="column w-50">
            <div class="label">Peso inicio historico</div>
            <el-input-number
              :value="patient.startWeight"
              placeholder="Ingrese el peso"
              :disabled="!editionMode"
              @input="changeProperty($event, 'startWeight')"
              :min="0"
              :controls="false"
            ></el-input-number>
          </div>
          <div class="column w-50">
            <div class="label">Fecha de inicio</div>
            <el-input
              :value="patient.startDate"
              placeholder="dd/MM/yyyy"
              @input="changeProperty($event, 'startDate')"
              :disabled="!editionMode"
            ></el-input>
            <!-- <el-date-picker
              :value="patient.startDate"
              type="date"
              placeholder="Seleccione una fecha"
              range-separator="/"
              format="dd/MM/yyyy"
              :picker-options="pickerOptionsStartDate"
              :default-value="new Date()"
              :disabled="!editionMode"
              @input="changeProperty($event, 'startDate')"
            ></el-date-picker> -->
          </div>
        </div>
      </div>
    </div>

    <!-- Fourth line -->
    <div class="row">
      <div class="column w-33">
        <div class="label">Telefono</div>
        <el-input
          :value="patient.phone"
          placeholder="Ingrese el número de teléfono"
          :disabled="!editionMode"
          @input="changeProperty($event, 'phone')"
        >
          <template slot="prefix"
            ><i
              class="el-icon-phone-outline"
              style="line-height: 40px; padding-left: 8px; "
            ></i
          ></template>
        </el-input>
      </div>
      <div class="column w-33">
        <div class="label">Direccion</div>
        <el-input
          :value="patient.address"
          placeholder="Ingrese la dirección"
          :disabled="!editionMode"
          @input="changeProperty($event, 'address')"
        >
          <template slot="prefix"
            ><i
              class="el-icon-location-outline"
              style="line-height: 40px; padding-left: 8px; "
            ></i
          ></template>
        </el-input>
      </div>
      <div class="column w-33">
        <div class="row">
          <div class="column w-50">
            <div class="label">C.P</div>
            <el-input
              :value="patient.postalCode"
              placeholder="Código postal"
              :disabled="!editionMode"
              @input="changeProperty($event, 'postalCode')"
            ></el-input>
          </div>
          <div class="column w-50">
            <div class="label">Localidad</div>
            <el-input
              :value="patient.locationDescription"
              placeholder="Localidad"
              :disabled="!editionMode"
              @input="changeProperty($event, 'locationDescription')"
            ></el-input>
          </div>
        </div>
      </div>
    </div>

    <!-- Fifth line -->
    <div class="row">
      <div class="column w-33">
        <div class="label">Email</div>
        <el-input
          :value="patient.email"
          placeholder="Ingrese el correo electrónico"
          :disabled="!editionMode"
          @input="changeProperty($event, 'email')"
        >
          <template slot="prefix"
            ><i
              class="el-icon-message"
              style="line-height: 40px; padding-left: 8px; "
            ></i
          ></template>
        </el-input>
      </div>

      <div class="column w-33">
        <div class="row">
          <div class="column w-50">
            <div class="label">Nickname</div>
              <el-input
                :value="patient.nickname"
                placeholder="Ingrese el sobrenombre"
                :disabled="!editionMode"
                @input="changeProperty($event, 'nickname')"
              >
              </el-input>
          </div>
          <div class="column w-50">
            <div class="label">Profesión</div>
              <el-input
                :value="patient.occupation"
                placeholder="Ingrese la profesión"
                :disabled="!editionMode"
                @input="changeProperty($event, 'occupation')"
              >
              </el-input>
          </div>
        </div>
      </div>
      <div class="column w-33">
        <div class="row">
          <div class="column w-50">
            <div class="label">CUIT</div>
            <el-input
              :value="patient.cuit"
              placeholder="Ingrese el CUIT"
              :disabled="!editionMode"
              @input="changeProperty($event, 'cuit')"
            ></el-input>
          </div>
        </div>
      </div>
    </div>

    <!-- Sixth line -->
    <div class="row" v-if="isAdmin">
      <div class="column w-33">
        <div class="row">
          <div class="column w-50">
            <div class="label">Tarifa</div>
            <el-select
              :value="patient.rateDescription"
              placeholder="Seleccione una tarifa"
              :disabled="!editionMode"
              @change="changeProperty($event, 'rate')"
            >
              <el-option
                v-for="(rate, index) in rates"
                :key="index"
                :label="rate.rateDescription"
                :value="rate"
              >
              </el-option>
            </el-select>
          </div>
          <div class="column w-50">
            <div class="label">Mes inicio suscripción</div>
            <el-date-picker
              :value="patient.startSubscription"
              type="month"
              placeholder="Seleccione una fecha"
              format="MM/yyyy"
              :picker-options="pickerOptionsStartSubscriptionDate"
              :default-value="new Date()"
              :disabled="!editionMode"
              @input="changeProperty($event, 'startSubscription')"
            ></el-date-picker>
          </div>
        </div>
      </div>
      <div class="column w-33">
        <div class="row">
          <div class="column w-50">
            <div class="label">% Descuento</div>
            <el-input-number
              :value="patient.discountRate"
              placeholder="Descuento"
              :disabled="!editionMode"
              @input="changeProperty($event, 'discountRate')"
              :step="1"
              :precision="0"
              :controls="false"
              :min="0"
              :max="100"
            ></el-input-number>
          </div>
          <div class="column w-50">
            <div class="label">A Pagar</div>
            <el-input :value="toTurnOff" disabled></el-input>
          </div>
        </div>
      </div>
      <div class="column w-33">
        <div class="row">
          <div class="column w-50">
            <div class="label">Forma de pago</div>
            <el-select
              :value="patient.wayToPayDescription"
              placeholder="Seleccione forma de pago"
              :disabled="!editionMode"
              @change="changeProperty($event, 'wayToPay')"
            >
              <el-option
                v-for="(wayToPay, index) in waysToPay"
                :key="index"
                :label="wayToPay.wayToPayDescription"
                :value="wayToPay"
              ></el-option>
            </el-select>
          </div>
          <div class="column w-50">
            <div class="label">Cuenta</div>
            <el-select
              :value="patient.accountDescription"
              placeholder="Seleccione una cuenta"
              :disabled="!editionMode"
              id="selectAccount"
              @change="changeProperty($event, 'account')"
            >
              <el-option
                v-for="(account, index) in accounts"
                :key="index"
                :label="account.accountDescription"
                :value="account"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>

    <!-- Seventh line -->
    <div class="row" v-if="isAdmin">
      <div class="column w-33">
        <div class="row">
          <div class="column w-50">
            <div class="label">CUIT</div>
            <el-input :value="patient.cuit" placeholder="Ingrese el CUIT" :disabled="!editionMode" @input="changeProperty($event, 'cuit')"></el-input>
          </div>
          <div class="column">
            <div class="label">Factura</div>
            <el-switch :value="patient.takesInvoice" @input="changeProperty($event, 'takesInvoice')" :disabled="!editionMode"></el-switch>
          </div>
        </div>
      </div>
      <div class="column w-33">
        <div class="row rowQuestionnaire">
          <div class="column w-50">
            <div class="label">Cuestionario completo</div>
            <el-radio-group
              :value="patient.questionnaire"
              :disabled="!editionMode"
              @input="changeProperty($event, 'questionnaire')"
            >
              <el-radio-button label="SI"></el-radio-button>
              <el-radio-button label="NO"></el-radio-button>
            </el-radio-group>
          </div>

          <div class="column w-50" v-if="patient.patientId > 0">
            <div class="label">Ficha inicial</div>
            <el-upload
              v-if="patient.startFile == ''"
              class="upload-demo"
              id="initialFile"
              drag
              action=""
              :http-request="initialFileUpload"
              :before-upload="initialFileBeforeUpload"
              :show-file-list="false"
              multiple
              :disabled="!editionMode"
            >
              <i class="el-icon-plus uplaodStartFile"></i>
              <span>Subir ficha</span>
              <div class="divDisabled" v-if="!editionMode"></div>
            </el-upload>
            <div class="containerStartFile" v-else>
              <a
                :href="pathStartFile"
                class="showStartFile"
                :class="{ pointerEvent: !editionMode }"
                target="_blank"
                disabled
                >Ver ficha inicial</a
              >
              <el-button
                class="deleteStartFile"
                icon="el-icon-delete"
                @click.stop="deleteStartFile"
                :disabled="!editionMode"
              ></el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="column w-33">&nbsp;</div>
    </div>

    <ps-patient-subscription-history
      v-if="isAdmin"
    ></ps-patient-subscription-history>

    <!-- Eighth line -->
    <div class="row rowButton">
      <div class="column w-33 columnButton">
        <el-button
          type="primary"
          round
          @click="save"
          :disabled="!disabledSaveButton"
          >Guardar</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import PsPatientSubscriptionHistory from "./PsPatientSubscriptionHistory.vue";

export default {
  name: "PsPatientDetailPersonal",
  components: { PsPatientSubscriptionHistory },
  data() {
    return {
      pickerOptionsBirthday: {
        disabledDate(time) {
          return (
            time.getTime() >
            new Date(
              new Date().getFullYear() - 16,
              new Date().getMonth(),
              new Date().getDate()
            )
          );
        }
      },
      pickerOptionsStartDate: {
        disabledDate(time) {
          return time.getTime() > new Date(new Date().getTime() + 2592000000); // + 1mes
        }
      },
      pickerOptionsStartSubscriptionDate: {
        disabledDate(time) {
          return (
            time.getTime() > new Date(new Date().getTime() + 2592000000 * 3)
          ); // + 1mes
        }
      }
    };
  },
  computed: {
    ...mapState("patients", ["editionMode", "patient", "errors"]),
    ...mapState("groups", ["groups"]),
    ...mapState("rates", ["rates"]),
    ...mapState("waysToPay", ["waysToPay"]),
    ...mapState("accounts", ["accounts"]),
    ...mapState("socialsWorkers", ["oss"]),
    ...mapGetters(["isAdmin"]),
    age() {
      if (this.patient.dateOfBirth && this.patient.dateOfBirth != '00-00-0000') {
        let dateOfBirth;
        const [day, month, year] = this.patient.dateOfBirth.split('/');

        if (!day || day < 1 || day > 31 || !month || month < 1 || month > 12 || !year || year < 1930) {
          return '';
        }

        dateOfBirth =  new Date(year, month - 1, day);

        var ageDifMs = Date.now() - dateOfBirth.getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        return `${Math.abs(ageDate.getUTCFullYear() - 1970)} años`;
      } else {
        return "";
      }
    },
    toTurnOff() {
      return this.patient.total
        ? this.$options.filters.money(this.patient.total)
        : "0.00";
    },
    imageUrl() {
      return this.patient.pathImagePatient;
    },
    pathStartFile() {
      return `${process.env.VUE_APP_PATH_START_FILE}/${this.patient.patientId}/${this.patient.startFile}`;
    },
    disabledSaveButton() {
      return (
        this.editionMode &&
        this.patient.name &&
        this.patient.name != "" &&
        this.patient.surname &&
        this.patient.surname != "" &&
        this.patient.rateId &&
        this.patient.rateId > 0 &&
        this.patient.startDate &&
        this.patient.startDate != ""
      );
    }
  },
  methods: {
    ...mapMutations("patients", [
      "PATIENTS_PATIENT_PROP",
      "PATIENTS_EDITION_MODE"
    ]),
    ...mapActions("patients", [
      "savePatient",
      "avatarUploadHTTP",
      "initialFileUploadHTTP"
    ]),
    ...mapActions("calendar", ["updateLastMonthPatientHTTP"]),
    handlePreview(file) {
      let isAcceptedType =
        file.type === "application/pdf" ||
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "application/postscript";

      if (file.type == "") {
        let extension = file.name.split(".");
        isAcceptedType =
          extension[extension.length - 1] == "psd" ? true : isAcceptedType;
      }

      const isLt100M = file.size / 1024 / 1024 < 100;

      if (!isAcceptedType) {
        this.$message.error(
          "El archivo debe ser un .PDF, .JPG, .PNG, .AI, .PSD!"
        );
      }
      if (!isLt100M) {
        this.$message.error("El archivo no puede exceder el tamaño de 100 Mb!");
      }
      return isAcceptedType && isLt100M;
    },
    handleRemove() {
      this.$alert(
        `Sólo puede seleccionarse un archivo. Puede reemplazar al actual eliminándolo primero`,
        "Alerta",
        { confirmButtonText: "Ok" }
      );
    },
    async avatarUpload(file) {
      let loading;
      try {
        loading = this.$loading({
          lock: true,
          text: "Subiendo imagen...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });

        await this.avatarUploadHTTP(file);
        this.$message({ message: "Imagen subida con éxito!", type: "success" });
      } catch (err) {
        this.$message({
          message: "Error al subir la imagen. Inténtelo nuevamente",
          type: "danger"
        });
      } finally {
        loading.close();
      }
    },
    avatarBeforeUpload(file) {
      let isAcceptedType =
        file.type === "image/png" || file.type === "image/jpeg";

      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isAcceptedType) {
        this.$message.error("El archivo debe ser un .JPG o .PNG!");
      }
      if (!isLt10M) {
        this.$message.error("El archivo no puede exceder el tamaño de 10 Mb!");
      }
      return isAcceptedType && isLt10M;
    },
    async initialFileUpload(file) {
      let loading;
      try {
        loading = this.$loading({
          lock: true,
          text: "Subiendo ficha inicial...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });

        await this.initialFileUploadHTTP(file);
        this.$message({ message: "Ficha subida con éxito!", type: "success" });
      } catch (err) {
        this.$message({
          message: "Error al subir la ficha. Inténtelo nuevamente",
          type: "danger"
        });
      } finally {
        loading.close();
      }
    },
    initialFileBeforeUpload(file) {
      let isAcceptedType =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "application/pdf" ||
        file.type ==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type == "application/msword";

      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isAcceptedType) {
        this.$message.error(
          "El archivo debe ser un .JPG, .PNG, .DOC, .DOCX o PDF!"
        );
      }
      if (!isLt5M) {
        this.$message.error("El archivo no puede exceder el tamaño de 5 Mb!");
      }
      return isAcceptedType && isLt5M;
    },
    changeProperty(value, property) {
      
      if ( (property == "dateOfBirth" || property == 'startDate') && value && value != "") {
        if (value.length > 10) {
          return;
        }

        if (value.length == 3 || value.length == 6) {
          if (value.length >= this.patient[property].length) {
            const last = value.split("")[value.length - 1];
            if (value.length - 1 != "/") {
              value = `${value.substring(0, value.length - 1)}/${last}`;
            }
          }
        }
      }

      if (property == "group") {
        this.PATIENTS_PATIENT_PROP({
          property: "groupId",
          value: value.groupId
        });
        this.PATIENTS_PATIENT_PROP({
          property: "groupDescription",
          value: value.groupDescription
        });
      } else if (property == "rate") {
        this.PATIENTS_PATIENT_PROP({ property: "rateId", value: value.rateId });
        this.PATIENTS_PATIENT_PROP({
          property: "rateDescription",
          value: value.rateDescription
        });
        this.PATIENTS_PATIENT_PROP({
          property: "rateValue",
          value: value.rateValue
        });
        if (this.patient.discountRate >= 0 && value.rateValue > 0) {
          this.PATIENTS_PATIENT_PROP({
            property: "total",
            value:
              Number(value.rateValue) -
              Number(value.rateValue) *
                (Number(this.patient.discountRate) / 100)
          });
        } else {
          this.PATIENTS_PATIENT_PROP({
            property: "total",
            value: Number(value.rateValue)
          });
        }
      } else if (property == "discountRate") {
        if (value >= 0 && this.patient.rateValue > 0) {
          this.PATIENTS_PATIENT_PROP({ property, value });
          this.PATIENTS_PATIENT_PROP({
            property: "total",
            value:
              Number(this.patient.rateValue) -
              Number(this.patient.rateValue) * (Number(value) / 100)
          });
        } else {
          this.PATIENTS_PATIENT_PROP({ property, value: 0 });
          this.PATIENTS_PATIENT_PROP({
            property: "total",
            value: Number(this.patient.rateValue)
          });
        }
      } else if (property == "wayToPay") {
        this.PATIENTS_PATIENT_PROP({
          property: "wayToPayId",
          value: value.wayToPayId
        });
        this.PATIENTS_PATIENT_PROP({
          property: "wayToPayDescription",
          value: value.wayToPayDescription
        });
      } else if (property == "account") {
        this.PATIENTS_PATIENT_PROP({
          property: "accountId",
          value: value.accountId
        });
        this.PATIENTS_PATIENT_PROP({
          property: "accountDescription",
          value: value.accountDescription
        });
      } else if (property == "os") {
        this.PATIENTS_PATIENT_PROP({ property: "osId", value: value.osId });
        this.PATIENTS_PATIENT_PROP({
          property: "osDescription",
          value: value.osDescription
        });
        this.PATIENTS_PATIENT_PROP({ property: "osCuit", value: value.osCuit });
      } else if (property == "lastMonth") {
        this.PATIENTS_PATIENT_PROP({ property, value });
        this.updateLastMonthPatientHTTP({
          patientId: this.patient.patientId,
          lastMonth: value
        });
      } else {
        this.PATIENTS_PATIENT_PROP({ property, value });
      }
    },
    async save() {
      let loading;
      try {
        loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });

        const message = await this.savePatient();
        if (this.errors.length > 0) {
          this.showValidationAlert();
        } else {
          this.$message({ message, type: "success" });
          setTimeout(() => this.$router.go(-1), 1000);
        }
      } catch (err) {
        this.$message({
          message: err.response.data.err.details,
          type: "error"
        });
      } finally {
        loading.close();
        this.PATIENTS_EDITION_MODE(false);
      }
    },
    showValidationAlert() {
      let str = "<p>";
      if (this.errors.length > 0) {
        this.errors.forEach(err => (str += `${err}<br>`));
        str = str + "</p>";
        this.$alert(str, "", {
          confirmButtonText: "Entendido",
          type: "warning",
          dangerouslyUseHTMLString: true
        });
      }
    },
    deleteStartFile() {
      this.changeProperty("", "startFile");
    }
  },
  beforeDestroy() {
    this.PATIENTS_EDITION_MODE(false);
  }
};
</script>

<style scoped>
.psPatientDetailPersonal {
  background-color: white;
  border-radius: 20px;
  padding: 32px;
}

.row {
  width: 100%;
}

i.el-icon-upload,
div.el-upload__text,
div.el-upload__tip {
  display: none;
}

.column .column:nth-child(2) {
  margin-right: 0;
}

.rowAvatar {
  justify-content: space-between;
}

.avatar-uploader {
  border: 1px dashed rgb(104, 104, 104);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  height: 90px;
  width: 90px;
  border-radius: 45px;
}

.avatar-uploader .el-upload:hover {
  border-color: black;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 90px;
  height: 90px;
  line-height: 90px;
  text-align: center;
}

.avatar {
  width: 90px;
  height: 90px;
  object-fit: cover;
  display: block;
}

.uplaodStartFile {
  line-height: 36px;
  font-family: "Roboto", sans-serif;
  margin-right: 10px;
}

.upload-demo,
.upload-demo /deep/ .el-upload {
  width: 100%;
}

.upload-demo span {
  font-size: 0.9rem;
}

.upload-demo /deep/ .el-upload-dragger {
  width: 100%;
  height: 40px;
}

.columnSwitchs {
  align-items: flex-end;
}

.columnSwitchs .column {
  align-items: flex-end;
  margin-right: 0;
}

.columnSwitchs .column:nth-child(1) {
  margin-bottom: 20px;
}

.rowNameSurname {
  align-items: center;
  margin-bottom: 10px;
}

.nameSurname {
  font-weight: bold;
  font-size: 24px;
  margin-left: 20px;
}

.group {
  font-weight: 300;
  font-size: 16px;
  margin-left: 20px;
}

.divDisabled {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #efefefc2;
}

.containerStartFile {
  height: 40px;
  width: 100%;
  display: flex;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}

.showStartFile {
  line-height: 40px;
  min-width: 100px;
  color: #606266;
  font-size: 0.9rem;
  cursor: pointer;
  transition: 0.2s ease;
  text-decoration: none;
  width: calc(100% - 34px);
  text-align: center;
}

.showStartFile:hover {
  font-size: 0.95rem;
  font-weight: bold;
  padding-left: 28px;
  border-color: #888;
}

button.el-button.deleteStartFile {
  position: absolute;
  top: -1px;
  right: -1px;
  padding: 12px 10px;
  width: 36px;
}

.pointerEvent {
  pointer-events: none;
  background-color: #e4e7ed;
  color: #c0c4cc;
}

.debtorColor {
  color: red;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .psPatientDetailPersonal {
    padding: 32px 16px;
  }

  .psPatientDetailPersonal > .row {
    flex-direction: column;
    width: 100%;
    margin-bottom: 0;
    padding: 0;
  }

  /* .psPatientDetailPersonal > .row:nth-child(1) { flex-direction: row;} */
  .psPatientDetailPersonal > .row > .column {
    margin-bottom: 8px;
    padding: 0;
  }

  .psPatientDetailPersonal > .row > .column.w-33 {
    width: 100%;
  }

  /* .psPatientDetailPersonal > .row:nth-child(6) > .column.w-33:nth-child(3) > .row { width: 100%; flex-direction: column; margin-bottom: 8px} */
  .psPatientDetailPersonal
    > .row:nth-child(6)
    > .column.w-33:nth-child(3)
    > .row
    > .column {
    margin-bottom: 8px;
  }

  .columnButton {
    padding-right: 0;
    align-items: center;
    margin-top: 25px;
  }

  .rowAvatar {
    flex-direction: column;
  }

  .columnAvatar {
    width: 100%;
    margin-right: 0;
  }

  .rowNameSurname {
    flex-direction: column;
    width: 100%;
  }

  .columnSwitchs {
    flex-direction: row;
    align-items: flex-start;
  }

  .columnSwitchs .column {
    margin-right: 20px;
    margin-bottom: 0;
    align-items: flex-start;
  }

  .rowQuestionnaire {
    flex-direction: column;
  }

  .rowQuestionnaire .w-50 {
    width: 100%;
  }

  .showStartFile {
    flex-grow: 5;
    display: block;
    padding: 0;
  }

  .deleteStartFile {
    flex-grow: 1;
  }
}
</style>
