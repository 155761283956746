<template>
  <div class="psConfigurationUsers">
    <ps-upper-bar :site="{ site: 'Configuración - Usuarios' }" :backButton="true"></ps-upper-bar>
    <div class="container">
      <div class="row">
        <el-button round plain icon="el-icon-plus" @click="addNewUser" :disabled="newUser">Nuevo usuario</el-button>
      </div>
      <div class="row rowData">
        <div class="column w-25">
          <div class="label">Nombre de usuario</div>
          <el-input 
            :value="user.username" 
            @input="changeProperty($event, 'username')" 
            placeholder="Ingrese un nombre de usuario"
            :disabled="!newUser"></el-input>
        </div>
        <div class="column w-25">
          <div class="label">Nombre</div>
          <el-input 
            :value="user.name" 
            @input="changeProperty($event, 'name')" 
            placeholder="Ingrese el nombre"
            :disabled="!newUser"></el-input>
        </div>
        <div class="column w-25">
          <div class="label">Apellido</div>
          <el-input 
            :value="user.surname" 
            @input="changeProperty($event, 'surname')" 
            placeholder="Ingrese el apellido"
            :disabled="!newUser"></el-input>
        </div>
        <div class="column">
          <div class="label">Activo</div>
          <el-switch :value="user.active" @input="changeProperty($event, 'active')" :disabled="!newUser"></el-switch>
        </div>
      </div>
      <div class="row rowPassword" v-if="isAdmin">
        <div class="column" v-if="changePassword == false && user.userId > 0" >
          <el-button @click="changePassword = true">Cambiar contraseña</el-button>
        </div>
        <template v-else>
          <div class="column">
            <div class="label">Contraseña</div>
            <el-input :value="user.password" @input="changeProperty($event, 'password')" placeholder="Ingrese contraseña" show-password :disabled="!newUser"></el-input>
          </div>
          <div class="column">
            <div class="label">Repetir contraseña</div>
            <el-input :value="user.repeatPassword" @input="changeProperty($event, 'repeatPassword')" placeholder="Ingrese contraseña" show-password :disabled="!newUser"></el-input>
          </div>
        </template>
      </div>
      <div class="row rowRole">
        <div class="column">
          <div class="label">Rol</div>
          <el-radio-group :value="user.roleDescription" @input="changeProperty($event, 'role')" :disabled="!newUser">
            <el-radio-button v-for="(role, index) in roles" :key="index" :label="role.roleDescription"></el-radio-button>
          </el-radio-group>
        </div>
        <div class="column">
          <div class="label">Lleva cuenta</div>
          <el-switch :value="user.keepAccount" @change="changeProperty($event, 'keepAccount')" :disabled="!newUser"></el-switch>
        </div>
      </div>
      <div class="row rowButtons">
        <el-button round plain type="success" :disabled="!saveDisabled" @click="verifySave">Guardar</el-button>
        <el-button round plain type="danger" :disabled="!newUser" @click="cancel">Cancelar</el-button>
      </div>

      <el-table 
        :data="users"
        style="width: 900px;" 
        :highlight-current-row="true" 
        size="mini" 
        height="305" 
        empty-text="No hay usuarios ingresadas"
        @cell-dblclick="selectRow">
        <el-table-column prop="userId" label="Código" width="80"  v-if="!isMobile" ></el-table-column>
        <el-table-column prop="username" label="Usuario" :width="isMobile == true ? '' : 150"></el-table-column>
        <el-table-column prop="name" label="Nombre" :width="isMobile == true ? '' : 150"></el-table-column>
        <el-table-column prop="surname" label="Apellido" :width="isMobile == true ? '' : 200" ></el-table-column>
        <el-table-column prop="roleDescription" label="Rol" width="150" v-if="!isMobile" ></el-table-column>
        <el-table-column prop="active" label="Activo" width="150" v-if="!isMobile" >
          <template slot-scope="scope">
            <el-tag size="mini" :type="scope.row.active == true ? 'success' : 'error'">{{scope.row.active == true ? 'Activo': 'Inactivo'}}</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import PsUpperBar from '@/components/layout/PsUpperBar.vue'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  name: 'PsConfigurationUsers',
  components: { PsUpperBar },
  data () {
    return {
      newUser: false,
      changePassword: false
    }
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapState('users', ['users', 'user', 'errors', 'roles']),
    ...mapGetters(['isAdmin']),
    saveDisabled () {
      return this.newUser && this.user.username != '' && this.user.name != '' && this.user.surname != '' && this.user.roleId > 0
    }
  },
  methods: {
    ...mapMutations('users', ['USERS_USER', 'USERS_USER_PROP']),
    ...mapActions('users', ['saveUser', 'getUsersHTTP', 'getRolesHTTP']),
    selectRow ( row ) {
      if( row ) {
        this.newUser = true
        this.USERS_USER(JSON.parse(JSON.stringify(row)))
      }
    },
    changeProperty ( value, property ) {
      if ( property == 'role' ) {
        let role = this.roles.find( role => role.roleDescription == value)
        
        this.USERS_USER_PROP({property: 'roleId', value: role.roleId })
        this.USERS_USER_PROP({property: 'roleDescription', value: role.roleDescription })
      } else {
        this.USERS_USER_PROP({property, value})
      }
    },
    addNewUser () {
      this.newUser = true
      this.USERS_USER({})
    },
    cancel () {
      this.newUser = false
      this.USERS_USER({})
    },
    async verifySave () {
      if( this.changePassword == true && this.user.password != '' && this.user.userId > 0) {
        this.$confirm('¿Está seguro que quiere cambiar la contraseña de este usuario?', 'Confirmación', {
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          type: 'warning'
        })
        .then( () => {
          this.save()
        })
        .catch( () => null)
      } else {
        this.save()
      }
    },
    async save () {

      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Guardando...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        }); 

        const message = await this.saveUser(this.changePassword)
        if(this.errors.length > 0 ) {
          this.showValidationAlert()
        } else {
          this.cancel()
          this.$message( { message, type: 'success' } )
          this.getUsersHTTP()
        }
    } catch ( err ) { this.$message( { message: err.response.data.err.details, type: 'error' })}
      finally { loading.close(); }
    },
    showValidationAlert () {      
      let str = '<p>';
      if(this.errors.length > 0) {
        this.errors.forEach(err => str += `${err}<br>`)
        str = str + '</p>'
        this.$alert(str, '', {confirmButtonText: 'Entendido', type: 'warning', dangerouslyUseHTMLString: true})
      }
    },
  },
  async created () {
    this.USERS_USER({})
    await this.getUsersHTTP()
    await this.getRolesHTTP()
  }
}
</script>

<style scoped>
  .psConfigurationUsers { min-height: 90%;}
  .container { background-color: white; border-radius: 20px; padding: 32px; min-height: 90%; height: 100%; overflow-y: scroll;}
    .container::-webkit-scrollbar { display: none}
  .rowData { margin: 40px 0 20px}
  .rowButtons { margin-top: 20px}

  
  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .container { padding: 32px 16px}
    .rowData, .rowPassword, .rowRole { flex-direction: column;}
    .rowData .column, .rowPassword .column, .rowRole .column { margin-right: 0; margin-bottom: 8px; }
    .rowData .column .el-input-number, .rowData .w-25 { width: 100%; margin-bottom: 8px; }
    .rowButtons { justify-content: space-evenly;}
  }
</style>