<template>
  <div class="psGroupsDetailDaily">
    <div class="row">
      <div class="patientTitle">Pacientes</div>
      <i class="el-icon-circle-plus-outline icon" @click="addPatient" v-if="isAdmin"></i>
    </div>
    <div class="titles">
      <div class="row rowNameSurname">
        <div class="title nameSurname">NOMBRE Y APELLIDO</div>
        <div class="title weight">PESO KG</div>
      </div>
      <div class="row rowOthersDatails">
        <div class="title pictures">FOTOS DE LAS COMIDAS</div>
        <div class="title variation">VARIACIÓN KG</div>
        <div class="title weeklyWeight">PESO DE LA SEMANA</div>
      </div>
    </div>
    <div class="container" v-if="group.patients && group.patients.length > 0">
      <ps-groups-detail-daily-item  v-for="(patient, index) in group.patients" :key="index" :patient="{ ...patient, index }" ></ps-groups-detail-daily-item>
    </div>
    <div class="thereAreNoPatients" v-else>
      No hay pacientes en este grupo
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import PsGroupsDetailDailyItem from './PsGroupsDetailDailyItem.vue'

export default {
  name: 'PsGroupsDetailDaily',
  components: { PsGroupsDetailDailyItem },
  computed: {
    ...mapState('groups', ['group']),
    ...mapGetters(['isAdmin']),
  },
  methods: {
    ...mapMutations('groups', ['GROUPS_DIALOG_PATIENTS']),
    ...mapActions('groups', ['getPatientsGroupHTTP']),
    async addPatient () {
      await this.getPatientsGroupHTTP(this.group.groupId)
      this.GROUPS_DIALOG_PATIENTS(true)
    }
  }
}
</script>

<style scoped>
  .psGroupsDetailDaily { display: flex; flex-direction: column; height: 100%; }
  .container { overflow-y: scroll; height: 78%; padding-bottom: 150px;}
  .container::-webkit-scrollbar {
    display: none;  
  }
  .patientTitle { font-size: 24px; font-weight: bold; text-align: left; margin-bottom: 5px; padding-left: 20px;}
  .titles { display: flex;}
  .titles .title { color: #0290af; font-weight: bold; font-size: 0.9rem; display: flex; margin-bottom: 10px; text-align: center;  display: block;  }
  .title.nameSurname { width: 250px; padding-left: 20px; text-align: left }
  .weight { width: 85px; }
  .pictures { width: 220px; padding-left: 10px}
  .variation { width: 200px;}
  .weeklyWeight { width: 400px;}
  .thereAreNoPatients { margin-top: 70px}

  .icon { line-height: 26px; font-size: 24px; margin-left: 20px; cursor: pointer; transition: 0.2s ease} 
  .icon:hover { font-size: 25px; font-weight: bold;}

      /* Medium devices (tablets, less than 992px) */
  @media (min-width: 1600px) {
    .container { height: 100%;}
  }


    /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .patientTitle { font-size: 20px; padding-left: 0;}
    .container { height: 84%;}
    .title.nameSurname { width: 200px}
    .rowNameSurname { width: 100%; justify-content: space-between;}
    .title.nameSurname { padding-left: 0;}
    .rowOthersDatails {display: none}
  }
</style>