import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './../store'
import Login from '../views/login/Login.vue'
import PsPageNotFound from '../views/PsPageNotFound.vue'

import PsHome from '../views/home/PsHome.vue'
import PsHomeIndicators from '../views/home/pages/PsHomeIndicators.vue'
import PsPatients from '../views/patients/PsPatients.vue'
import PsPatientsList from '../views/patients/pages/PatientsList/PsPatientsList.vue'
import PsPatientsDetail from '../views/patients/pages/PatientsDetail/PsPatientsDetail.vue'
import PsGroups from '../views/groups/PsGroups.vue'
import PsGroupsList from '../views/groups/pages/GroupsList/PsGroupsList.vue'
import PsGroupsDetail from '../views/groups/pages/GroupsDetail/PsGroupsDetail.vue'
import PsAdministration from '../views/administration/PsAdministration.vue'
import PsAdministrationPanel from '../views/administration/pages/panel/PsAdministrationPanel.vue'
import PsAdministrationTransactions from '../views/administration/pages/transactions/PsAdministrationTransactions.vue'
import PsAdministrationSubscriptions from '../views/administration/pages/subscriptions/PsAdministrationSubscriptions.vue'
import PsCalendar from '../views/calendar/PsCalendar.vue'
import PsCalendarGrid from '../views/calendar/pages/CalendarGrid/PsCalendarGrid.vue'
import PsConfiguration from '../views/configuration/PsConfiguration.vue'
import PsConfigurationPanel from '../views/configuration/pages/panel/PsConfigurationPanel.vue'
import PsConfigurationAccounts from '../views/configuration/pages/accounts/PsConfigurationAccounts.vue'
import PsConfigurationRates from '../views/configuration/pages/rates/PsConfigurationRates.vue'
import PsConfigurationRatesGroup from '../views/configuration/pages/ratesGroup/PsConfigurationRatesGroup.vue'
import PsConfigurationSocialsWorkers from '../views/configuration/pages/socialsWorkers/PsConfigurationSocialsWorkers.vue'
import PsConfigurationUsers from '../views/configuration/pages/users/PsConfigurationUsers.vue'
import PsConfigurationWaysToPay from '../views/configuration/pages/waysToPay/PsConfigurationWaysToPay.vue'
import PsConfigurationDiseases from '../views/configuration/pages/diseases/PsConfigurationDiseases.vue'
import PsConfigurationEventuals from '../views/configuration/pages/eventuals/PsConfigurationEventuals.vue'
import PsConfigurationPhases from '../views/configuration/pages/phases/PsConfigurationPhases.vue'
import PsConfigurationConcepts from '../views/configuration/pages/concepts/PsConfigurationConcepts.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Login', component: Login },
  {
    path: '/home', name: 'Home', component: PsHome, children: [
      { path: 'indicators', component: PsHomeIndicators }
    ]
  },
  {
    path: '/patients', name: 'Patients', component: PsPatients, children: [
      { path: 'list', component: PsPatientsList },
      { path: 'detail/:patientId', component: PsPatientsDetail }
    ]
  },
  {
    path: '/groups', name: 'Groups', component: PsGroups, children: [
      { path: 'list', component: PsGroupsList },
      { path: 'detail/:groupId', component: PsGroupsDetail }
    ]
  },
  {
    path: '/administration', name: 'Administration', component: PsAdministration, children: [
      { path: 'panel', component: PsAdministrationPanel },
      { path: 'transactions', component: PsAdministrationTransactions },
      { path: 'subscriptions', component: PsAdministrationSubscriptions }
    ]
  },
  {
    path: '/calendar', name: 'Calendar', component: PsCalendar, children: [
      { path: 'grid', component: PsCalendarGrid }
    ]
  },
  {
    path: '/configuration', name: 'Configuration', component: PsConfiguration, children: [
      { path: 'panel', component: PsConfigurationPanel },
      { path: 'accounts', component: PsConfigurationAccounts },
      { path: 'rates', component: PsConfigurationRates },
      { path: 'ratesGroup', component: PsConfigurationRatesGroup },
      { path: 'socialsWorkers', component: PsConfigurationSocialsWorkers },
      { path: 'users', component: PsConfigurationUsers },
      { path: 'waysToPay', component: PsConfigurationWaysToPay },
      { path: 'diseases', component: PsConfigurationDiseases },
      { path: 'eventuals', component: PsConfigurationEventuals },
      { path: 'phases', component: PsConfigurationPhases },
      { path: 'concepts', component: PsConfigurationConcepts },
    ]
  },
  { path: '*', component: PsPageNotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path != '/') {
    store.dispatch('activeSession', localStorage.getItem('user'))
      .then(() => {
        next()
      })
      .catch(() => { next('/') })
  } else {
    next()
  }
})


export default router
