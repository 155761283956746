<template>
  <div class="psPatientSubscriptionHistory">
    <el-collapse  v-model="activeNames">
      <el-collapse-item title="Historial de suscripciones" name="1">
        <div class="row">
          <el-button icon="el-icon-circle-plus-outline" @click="newSubscription">Nuevo suscripción</el-button>
        </div>
        <div class="titles">
          <div class="id"> ID </div>
          <div class="year"> Año </div>
          <div class="month"> Mes </div>
          <div class="status"> Estado </div>
          <div class="amount"   v-if="isMobile == false"> Importe </div>
          <div class="edit"></div>
        </div>

        <div class="contentItems">
          <ps-patient-subscription-history-item v-for="(item, index) in patient.subscriptionsHistory" :key="index" :item="item"></ps-patient-subscription-history-item>
        </div>
      </el-collapse-item>
      <el-collapse-item title="Historial de pagos" name="2">
        <div class="row">
          <el-button icon="el-icon-circle-plus-outline" @click="newPaymentEvent">Nuevo pago</el-button>
        </div>
        <div class="titles">
          <div class="id" v-if="isMobile == false"> ID </div>
          <div class="date"> Fecha </div>
          <div class="amount"> Importe </div>
          <div class="wayToPay"  v-if="isMobile == false"> Forma de pago </div>
          <div class="account"> Cuenta </div>
          <div class="edit"></div>
        </div>

        <div class="contentItems">
          <ps-patient-payment-history-item v-for="(item, index) in patient.paymentsHistory" :key="index" :item="item" :index="index"></ps-patient-payment-history-item>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import PsPatientSubscriptionHistoryItem from './PsPatientSubscriptionHistoryItem.vue'
import PsPatientPaymentHistoryItem from './PsPatientPaymentHistoryItem.vue'

export default {
  name: 'PsPatientSubscriptionHistory',
  components: { PsPatientSubscriptionHistoryItem, PsPatientPaymentHistoryItem },
  data () {
    return {
      activeNames: ['1','2']
    }
  },
  computed: {
    ...mapState('patients', ['patient', 'newPayment']),
    ...mapState(['isMobile'])
  },
  methods: {
    ...mapMutations('patients', ['PATIENTS_ITEM_MEDICATION_PROP', 'PATIENTS_DIALOG_MEDICATION', 'PATIENTS_PAYMENT', 'PATIENTS_DIALOG_PAYMENT',
    'PATIENTS_SUBSCRIPTION', 'PATIENTS_DIALOG_SUBSCRIPTION']),
    ...mapActions('patients', ['saveMedicationPatient', 'getPatientHTTP', 'deleteMedicationPatientHTTP']),
    async saveMedication () {
      let loading
      try {
        loading = this.$loading({
          lock: true,
          text: 'Guardando...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });   

        const message = await this.saveMedicationPatient()
        this.$message( { message, type: 'success' } )
        setTimeout( async () => {
          await this.getPatientHTTP( this.$route.params['patientId'])
          loading.close()
          this.PATIENTS_DIALOG_MEDICATION(false)
        }, 1000)
      } catch ( err ) {  this.$message( { message: err.response.data.err.details, type: 'error' })}
      finally { loading.close()}
     
    },
    async deleteMedication () {
      let loading
      try {
        this.$confirm('¿Quiere eliminar esta medicación?', 'Confirmación', {
          type: 'warning',
          confirmButtonText: 'Si',
          cancelButtonText: 'No'
        })
        .then( async () => {
          loading = this.$loading({
            lock: true,
            text: 'Eliminando...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          }); 
          const message = await this.deleteMedicationPatientHTTP()
          setTimeout( async () => {
            await this.getPatientHTTP( this.$route.params['patientId'])
            this.PATIENTS_DIALOG_MEDICATION(false)
            loading.close()
            this.$message.success(message)
          }, 1000)
        })
      } catch ( err ) { this.$message.error('No se pudo eliminar esta medicación.')}
      finally { loading.close()}
    },
    newPaymentEvent () {
      let payment = Object.assign({},this.newPayment)
      if( payment.wayToPayId == 0) {
        payment.wayToPayId = this.patient.wayToPayId
        payment.wayToPayDescription = this.patient.wayToPayDescription
      }
      
      if( payment.accountId == 0) {
        payment.accountId = this.patient.accountId
        payment.accountDescription = this.patient.accountDescription
      }
      this.PATIENTS_PAYMENT(payment)
      this.PATIENTS_DIALOG_PAYMENT(true)
    },
    newSubscription () {
      let discountAmount = Number(this.patient.rateValue) * Number(this.patient.discountRate) / 100
      this.PATIENTS_SUBSCRIPTION({
        discountRate: this.patient.discountRate,
        id: 0,
        month: null,
        patientId: this.patient.patientId,
        rateId: this.patient.rateId,
        rateValue: this.patient.rateValue,
        statusPaidOut: 1,
        surcharge: 0,
        total: Number(this.patient.rateValue) - discountAmount,
        year: 0,
        assignment: 0
      })
      this.PATIENTS_DIALOG_SUBSCRIPTION(true)
    },
  }
}
</script>

<style scoped>
  .psPatientSubscriptionHistory { margin-top: 30px; margin-bottom: 30px;}
  .titles { display: flex; color: #828282; text-align: left; border-bottom: 1px solid #ddd; padding: 5px 0}
  .contentItems { height: 110px; overflow-y: scroll;}
  .contentItems::-webkit-scrollbar {
    width: 8px;     
    height: 100%;    
    display: none;  
  }
  .year { width: 70px}
  .month, .assignedTo { width: 100px}
  .date, .amount, .wayToPay, .account { width: 120px }
  .amount { text-align: center;}
  .paidout { width: 70px}
  .status { width: 140px}

  .id { width: 80px}

  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .year, .month, .paidout, .amount { width: auto; min-width: 50px}  
    .month { min-width: 75px;}

    .date, .amount { width: 95px }

    .wayToPay, .account { width: 105px }
  }
</style>