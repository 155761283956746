import api from "../services/api"
import {addProperyIfNotExists} from "../services/utils"

const state = {
  disease: {},
  newDisease: {
    diseaseId: 0,
    diseaseDescription: '',
    active: true,
    createdBy: 0,
    updatedBy: 0,
    createdAt: '',
    updatedAt: ''
  },
  diseases: [],
  errors: []
}

const mutations = {
  DISEASES_DISEASES ( state, diseases ) { state.diseases = diseases },
  DISEASES_DISEASE ( state, disease ) { state.disease = addProperyIfNotExists(disease, state.newDisease) },
  DISEASES_DISEASE_PROP ( state, payload ) { state.disease[payload.property] = payload.value },
  DISEASES_EMPTY_ERRORS ( state ) { state.errors = [] },
  DISEASES_ADD_ERROR ( state, error ) { state.errors.push(error)}
}

const actions = {
  async getDiseasesHTTP ( { commit }) {
    const diseases = await api.get('/configuration/diseases')
    commit('DISEASES_DISEASES', diseases.data.data)
  },
  async saveDisease ( { state, commit, dispatch } ) {
    commit('DISEASES_EMPTY_ERRORS')
    if( state.disease.diseaseDescription == '') commit('DISEASES_ADD_ERROR', 'La descripción de la enfermedad de base es requerida')

    
    if( state.errors.length == 0 ) {
      if( state.disease.diseaseId == 0 ) {
        
        return await dispatch('createDiseaseHTTP')
      } else {
        return await dispatch('updateDiseaseHTTP')
      }
    }
  },
  async createDiseaseHTTP ( { state, rootState } ) {
    let disease = state.disease
    disease.createdBy = rootState.user.userId
    await api.post('/configuration/diseases', disease )
    return 'Enfermedad de base creada'
  },
  async updateDiseaseHTTP ( { state, rootState } ) {
    let disease = state.disease
    disease.createdBy = rootState.user.userId
    disease.updatedBy = rootState.user.userId
    await api.put('/configuration/diseases', disease )
    return 'Enfermedad de base actualizada'
  }
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}