<template>
  <div class="psPatients view">
    <ps-page-container></ps-page-container>
    <el-dialog
      title="Seleccionar enfermedades de base / Momento biológico"
      :visible.sync="dialogDiseases"
      :width="isMobile == true ? '85%' : '40%'"
      :before-close="handleCloseDiseses"
    > 
      <ps-patient-list-diseases></ps-patient-list-diseases>
    </el-dialog>
    <el-dialog
      title="Agregar información eventual"
      :visible.sync="dialogEventuals"
      :width="isMobile == true ? '85%' : '40%'"
      :before-close="handleCloseEventuals"
    > 
      <ps-patient-list-eventuals></ps-patient-list-eventuals>
    </el-dialog>
    <el-dialog
      title="Agregar medicación"
      :visible.sync="dialogMedication"
      :width="isMobile == true ? '85%' : '40%'"
      :before-close="handleCloseMedication"
      v-if="dialogMedication"
    > 
      <ps-patient-medication-detail></ps-patient-medication-detail>
    </el-dialog>
    <el-dialog
      title="Agregar información general"
      :visible.sync="dialogGeneralInformation"
      :width="isMobile == true ? '85%' : '40%'"
      :before-close="handleCloseGeneralInformation"
      v-if="dialogGeneralInformation"
    > 
      <ps-patient-general-information-detail></ps-patient-general-information-detail>
    </el-dialog>
    <el-dialog
      title="Suscripción"
      :visible.sync="dialogSubscription"
      :width="isMobile == true ? '85%' : '50%'"
      :before-close="handleCloseSubscription"
      v-if="dialogSubscription"
    > 
      <ps-patient-subscription-detail></ps-patient-subscription-detail>
    </el-dialog>
    <el-dialog
      title="Pago"
      :visible.sync="dialogPayment"
      :width="isMobile == true ? '85%' : '50%'"
      :before-close="handleClosePayment"
      v-if="dialogPayment"
    > 
      <ps-patient-payment-detail></ps-patient-payment-detail>
    </el-dialog>
    <el-drawer
      :visible.sync="drawerAllWeights"
      title="Lista de todos los pesos del paciente"
      direction="rtl"
      :destroy-on-close="true"
      :wrapperClosable="true"
      :before-close="closeListWeights"
      v-if="drawerAllWeights"
    >
      <ps-patient-list-all-weights></ps-patient-list-all-weights>
    </el-drawer>
  </div>
</template>

<script>
import PsPageContainer from '@/components/layout/PsPageContainer.vue'
import PsPatientListDiseases from './pages/PatientsDetail/widgets/PsPatientListDiseases.vue'
import PsPatientListEventuals from './pages/PatientsDetail/widgets/PsPatientListEventuals.vue'
import PsPatientMedicationDetail from './pages/PatientsDetail/widgets/PsPatientMedicationDetail.vue'
import PsPatientGeneralInformationDetail from './pages/PatientsDetail/widgets/PsPatientGeneralInformationDetail.vue'
import PsPatientSubscriptionDetail from './pages/PatientsDetail/widgets/PsPatientSubscriptionDetail.vue'
import PsPatientPaymentDetail from './pages/PatientsDetail/widgets/PsPatientPaymentDetail.vue'
import PsPatientListAllWeights from './pages/PatientsDetail/widgets/PsPatientListAllWeights.vue'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'PsPatients',
  components: { PsPageContainer, PsPatientListDiseases, PsPatientListEventuals, PsPatientMedicationDetail, PsPatientSubscriptionDetail,
    PsPatientPaymentDetail, PsPatientGeneralInformationDetail, PsPatientListAllWeights },
  computed: {
    ...mapState('patients', ['dialogDiseases', 'dialogEventuals', 'dialogMedication', 'dialogSubscription', 'dialogPayment', 
      'dialogGeneralInformation', 'drawerAllWeights']),
    ...mapState(['isMobile'])
  },
  methods: {
    ...mapMutations('patients', ['PATIENTS_DIALOG_DISEASES', 'PATIENTS_DIALOG_EVENTUALS', 'PATIENTS_DIALOG_MEDICATION', 
    'PATIENTS_DIALOG_SUBSCRIPTION', 'PATIENTS_SUBSCRIPTION', 'PATIENTS_DIALOG_PAYMENT', 'PATIENTS_PAYMENT', 
    'PATIENTS_DIALOG_GENERAL_INFORMATION', 'PATIENTS_DRAWER_ALL_WEIGHTS']),
    handleCloseDiseses () {
      this.PATIENTS_DIALOG_DISEASES(false)
    },
    handleCloseEventuals () {
      this.PATIENTS_DIALOG_EVENTUALS(false)
    },
    handleCloseMedication () {
      this.PATIENTS_DIALOG_MEDICATION(false)
    },
    handleCloseGeneralInformation () {
      this.PATIENTS_DIALOG_GENERAL_INFORMATION(false)
    },
    handleCloseSubscription () {
      this.PATIENTS_SUBSCRIPTION({})
      this.PATIENTS_DIALOG_SUBSCRIPTION(false)
    },
    handleClosePayment () {
      this.PATIENTS_PAYMENT({})
      this.PATIENTS_DIALOG_PAYMENT(false)
    },
    closeListWeights () {
      this.PATIENTS_DRAWER_ALL_WEIGHTS(false);
    }
  },
  created () {
    this.handleCloseDiseses()
    this.handleCloseEventuals()
    this.handleCloseMedication()
  }
}
</script>

<style scoped>

</style>