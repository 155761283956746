<template>
  <div class="psHomeDecreaseInPatientsItem">
		<el-avatar 
			:size="40" 
			:src="patient.pathImagePatient && patient.pathImagePatient != '' ? patient.pathImagePatient : `${urlServer}/assets/images/avatarPlaceholder.png` ">
		</el-avatar>
		<div class="container">
			<div class="nameSurname">{{patient.name | textCapitalize}} {{patient.surname | textCapitalize}} </div>
			<div class="weeklyVariation">{{patient.weeklyVariation}} Kg </div>
		</div>
  </div>
</template>

<script>
export default {
	name: 'PsHomeDecreaseInPatientsItem',
	props: ['patient'],
	computed: {
		urlServer () {
      return process.env.VUE_APP_URLAPI
    }
	}
}
</script>

<style scoped>
	.psHomeDecreaseInPatientsItem { 
		display: flex; 
		margin: 4px 0; 
		border: 1px solid #ddd; 
		box-shadow: 1px 1px 1px #bbb; 
		background-color: white;
		border-radius: 16px;
		padding: 9px;
		line-height: 40px;
		width: 100%;
	}

	

	.container { display: flex; justify-content: space-between; width: 100%; padding-right: 20px}
	.weeklyVariation { color: #E57B07; font-weight: bold; font-size: 0.9rem; }

	.nameSurname { font-weight: bold; margin: 0 12px; font-size: 0.9rem;}
</style>