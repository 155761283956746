<template>
  <div class="psPatientEventualInformation">
    <div class="row rowTitle">
      Información eventual
      <ps-patient-btn-new-eventual></ps-patient-btn-new-eventual>
    </div>
    <div class="row columnTags">
      <div
        class="item"
        v-for="(eventual, index) in patient.eventuals"
        :key="index"
      >
        <div class="row rowDate">
          <div class="date">
            {{ eventual.createdAt | formatDatetimeDDMMYYYYhhmm }}
          </div>
          <i
            v-if="
              !(
                eventual.eventualDescription &&
                eventual.eventualDescription != ''
              )
            "
            class="icon el-icon-edit"
            @click="editItem(eventual)"
          ></i>
        </div>
        <div
          class="row rowTag"
          v-if="
            eventual.eventualDescription && eventual.eventualDescription != ''
          "
        >
          <el-tag>{{ eventual.eventualDescription }}</el-tag>
          <i class="icon el-icon-edit" @click="editItem(eventual)"></i>
        </div>
        <div class="detail">{{ eventual.detail }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import PsPatientBtnNewEventual from "./PsPatientBtnNewEventual.vue";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "PsPatientEventualInformation",
  components: { PsPatientBtnNewEventual },
  computed: {
    ...mapState("patients", ["patient"])
  },
  methods: {
    ...mapMutations("patients", [
      "PATIENTS_DIALOG_EVENTUALS",
      "PATIENTS_ITEM_EVENTUAL"
    ]),
    ...mapActions("eventuals", ["getEventualsHTTP"]),
    async editItem(eventual) {
      await this.getEventualsHTTP();
      this.PATIENTS_ITEM_EVENTUAL({
        id: eventual.id,
        eventualSelected: eventual.eventualDescription,
        detail: eventual.detail
      });
      this.PATIENTS_DIALOG_EVENTUALS(true);
    }
  }
};
</script>

<style scoped>
.psPatientEventualInformation {
  background-color: white;
  border-radius: 20px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 35px;
  min-height: 200px;
}
.rowTitle {
  font-size: 24px;
  font-weight: bold;
  line-height: 35px;
  align-items: center;
}
.item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.columnTags {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 220px;
  overflow-y: scroll;
}
.date {
  font-size: 0.7rem;
  text-align: left;
  margin-left: 4px;
  margin-bottom: 4px;
}
.el-tag {
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  margin-right: 15px;
  margin-bottom: 10px;
  width: min-content;
}
.detail {
  border-bottom: 1px solid #ccc;
  min-height: 30px;
  font-size: 0.9rem;
  text-align: left;
}
.columnTags::-webkit-scrollbar {
  width: 8px;
  height: 100%;
  /* display: none;   */
}
.rowTag {
  margin-bottom: 0;
}
.icon {
  line-height: 30px;
  margin-left: 10px;
  cursor: pointer;
  transition: 0.2s ease;
}

.icon:hover {
  font-size: 1.1rem;
  font-weight: bold;
}
.rowDate {
  align-items: center;
}

/* Ponemos un color de fondo y redondeamos las esquinas del thumb */
.columnTags::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.columnTags::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
.columnTags::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .psPatientEventualInformation {
    width: 100%;
  }
  .rowTitle {
    text-align: left;
  }
  .title {
    font-size: 22px;
  }
}
</style>
